import React from 'react';
import UtilitiesColor from './views/LoginRegister/LoginPage';
import UtilitiesBorder from './views/LoginRegister/LoginPage';
import UtilitiesAnimation from './views/LoginRegister/LoginPage';
import UtilitiesOther from './views/LoginRegister/LoginPage';
import Page404 from './views/LoginRegister/LoginPage';
import BlankPage from './views/LoginRegister/LoginPage';
import DashboardPage from './views/Dashboard';

const routes = [
  {
    path: '/dashboard',
    element: <DashboardPage />,
    label: 'Dashboard'
  },
  {
    path: '/components',
    label: 'components',
    children: [
      {
        path: 'cadastro/empresa',
        element: 'empresa',
        label: 'Curso'
      },
      {
        path: 'cadastro/pessoa',
        element: 'pessoa',
        label: 'Trilha'
      },
      {
        path: 'cadastro/usuario',
        element: 'usuario',
        label: 'Modulo'
      },
      {
        path: 'cadastro/acesso',
        element: 'acesso',
        label: 'Definição Acessos'
      },
    
    ],
  },
  {
    path: '/cadastros/pessoa',
    label: 'Cadastros',
    children: [
      // {
      //   path: '/cadastros/pessoa',
      //   element: <UtilitiesColor />,
      //   label: 'Pessoa'
      // },
      {
        path: '/cadastros/categorias',
        element: <UtilitiesBorder />,
        label: 'Categorias'
      },
      {
        path: '/cadastros/trilha',
        element: <UtilitiesBorder />,
        label: 'Trilhas'
      },
      {
        path: '/cadastros/cursos',
        element: <UtilitiesColor />,
        label: 'Cursos'
      },
      {
        path: '/cadastros/modulo',
        element: <UtilitiesAnimation />,
        label: 'Módulos'
      },
      {
        path: '/cadastros/aulas',
        element: <UtilitiesOther />,
        label: 'Aulas'
      },
      {
        path: '/cadastros/avaliacoes',
        element: <UtilitiesOther />,
        label: 'Avaliações'
      },
      {
        path: '/drag',
        element: <UtilitiesOther />,
        label: 'Categorias e Trilhas'
      },
      // {
      //   path: '/cadastros/avaliacoes',
      //   element: <UtilitiesOther />,
      //   label: 'Avaliações'
      // },
    ],
  },
  {
    path: '/compras',
    label: 'Compras',
    children: [
      {
        path: '/compras/pedidodecompra',
        element: <UtilitiesColor />,
        label: 'Pedido de Compra'
      },
      {
        path: '/',
        element: <UtilitiesColor />,
        label: 'Nota Fiscal de entrada'
      },
    ]
  },
  {
    path: '/vendas',
    label: 'Vendas',
    children: [
      {
        path: '/vendas/anuncios',
        element: <UtilitiesColor />,
        label: 'Anúncio'
      },
      {
        path: 'venda/pedidodevenda',
        element: <UtilitiesAnimation />,
        label: 'Pedido de Venda'
      },
      {
        path: '/estoque/notafiscalsaida',
        element: <UtilitiesAnimation />,
        label: 'Nota Fiscal de Saída'
      },
    ]
  },
  {
    path: '/estoque',
    label: 'Utilities',
    children: [
      {
        path: '/estoque/cadastroprodutos',
        element: <UtilitiesColor />,
        label: 'Produtos'
      },
      
      {
        path: '/estoque/consultaestoque',
        element: <UtilitiesOther />,
        label: 'Consulta Estoque'
      }, 
      {
        path: '/estoque/unidadesdemedida',
        element: <UtilitiesOther />,
        label: 'Unidades de Medida'
      },
      {
        path: '/estoque/ncm',
        element: <UtilitiesOther />,
        label: 'NCM'
      },
    ],
  },
  {
    path: '/financeiro',
    label: 'Financeiro',
    children: [
      {
        path: '/contaspagar',
        element: <UtilitiesColor />,
        label: 'Contas a Pagar'
      },
      {
        path: '/contasreceber',
        element: <UtilitiesBorder />,
        label: 'Contas a Receber'
      },
      {
        path: 'financeiro/formaspagamento',
        element: <UtilitiesOther />,
        label: 'Condições de Pagamento'
      },
      {
        path: '/regrascomissionamento',
        element: <UtilitiesOther />,
        label: 'Comissionamento'
      },
   
      {
        path: '/financeiro/agencias',
        element: <UtilitiesOther />,
        label: 'Agências'
      },
    
    ],
  },

  {
    path: '/fiscal',
    label: 'Fiscal',
    children: [ 
      {
        path: '/fiscal/cfop',
        element: <UtilitiesOther />,
        label: 'CFOP'
      },  
      {
        path: '/fiscal/cnae',
        element: <UtilitiesOther />,
        label: 'CNAE'
      }, 
      {
        path: '/fiscal/impostos',
        element: <UtilitiesOther />,
        label: 'Impostos'
      },
      {
        path: '/fiscal/planodecontas',
        element: <UtilitiesOther />,
        label: 'Plano de Contas'
      },
      {
        path: '/fiscal/entradaesaida',
        element: <UtilitiesOther />,
        label: 'Entradas e Saídas'
      },
  ],
},
  {
    path: '/serasa',
    label: 'Serasa',
    children: [
      {
        path: '/aceite/eletronico',
        element: 'Serasa',
        label: 'Aceite Eletrônico',
      },
      {
        path: '/consultas',
        element: 'Serasa',
        label: 'Consultas',
      },
      {
        path: '/inclusao',
        element: 'Serasa',
        label: 'Inclusão',
      },
    ],
  },
  {
    path: '/tabelafipe',
    label: 'Tabela fipe',
    children: [
      {
        path: '/tabelafipe',
        element: 'tabela',
        label: 'Tabela fipe',
      },
    ],
  },
  {
    path: '/webmotors',
    label: 'Webmotors',
    children: [
      {
        path: '/webmotors',
        element: 'webmotors',
        label: 'Webmotors',
      },
    ],
  },
  {
    path: '/icarros',
    label: 'Icarros',
    children: [
      {
        path: '/icarros',
        element: 'icarros',
        label: 'Icarros',
      },
    ],
  },
  {
    path: '/mercadolivre',
    label: 'Mercado Livre',
    children: [
      {
        path: '/mercadolivre',
        element: 'mercadolivre',
        label: 'Mercado Livre',
      },
    ],
  },
  {
    path: '/detran',
    label: 'Detran',
    children: [
      {
        path: '/detran',
        element: 'detran',
        label: 'Detran',
      },
    ],
  },

  {
    path: '/financeiro',
    label: 'Financeiro',
    children: [
      {
        path: '/contaspagar',
        element: <UtilitiesColor />,
        label: 'Contas a Pagar'
      },
      {
        path: '/contasreceber',
        element: <UtilitiesBorder />,
        label: 'Contas a Receber'
      },
      {
        path: '/financeiro/pagamentos',
        element: <UtilitiesColor />,
        label: 'Formas de Pagamento'
      },
      {
        path: '/regrascomissionamento',
        element: <UtilitiesOther />,
        label: 'Comissionamento'
      },
    ],
  },
  {
    path: '/otherpages',
    label: 'Other Pages',
    children: [
      {
        path: '/404',
        element: <Page404 />,
        label: '404 Page'
      },
      {
        path: '/blank',
        element: <BlankPage />,
        label: 'Blank Page'
      },
    ],
  },
  {
    path: '/oficina',
    label: 'Oficina',
    children: [
      {
        path: '/',
        element: <UtilitiesColor />,
        label: 'Oficinas'
      },
    ]
  },{
    path: '/vistoria',
    label: 'Vistoria',
    children: [
      {
        path: '/',
        element: <UtilitiesColor />,
        label: 'Vistorias'
      },
    ]
  },{
    path: '/relatorio',
    label: 'Relatorio',
    children: [
      {
        path: '/',
        element: <UtilitiesColor />,
        label: 'Relatorios'
      },
    ]
  },
];

export default routes;