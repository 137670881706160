import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import axios from 'axios';
import { COURSE_API, PHOTOITEM_API, DELETEPHOTOITEM_API, TRAIL_API } from '../../../apiEndPoints';
import swal from 'sweetalert';
import { BackButton, SaveButton, TabButton, AddNewButton } from '../../../components/Buttons';
import { CourseFormdata } from '../../../components/Formdatas';
import { Container, FormContainer, Form, ResponsiveDiv, Label, Buttons, Container1, TabContainer } from '../../../components/FormContainers';
import { Title } from '../../../components/General';
import PhotoItem from '../../../components/PhotoCover/PhotoItem';
import { CourseFields, CourseModulesFields } from '../../../components/Fields/CourseFields';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Inputmodules } from '../../../components/InputModules';

const Input = styled.input`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%;
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

const Textarea = styled.textarea`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  resize: vertical;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

function CourseRegister() {
  const [formData, setFormData] = useState(CourseFormdata);
  const token = localStorage.getItem('token');
  const [loading] = useState(false);
  // const [selectedPeople, setSelectedPeople] = useState([]);
  // const [peopleOptions, setPeopleOptions] = useState([]);
  const [numPhotosRegister, setPhotosRegister] = useState(1);
  const [phID, setPhid] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [selectedTrail, setSelectedTrail] = useState([]);
  const [trailOptions, setTrailOptions] = useState([]);

  const [activeTab, setActiveTab] = useState('Curso');
  const [showDocumentsTab, setShowDocumentsTab] = useState(false);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const editor = useRef(null);
  const [content, setContent] = useState("Digite o Resumo do Curso");

  const config = {
    readonly: false,
    height: 400,
  };

  const handleUpdate = (newContent) => {
    setContent(newContent);
    setFormData({ ...formData, summary_course: newContent });
  };

  useEffect(() => {
    const fetchTrail = async () => {
      try {
        const response = await axios.get(`${TRAIL_API}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const companyId = parseInt(localStorage.getItem('companyID'), 10);
        const filteredProducts = response.data.filter(
          (trail) => trail.company_id === companyId
        );

        const options = filteredProducts.map((trail) => ({
          label: trail.title,
          value: trail.id
        }));

        setTrailOptions(options);
      } catch (error) {
        console.error('Erro ao buscar opções de produtos:', error);
      }
    };

    fetchTrail();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!formData.file_document) {
      swal({
        title: 'Ops!',
        text: 'Por favor, carregue uma imagem antes de salvar.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      return;
    }
  
    const missingFields = campos.filter(campo => campo.required && !formData[campo.name]);
  
    if (missingFields.length > 0) {
      swal({
        title: 'Ops!',
        text: 'Por favor, preencha todos os campos obrigatórios.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      return;
    }
  
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyID');
  
    const updatedFormdata = new FormData();
    updatedFormdata.append('user_id', userId);
    updatedFormdata.append('company_id', companyId);
    updatedFormdata.append('status', 1);
    updatedFormdata.append('summary_course', content);
    updatedFormdata.append('file_document', formData.file_document);
    updatedFormdata.append('title', formData.title);
    updatedFormdata.append('description', '0');
    updatedFormdata.append('trails_id', selectedTrail);
  
    try {
      const response = await axios.post(`${COURSE_API}`, updatedFormdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200 || response.status === 201) {
        swal({
          title: 'OK!',
          text: 'Curso cadastrado com sucesso',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        handleGoBack();
      } else {
        swal({
          title: 'Ops!',
          text: 'Algo deu errado, tente novamente.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Error processing the form:', error);
      swal({
        title: 'Ops!',
        text: 'Erro ao processar o formulário.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
    }
  };
  

  // useEffect(() => {
  //   const fetchPeople = async () => {
  //     try {
  //       const response = await axios.get(`${PEOPLE_API}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`
  //         }
  //       });

  //       const companyId = parseInt(localStorage.getItem('companyID'), 10);
  //       const filteredProducts = response.data.filter(
  //         (people) => people.company_id === companyId && people.type_people === 1
  //       );

  //       const options = filteredProducts.map((people) => ({
  //         label: people.name,
  //         value: people.id
  //       }));

  //       setPeopleOptions(options);
  //     } catch (error) {
  //       console.error('Erro ao buscar opções de produtos:', error);
  //     }
  //   };

  //   fetchPeople();
  // }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const reader = new FileReader();
      const file = files[0];
      
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
  
      reader.readAsDataURL(file);
  
      setFormData({ ...formData, [name]: file });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleGoBack = () => {
    window.history.back();
  };

  console.log(formData)

  const campos = [
    {
      name: 'trails_id',
      type: 'select',
      placeholder: 'Trilha',
      width: 'calc(99% - 7.5px)',
      value: selectedTrail,
      onChange: (e) => {
        setSelectedTrail(e.target.value);
      },
      options: trailOptions,
    },
    ...CourseModulesFields,
    // {
    //   name: 'people_id',
    //   type: 'select',
    //   placeholder: 'Responsável',
    //   width: 'calc(40% - 7.5px)',
    //   value: selectedPeople,
    //   onChange: (e) => {
    //     setSelectedPeople(e.target.value);
    //   },
    //   options: peopleOptions,
    // },
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <FormContainer>
        <TabContainer>
          <TabButton
            active={activeTab === 'Curso'}
            onClick={() => handleTabChange('Curso')}
          >
            Curso
          </TabButton>
          {showDocumentsTab && (
            <TabButton
              active={activeTab === 'Fotos'}
              onClick={() => handleTabChange('Fotos')}
            >
              Fotos
            </TabButton>
          )}
        </TabContainer>
        <Container>
          <Title text={'Registrar Curso'} backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'} />
          <Container1>
            <Form onSubmit={handleSubmit}>
              {activeTab === 'Curso' && (
                <>
                  {campos.map((campo) => (
                    <ResponsiveDiv key={campo.name} width={campo.width}>
                      {campo.type === 'select' ? (
                        <Select
                          name={campo.name}
                          value={campo.value}
                          onChange={campo.onChange}
                          style={{ width: '100%' }}
                          disabled={campo.disabled || fieldsDisabled}
                        >
                          <option value="" style={{ color: '#999' }} disabled>
                            {campo.placeholder}
                          </option>
                          {campo.options.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Select>
                      ) : campo.type === 'textarea' ? (
                        <Textarea
                          id={campo.name}
                          name={campo.name}
                          value={formData[campo.name]}
                          onChange={campo.onChange || handleChange}
                          required={campo.required}
                          disabled={campo.disabled || fieldsDisabled}
                          fullWidth={campo.fullWidth}
                          style={{ width: '100%' }}
                        />
                      ) : campo.type === 'file' ? (
                        <div style={{ display: 'flex', alignItems: 'end' }}>
                          <div>
                            {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />}
                          </div>
                          <div>
                            <Input
                              id={campo.name}
                              type="file"
                              name={campo.name}
                              onChange={handleChange}
                              required
                              accept={campo.accept}
                              disabled={campo.disabled}
                              fullWidth={campo.fullWidth}
                              style={{ width: '100%' }}
                            />
                          </div>
                        </div>
                      ) : (
                        <Input
                          id={campo.name}
                          type={campo.type}
                          name={campo.name}
                          value={formData[campo.name]}
                          onChange={campo.onChange || handleChange}
                          mask={campo.mask}
                          maskChar={null}
                          required
                          disabled={campo.disabled || fieldsDisabled}
                          fullWidth={campo.fullWidth}
                          style={{ width: '100%' }}
                        />
                      )}
                      <Label htmlFor={campo.name}>{campo.placeholder}</Label>
                    </ResponsiveDiv>
                  ))}
                            <ResponsiveDiv width={'99%'}>
                      Resumo do Curso
                      <ReactQuill
                      ref={editor}
                      value={content}
                      onChange={handleUpdate}
                      style={{ height: '100px', marginBottom:'2em', resize: 'vertical', overflow: 'auto' }}
                      modules={Inputmodules}
                    />
                  </ResponsiveDiv>

                </>
              )}
              {activeTab !== 'Fotos' && (
                <Buttons>
                  <BackButton onClick={handleGoBack} />
                  <SaveButton onClick={handleSubmit} disabled={loading} />
                </Buttons>
              )}
            </Form>
            {showDocumentsTab && activeTab === 'Fotos' && (
              <>
                <Title text={'Fotos'} />
                {Array.from({ length: numPhotosRegister }).map((_, index) => (
                  <PhotoItem key={index} apiItem={PHOTOITEM_API} deleteApi={DELETEPHOTOITEM_API} phId={phID} />
                ))}
                <FormContainer paddingWrap={'0px'}>
                  <AddNewButton onClick={() => setPhotosRegister(numPhotosRegister + 1)} />
                </FormContainer>
              </>
            )}
          </Container1>
        </Container>
      </FormContainer>
    </div>
  );
}

export default CourseRegister;
