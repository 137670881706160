import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate, useParams } from 'react-router';
import { COURSE_API, EVALUATION_API, QUESTIONS_API } from '../../../apiEndPoints';
import { BackButton, SaveButton, AddNewButton } from '../../../components/Buttons';
import { Container, FormContainer, Form, ResponsiveDiv, Label, Buttons, Container1 } from '../../../components/FormContainers';
import { Title } from '../../../components/General';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import theme from '../../../theme';

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${(props) => props.theme.primaryColor};
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${(props) => props.theme.primaryColor};
  }
`;

function EditEvaluation() {
  const { id } = useParams(); 
  const token = localStorage.getItem('token');
  const [loading] = useState(false);
  const [formData, setFormData] = useState({ title: '', description: '', courses_id: '' });
  const [questions, setQuestions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvaluation = async () => {
      try {
        const evaluationResponse = await axios.get(`${EVALUATION_API}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setFormData({
          title: evaluationResponse.data.title,
          description: evaluationResponse.data.description,
          courses_id: evaluationResponse.data.courses_id,
        });

        const questionsResponse = await axios.get(QUESTIONS_API, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const filteredQuestions = questionsResponse.data
          .filter(question => question.evaluation_id === parseInt(id))
          .map((question) => ({
            ...question,
            options: JSON.parse(question.options).options,
            correctAnswer: JSON.parse(question.options).correctAnswer,
          }));

        setQuestions(filteredQuestions);
      } catch (error) {
        console.error('Erro ao buscar avaliação:', error);
      }
    };

    const fetchCourses = async () => {
      try {
        const response = await axios.get(COURSE_API, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const companyId = parseInt(localStorage.getItem('companyID'), 10);
        const filteredProducts = response.data.filter((course) => course.company_id === companyId);

        const options = filteredProducts.map((course) => ({
          label: course.title,
          value: course.id,
        }));

        setCourseOptions(options);
      } catch (error) {
        console.error('Erro ao buscar cursos:', error);
      }
    };

    fetchEvaluation();
    fetchCourses();
  }, [id]);


  useEffect(() => {
    if (courseOptions.length > 0 && formData.courses_id) {
      const selectedCourse = courseOptions.find(course => course.value === formData.courses_id);
      if (selectedCourse) {
        setFormData(prevFormData => ({
          ...prevFormData,
          courses_id: selectedCourse.value,
        }));
      }
    }
  }, [courseOptions, formData.courses_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    setQuestions([...questions, { question_text: '', options: ['', '', '', ''], correctAnswer: 0 }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
  
    try {
      await axios.put(
        `${EVALUATION_API}/${id}`,
        {
          title: formData.title,
          description: formData.description,
          courses_id: formData.courses_id,
          user_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const existingQuestions = questions.filter(question => question.id);
      const newQuestions = questions.filter(question => !question.id);
  
      const updatePromises = existingQuestions.map((question) => {
        return axios.put(
          `${QUESTIONS_API}/${question.id}`,
          {
            evaluation_id: id,
            question_text: question.question_text,
            options: JSON.stringify({
              options: question.options,
              correctAnswer: question.correctAnswer,
            }),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      });
  
      const createPromises = newQuestions.map((question) => {
        return axios.post(
          QUESTIONS_API,
          {
            evaluation_id: id,
            user_id: userId,
            question_text: question.question_text,
            options: JSON.stringify({
              options: question.options,
              correctAnswer: question.correctAnswer,
            }),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      });
  
      await Promise.all([...updatePromises, ...createPromises]);
  
      swal({
        title: 'Sucesso!',
        text: 'Avaliação e questões atualizadas com sucesso.',
        icon: 'success',
        timer: 2000,
        buttons: false,
      });
  
      navigate(-1);
    } catch (error) {
      console.error('Erro ao atualizar a avaliação:', error);
      swal({
        title: 'Erro!',
        text: 'Houve um problema ao atualizar a avaliação. Por favor, tente novamente.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
    }
  };

  return (
    <FormContainer>
      <Container>
        <Title text="Editar Avaliação" backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'} />
        <Container1>
          <Form onSubmit={handleSubmit}>
            <ResponsiveDiv width="100%">
            <Label htmlFor="courses_id">Curso</Label>
              <Select
                name="courses_id"
                value={formData.courses_id || ''} 
                onChange={handleInputChange}
                required
              >
                {!formData.courses_id && (
                  <option value="" disabled>
                    Selecione um Curso
                  </option>
                )}
                {courseOptions.map((course) => (
                  <option key={course.value} value={course.value}>
                    {course.label}
                  </option>
                ))}
              </Select>
            </ResponsiveDiv>

            <ResponsiveDiv width="100%">
              <Label htmlFor="title">Título</Label>
              <Input
                id="title"
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
              />
            </ResponsiveDiv>

            <ResponsiveDiv width="100%">
              Descrição da avaliação
              <Label htmlFor="description"></Label>
              <ReactQuill
                value={formData.description}
                onChange={(value) => setFormData({ ...formData, description: value })}
                style={{ height: '200px', marginBottom: '2em' }}
              />
            </ResponsiveDiv>

            {questions.map((question, questionIndex) => (
              <div key={questionIndex} style={{ width: '100%', marginBottom: '20px' }}>
                <ResponsiveDiv width="100%">Questão {questionIndex + 1}
                  <Label htmlFor={`question_text-${questionIndex}`}></Label>
                  <ReactQuill
                    value={question.question_text}
                    onChange={(value) => handleQuestionChange(questionIndex, 'question_text', value)}
                    style={{ height: '100px', marginBottom: '2em' }}
                  />
                </ResponsiveDiv>

                {question.options.map((option, optionIndex) => (
                  <ResponsiveDiv key={optionIndex} width="100%">
                    <Label htmlFor={`option-${questionIndex}-${optionIndex}`}>
                      Alternativa {optionIndex + 1}
                    </Label>
                    <Input
                      id={`option-${questionIndex}-${optionIndex}`}
                      type="text"
                      value={option}
                      onChange={(e) => handleOptionChange(questionIndex, optionIndex, e.target.value)}
                    />
                  </ResponsiveDiv>
                ))}

                <ResponsiveDiv width="100%">
                  <Label htmlFor={`correctAnswer-${questionIndex}`}>Alternativa Correta</Label>
                  <Select
                    id={`correctAnswer-${questionIndex}`}
                    value={question.correctAnswer}
                    onChange={(e) => handleQuestionChange(questionIndex, 'correctAnswer', parseInt(e.target.value))}
                  >
                    {question.options.map((_, optionIndex) => (
                      <option key={optionIndex} value={optionIndex}>
                        Alternativa {optionIndex + 1}
                      </option>
                    ))}
                  </Select>
                </ResponsiveDiv>
              </div>
            ))}

            <AddNewButton type="button" onClick={addQuestion}>
              Adicionar Questão
            </AddNewButton>

            <Buttons>
              <BackButton onClick={() => navigate(-1)} />
              <SaveButton onClick={handleSubmit} disabled={loading} />
            </Buttons>
          </Form>
        </Container1>
      </Container>
    </FormContainer>
  );
}

export default EditEvaluation;
