import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import { COMPANY_API, IBGECITIESSTATES_API } from '../../../apiEndPoints';
import axios from 'axios';
import InputMask from 'react-input-mask';
import swal from 'sweetalert';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { BackButton, SaveButton } from '../../../components/Buttons';
import { CompanyData } from '../../../components/Formdatas';
import { CompanyFields } from '../../../components/Fields/CompanyFields';
import { Container, Container1, FormContainer, Form, ResponsiveDiv, Label, Buttons } from '../../../components/FormContainers';
import { Title } from '../../../components/General';

const Input = styled(InputMask)`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const EditCompany = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(CompanyData);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }

    if (id) {
      const fetchCompany = async () => {
        try {
          const response = await axios.get(`${COMPANY_API}/${id}`, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
          setFormData(response.data);
        } catch (error) {
          console.error('Error fetching company information:', error);
        }
      };

      fetchCompany();
    }
  }, [id]);

  const handleCepBlur = async (value) => {
    if (value.length === 9) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
  
        if (response.data.erro) {
          swal({
            title: 'Ops!',
            text: 'CEP inválido. Verifique o CEP informado.',
            icon: 'error',
            timer: 2000,
            buttons: false,
          });
          setFormData(prevData => ({
            ...prevData,
            zip_code: '',
            address: '',
            district: '',
            city: '',
            state: '',
          }));
        } else {
          const { logradouro, bairro, cep, localidade, uf } = response.data;
  
          const ibgeToken = localStorage.getItem('IBGEtoken');
          const ibgeResponse = await axios.get(`${IBGECITIESSTATES_API}`, {
            headers: {
              Authorization: `Bearer ${ibgeToken}`,
            },
          });
  
          const cities = ibgeResponse.data;
          const cityExists = cities.some((city) => city.name.toLowerCase() === localidade.toLowerCase());
  
          if (cityExists) {
            setFormData(prevData => ({
              ...prevData,
              zip_code: cep,
              address: logradouro,
              district: bairro,
              city: localidade,
              state: uf,
            }));
          } else {
            setFormData(prevData => ({
              ...prevData,
              zip_code: cep,
              address: logradouro,
              district: bairro,
              city: '',
              state: uf,
            }));
          }
        }
      } catch (error) {
        console.error('Erro ao buscar CEP:', error);
      }
    } else {
      swal({
        title: 'Ops!',
        text: 'CEP inválido. Verifique o CEP informado.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      setFormData(prevData => ({
        ...prevData,
        zip_code: '',
        address: '',
        district: '',
        city: '',
        state: '',
      }));
    }
  };

  useEffect(() => {
    const cepInput = document.querySelector('input[name="zip_code"]');
    const handleBlur = () => handleCepBlur(cepInput.value);
    cepInput.addEventListener('blur', handleBlur);
    return () => {
      cepInput.removeEventListener('blur', handleBlur);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${COMPANY_API}/${id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        swal({
          title: 'OK!',
          text: 'Empresa atualizada com sucesso',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        navigate('/cadastro/empresa');
      } else {
        swal({
          title: 'Ops!',
          text: 'Algo deu errado, tente denovo.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Error processing the form:', error);
    }
  };

  const campos = [
    ...CompanyFields,
    {
      name: 'matrix',
      type: 'select',
      options: [
        { value: '1', label: 'Matriz' },
        { value: '0', label: 'Filial' }
      ],
      required: true,
    },
  ];

  return (
    <FormContainer>
      <Container>
        <Title text={'Editar Empresa'} backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'} />
        <Container1>
          <Form onSubmit={handleSubmit}>
            {campos.map((campo) => (
              <ResponsiveDiv key={campo.name} width={campo.width}>
                {campo.type === 'select' ? (
                  <Select
                    name={campo.name}
                    value={formData[campo.name]}
                    onChange={handleChange}
                    required={campo.required}
                  >
                    {campo.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    key={campo.name}
                    type={campo.type}
                    name={campo.name}
                    value={formData[campo.name]}
                    onChange={handleChange}
                    mask={campo.mask}
                    maskChar={null}
                    required={campo.required}
                    disabled={campo.disabled}
                    fullWidth={campo.fullWidth}
                    style={{ width: '100%' }}
                  />
                )}
                <Label htmlFor={campo.name}>{campo.placeholder}</Label>
              </ResponsiveDiv>
            ))}
            <Buttons>
              <BackButton as={Link} to="/cadastro/empresa" />
              <SaveButton onClick={handleSubmit} disabled={loading} />
            </Buttons>
          </Form>
        </Container1>
      </Container>
    </FormContainer>
  );
};

export default EditCompany;
