import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

const ContainerWrapper = styled.div`
  max-width: ${(props) => props.maxWidth || '75%'};
  min-width: ${(props) => props.minWidth || '75%'};
  margin: ${(props) => props.margin || '50px auto'};
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const Container1Wrapper = styled.div`
  padding: 0 20px 20px 20px;
`;

const FormContainerWrapper = styled.div`
  padding: ${(props) => props.paddingWrap || '20px'};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
`;

const ResponsiveDivWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width};

  @media (max-width: 812px) {
    min-width: 100%; 
  }

  &:focus-within label {
    color: ${theme.primaryColor};
  }
`;

const LabelWrapper = styled.label`
  position: absolute;
  top: 14px;
  left: 10px;
  background-color: #fff;
  padding: 0 2px;
  transition: color 0.3s ease-in-out; 
  border-radius: 15px;
  font-size: 13px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  position: relative;
  top: 15px;
  gap: 18px;
`;

const Loading = styled.div`
  position: relative;
  top: 10rem;
`;

const TabContainer = styled.div`
  margin-top: 20px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Container = ({ children, minWidth, maxWidth, margin }) => {
    return <ContainerWrapper  minWidth={minWidth} maxWidth={maxWidth} margin={margin}>{children}</ContainerWrapper>;
  };

const Container1 = ({ children }) => {
  return <Container1Wrapper>{children}</Container1Wrapper>;
};

const FormContainer = ({ children, paddingWrap }) => {
  return <FormContainerWrapper paddingWrap={paddingWrap}>{children}</FormContainerWrapper>;
};

const Form = ({ onSubmit, children }) => {
  return <FormWrapper onSubmit={onSubmit}>{children}</FormWrapper>;
};

const ResponsiveDiv = ({ width, children }) => {
  return <ResponsiveDivWrapper width={width}>{children}</ResponsiveDivWrapper>;
};

const Label = ({ htmlFor, children }) => {
  return <LabelWrapper htmlFor={htmlFor}>{children}</LabelWrapper>;
};

const Buttons = ({ children }) => {
  return <ButtonsWrapper>{children}</ButtonsWrapper>;
};

export { Container, Container1, FormContainer, Form, ResponsiveDiv, Label, Buttons, Loading, TabContainer, List, ListItem };
