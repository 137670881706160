import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import axios from 'axios';
import InputMask from 'react-input-mask';
import swal from 'sweetalert';
import { DeleteButton, SaveButton } from '../../Buttons';
import { Form, ResponsiveDiv, Label, Buttons} from '../../FormContainers';
import { PersonDocumentFormdata } from '../../Formdatas';
import { PhotoItemFields } from '../../Fields/PhotoFields';


const Input = styled(InputMask)`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
 
  &:focus {
    border-color: ${theme.primaryColor};
  }
`;
  
function PhotoItem({deleteApi, apiItem, phID}) {
  const [formData, setFormData] = useState(PersonDocumentFormdata);
  const token = localStorage.getItem('token');
  const [loading] = useState(false);

  const [show, setShow] = useState(true);
  const [showExtras, setShowExtras] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [showAdd, setShowAdd] = useState(true);
  const [createdPostId, setCreatedPostId] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  console.log(phID, 'phph')

  const handleSubmit = async (e) => {
    e.preventDefault();
    const PhId = phID
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyID');

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', userId);
    formDataToSend.append('company_id', companyId);
    formDataToSend.append('photo_id', PhId);
    formDataToSend.append('observation', formData.observation);
    formDataToSend.append('file_document', formData.file_document);

    try {
      const response = await axios.post(`${apiItem}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response, '2222')

      if (response.status === 200 || response.status === 201) {
        swal({
          title: 'OK!',
          text: 'Cadastrado com sucesso',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        setCreatedPostId(response.data.id)
        setShowExtras(false)
        setShowDelete(true)
        setShowAdd(false)
        localStorage.removeItem('PicId')

      } else {
        swal({
          title: 'Ops!',
          text: 'Algo deu errado, tente denovo.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Error processing the form:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${deleteApi}/${createdPostId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal({
        title: 'OK!',
        text: 'Foto excluída com sucesso',
        icon: 'success',
        timer: 2000,
        buttons: false,
      });
      setShowDelete(false);
    } catch (error) {
      console.error('Erro ao excluir o Foto:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const reader = new FileReader();
      const file = files[0];
      
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
  
      reader.readAsDataURL(file);
  
      setFormData({ ...formData, [name]: file });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


  const campos = [...PhotoItemFields];

  return (
    <div>
      {show && (
        <div style={{ border: 'solid 1px #D3D3D3', margin: '1.5em 0 0 0', padding: '1em', backgroundColor: '#fcfcfc' }}>
          <>
            Fotos
            <Form onSubmit={handleSubmit}>
              {campos.map((campo) => (
                <ResponsiveDiv key={campo.name} width={campo.width}>
                  {campo.type === 'select' ? (
                    <Select
                      name={campo.name}
                      value={campo.value}
                      onChange={(e) => {
                        campo.onChange(e);
                      }}
                      style={{ width: '100%' }}
                      disabled={campo.disabled}
                    >
                      <option value="" style={{ color: '#999' }} disabled>
                        {campo.placeholder}
                      </option>
                      {campo.options.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    campo.type === 'file' ? (
                      <div style={{ display: 'flex', alignItems: 'end' }}>
                      <div>
                        {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />}
                        </div><div>
                        <Input
                          id={campo.name}
                          type="file"
                          name={campo.name}
                          onChange={handleChange}
                          required
                          disabled={campo.disabled}
                          fullWidth={campo.fullWidth}
                          style={{ width: '100%' }}
                        />
                      </div></div>
                    ) : (
                      <Input
                        id={campo.name}
                        type={campo.type}
                        name={campo.name}
                        value={formData[campo.name]}
                        onChange={campo.onChange || handleChange}
                        required
                        disabled={campo.disabled}
                        fullWidth={campo.fullWidth}
                        style={{ width: '100%' }}
                      />
                    )
                  )}
                  <Label htmlFor={campo.name}>{campo.placeholder}</Label>
                </ResponsiveDiv>
              ))}
              <Buttons>
                {showExtras && (
                  <DeleteButton onClick={() => setShow(false)} />
                )}
                {showDelete && (
                  <DeleteButton onClick={() => { handleDelete(); setShow(false); }} />
                )}
                {showAdd && (
                  <SaveButton onClick={handleSubmit} disabled={loading} />
                )}
              </Buttons>
            </Form>
          </>
        </div>
      )}
    </div>
  );
  
}

export default PhotoItem;
