import React, { useState,useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Header';
import Footer from '../components/Footer';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

const StyledFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
    transition: 0.3s;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
   transition: 0.3s;
`;

const ContentWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Flex = styled.div`
display: flex;
justify-content: center;
`

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showSideBar, setShowSideBar] = useState(true);


    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const checkUserLevel = async () => {
        await new Promise(resolve => setTimeout(resolve, 4000));
  
        setLoading(false); 
      };
  
      checkUserLevel();
    }, []);
  
  
    
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
    useEffect(() => {
      setShowSideBar(isSidebarOpen);
    }, [isSidebarOpen]);


    if (loading) {
      return (
        <div className="container-fluid">
          <Topbar toggleSidebar={toggleSidebar} />
          <Flex>
          <CircularProgress/>
          </Flex>
        </div>
      );
    } 

  return (
    <LayoutContainer>
      <ContentWrapper>
        {showSideBar && (
          <Sidebar toggleSidebar={toggleSidebar}
            isSidebarOpen={isSidebarOpen} />
        )}
        <ContentWrapper2>
          <Topbar toggleSidebar={toggleSidebar} />
          <MainContent>
            <div className="container-fluid">{children}</div>
          </MainContent>
          <Footer/>
        </ContentWrapper2>
      </ContentWrapper>
      <StyledFooter>
      </StyledFooter>
    </LayoutContainer>
  );
};

export default Layout;