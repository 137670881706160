import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { COMPANY_API, CONSULTCOMPANYYUSER_API, ME_API } from '../../apiEndPoints';
import ProfileImg from '../../img/undraw_profile.svg';
import ProfileImg2 from '../../img/undraw_profile_1.svg';
import LogoutModal from '../../components/Logoutbutton';
import { Link } from 'react-router-dom';
import theme from '../../theme';
import swal from 'sweetalert';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../vendor/fontawesome-free/css/all.min.css';
import '../../css/sb-admin-2.min.css';
import SearchInput from '../SearchInput';
import axios from 'axios';
import { SidebarToggleButton } from '../Buttons';

const TopbarWrapper = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #e3e6f0;
  padding: 0 1rem;
  

  &:hover {
    color: none;
    text-decoration: none;
  }
`;

const TopbarNavbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
`;

const TopbarIcons = styled.div`
  display: flex;
  align-items: center;
`;

const TopbarIcon = styled.div`
  margin-right: 1rem;
`;

const TopbarUserDropdown = styled(Dropdown)`
  padding: 0;

  .dropdown-toggle {
    box-shadow: none;
  }
`;

const ChangeCompany = styled.div`
  font-size: 16px;
  margin-left: -355px;

  @media (max-width: 868px) {
    width: 100%;
    margin-left: 100px;
  }

  @media (max-width: 480px) {
    width: 100%;
   margin-left: 0px;
    font-size: 14px; 
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '100%')};
  border: none;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  padding: 0 8px;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  appearance: none; 
  -webkit-appearance: none; 
  -moz-appearance: none; 
  background: transparent; 
  color: inherit; 

   @media (max-width: 812px) {
    min-width: 50%; 
  }
`;

const ConfigPageLink = styled(Link)`
  color: #000;
  text-decoration: none;

  &:hover {
    color: #000;
    text-decoration: none;
  }

  &:focus {
    color: white;
  }
`;

const Topbar = ({ toggleSidebar }) => {
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');
  const [userSex, setUserSex] = useState('');
  const [userMaster, setUserMaster] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [selectedCompanyID, setSelectedCompanyID] = useState('');
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const fetchUserData = async () => {
      try {
        const response = await fetch(ME_API, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserName(data.name);
          setUserId(data.id);
          setUserSex(data.sex);
          setUserMaster(data.user_master);

          // Redirecionar se user_master for 0
          if (data.user_master === 0) {
            navigate('/home');
          }
        } else {
          console.error('Erro ao buscar dados do usuário');
        }
      } catch (error) {
        console.error('Erro na chamada da API:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(COMPANY_API, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setCompanies(response.data);
      } catch (error) {
        console.error('Erro ao buscar empresas:', error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const tokenStoredTimestamp = parseInt(localStorage.getItem('tokenTimestamp'));
    if (!isNaN(tokenStoredTimestamp)) {
      const currentTime = new Date().getTime();
      const elapsedTimeMilliseconds = currentTime - tokenStoredTimestamp;
      const minutesSinceTokenStored = Math.floor(elapsedTimeMilliseconds / (1000 * 60)); 
      if (minutesSinceTokenStored > 60) {
        localStorage.removeItem('token');
        swal({
          title: 'Tempo expirado!',
          text: 'Faça o Login novamente!',
          icon: 'warning',
          timer: 2000,
          buttons: false,
        });
        navigate('/login');
      } else {
        console.log(minutesSinceTokenStored, 'minutos');
      }
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchCompaniesUsers = async () => {
      const UserID = localStorage.getItem('userId');
      const storedToken = localStorage.getItem('token');
      try {
        const response = await axios.get(`${CONSULTCOMPANYYUSER_API}/${UserID}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const companyIds = response.data.map(company => company.company_id);
        const companiesWithNames = companies.filter(company => companyIds.includes(company.id));
        setCompaniesOptions(companiesWithNames);
      } catch (error) {
        console.error('Erro ao buscar empresas:', error);
      }
    };

    fetchCompaniesUsers();
  }, [companies]);

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const handleLogoutConfirmed = () => {
    setShowLogoutModal(false);
    navigate('/login');
    localStorage.clear();
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
  };

  const handleMyProfile = () => {
    navigate(`/edituser/${userId}`);
  };

  const handlePasswordChange = () => {
    navigate('/editpassword');
};

  const handleCompanyChange = (e) => {
    const newCompanyID = e.target.value;
    setSelectedCompanyID(newCompanyID);
    localStorage.setItem('companyID', newCompanyID);
    window.location.reload();
  };

  useEffect(() => {
    const companyIDFromLocalStorage = localStorage.getItem('companyID');
    setSelectedCompanyID(companyIDFromLocalStorage);
  }, []);

  return (
    <TopbarWrapper>
    <TopbarNavbar className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      {/* Sidebar Toggle (Topbar) */}
      <SidebarToggleButton className="btn btn-link d-md-none rounded-circle mr-3"
        color={'blue'}
        onClick={toggleSidebar}>
        <i className="fa fa-bars"></i>
      </SidebarToggleButton>
      <div></div>

        {/* Topbar Brand
        <TopbarBrand href="index.html">Your Brand</TopbarBrands> */}

        {/* Topbar Search */}
        <ChangeCompany>
          { <Select value={selectedCompanyID} onChange={handleCompanyChange}>
            {companiesOptions.map(company => (
              <option key={company.id} value={company.id}>{company.fantasy_name}</option>
            ))}
          </Select> }
        </ChangeCompany>

        {/* Topbar Icons */}
        <TopbarIcons>
          {/* Notification Icon */}
          <TopbarIcon className="nav-item dropdown no-arrow mx-1">
            <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            </a>
            <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
              {/* ... (conteúdo do dropdown de notificações) */}
            </div>
          </TopbarIcon>

          {/* Message Icon */}
          <TopbarIcon className="nav-item dropdown no-arrow mx-1">
            <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className=""></i>
              <span className=""></span>
            </a>
            <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
              {/* ... (conteúdo do dropdown de mensagens) */}
            </div>
          </TopbarIcon>

          {/* User Name */}
          <div className="mr-3 d-none d-lg-inline text-gray-600 small" style={{ fontSize: '14px', color: '#000' }}>{userName}</div>

          {/* User Dropdown */}
          <TopbarUserDropdown>
            <Dropdown.Toggle variant="link" id="userDropdown" className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {/* Use uma expressão ternária para alternar entre as imagens de perfil */}
              <img className="img-profile rounded-circle" src={userSex === 0 ? ProfileImg2 : ProfileImg} alt="Profile" style={{ width: '25px', height: '30px' }} />
            </Dropdown.Toggle>
            {/* Dropdown - User Information */}
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <Dropdown.Item onClick={handleMyProfile}>
                <i className="fas fa-user fa-sm fa-fw mr-2 text-black-800"></i>
                Meu Perfil
              </Dropdown.Item>
              {userMaster === 1 && (
                <Dropdown.Item>
                  <ConfigPageLink to="/config">
                  <i class="fas fa-cog fa-sm fa-fw mr-2 text-black-800" ></i>
                    Configurações
                  </ConfigPageLink>
                </Dropdown.Item>
              )}
                   <Dropdown.Item onClick={handlePasswordChange}>
                <i className="fa-solid fa-lock-open fa-sm fa-fw mr-2 text-black-800"></i>
                Alterar Senha
              </Dropdown.Item >
              <Dropdown.Item onClick={handleLogout} >
                
              <i class="fas fa-power-off fa-sm fa-fw mr-2 text-black-800 fontSize 20px"></i>
                Sair
              </Dropdown.Item>
            </Dropdown.Menu>
          </TopbarUserDropdown>
        </TopbarIcons>
      </TopbarNavbar>
      {/* Logout Modal */}
      <LogoutModal showModal={showLogoutModal} handleClose={handleCloseLogoutModal} handleLogout={handleLogoutConfirmed} />
    </TopbarWrapper>
  );
};

export default Topbar;
