import React, { useState  } from 'react'
import styled from 'styled-components';
import theme from '../../../theme';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { PHOTOITEM_API, DELETEPHOTOITEM_API, CATEGORY_API } from '../../../apiEndPoints';
import swal from 'sweetalert';
import { BackButton, SaveButton, TabButton, AddNewButton } from '../../../components/Buttons';
import { CategoryFormdata } from '../../../components/Formdatas';
import { Container, FormContainer, Form, ResponsiveDiv, Label, Buttons, Container1, TabContainer } from '../../../components/FormContainers';
import { Title } from '../../../components/General';
import PhotoItem from '../../../components/PhotoCover/PhotoItem';
import { CategoryFields } from '../../../components/Fields/CourseFields';


const Input = styled.input`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

const Textarea = styled.textarea`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  resize: vertical;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

function CategoriesRegister() {
  const [formData, setFormData] = useState(CategoryFormdata);
  const token = localStorage.getItem('token');
  const [loading] = useState(false);

  const navigate = useNavigate();
  const [numPhotosRegister, setPhotosRegister] = useState(1);

  const [activeTab, setActiveTab] = useState('Categoria');
  const [phID, setPhid] = useState('');
  const [showDocumentsTab, setShowDocumentsTab] = useState(false);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const missingFields = campos.filter(campo => campo.required && !formData[campo.name]);

    if (missingFields.length > 0) {
      swal({
        title: 'Ops!',
        text: 'Por favor, preencha todos os campos obrigatórios.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      return;
    }

    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyID');

    const updatedFormdata = new FormData();
    updatedFormdata.append('description', formData.description);

    try {
      const response = await axios.post(`${CATEGORY_API}`, updatedFormdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        swal({
          title: 'OK!',
          text: 'Categoria cadastrada com sucesso',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        setFieldsDisabled(true);
        handleGoBack();
      } else {
        swal({
          title: 'Ops!',
          text: 'Algo deu errado, tente denovo.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Error processing the form:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const reader = new FileReader();
      const file = files[0];
      
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
  
      reader.readAsDataURL(file);
  
      setFormData({ ...formData, [name]: file });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const campos = [
    ...CategoryFields,
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <FormContainer>
        <TabContainer>
          <TabButton
            active={activeTab === 'Categoria'}
            onClick={() => handleTabChange('Categoria')}
          >
            Categoria
          </TabButton>
          {showDocumentsTab && (
            <TabButton
              active={activeTab === 'Fotos'}
              onClick={() => handleTabChange('Fotos')}
            >
              Fotos
            </TabButton>
          )}
        </TabContainer>
        <Container>
          <Title text={'Registrar Categoria'} backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'} />
          <Container1>
            <Form onSubmit={handleSubmit}>
              {activeTab === 'Categoria' && (
                <>
                  {campos.map((campo) => (
                    <ResponsiveDiv key={campo.name} width={campo.width}>
                      {campo.type === 'select' ? (
                        <Select
                          name={campo.name}
                          value={campo.value}
                          onChange={campo.onChange}
                          style={{ width: '100%' }}
                          disabled={campo.disabled || fieldsDisabled}
                        >
                          <option value="" style={{ color: '#999' }} disabled>
                            {campo.placeholder}
                          </option>
                          {campo.options.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Select>
                      ) : campo.type === 'textarea' ? (
                        <Textarea
                          id={campo.name}
                          name={campo.name}
                          value={formData[campo.name]}
                          onChange={campo.onChange || handleChange}
                          required={campo.required}
                          disabled={campo.disabled || fieldsDisabled}
                          fullWidth={campo.fullWidth}
                          style={{ width: '100%' }}
                        />
                      ) : campo.type === 'file' ? (
                        <div style={{ display: 'flex', alignItems: 'end' }}>
                          <div>
                            {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />}
                          </div>
                          <div>
                            <Input
                              id={campo.name}
                              type="file"
                              name={campo.name}
                              onChange={handleChange}
                              required
                              disabled={campo.disabled}
                              fullWidth={campo.fullWidth}
                              style={{ width: '100%' }}
                            />
                          </div>
                        </div>
                      ) : (
                        <Input
                          id={campo.name}
                          type={campo.type}
                          name={campo.name}
                          value={formData[campo.name]}
                          onChange={campo.onChange || handleChange}
                          mask={campo.mask}
                          maskChar={null}
                          required
                          disabled={campo.disabled || fieldsDisabled}
                          fullWidth={campo.fullWidth}
                          style={{ width: '100%' }}
                        />
                      )}
                      <Label htmlFor={campo.name}>{campo.placeholder}</Label>
                    </ResponsiveDiv>
                  ))}
                </>
              )}
              {activeTab !== 'Fotos' && (
                <Buttons>
                  <BackButton onClick={handleGoBack} />
                  <SaveButton onClick={handleSubmit} disabled={loading} />
                </Buttons>
              )}
            </Form>
            {showDocumentsTab && activeTab === 'Fotos' && (
              <>
                <Title text={'Fotos'} />
                {Array.from({ length: numPhotosRegister }).map((_, index) => (
                  <PhotoItem key={index} apiItem={PHOTOITEM_API} deleteApi={DELETEPHOTOITEM_API} phId={phID} />
                ))}
                <FormContainer paddingWrap={'0px'}>
                  <AddNewButton onClick={() => setPhotosRegister(numPhotosRegister + 1)} />
                </FormContainer>
              </>
            )}
          </Container1>
        </Container>
      </FormContainer>
    </div>
  );
};

export default CategoriesRegister;
