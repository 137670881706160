const theme = {
    primaryColor: '#4e73df',
    secondaryColor: '#224abe',
    backgroundColor1: '#EBECEE',
    succesColor:'#1cc88a',
    infoColor:'#36b9cc',
    dangerColor:'#e74a3b',
    warningColor:'#f6c23e',
    darkColor:'#5a5c69',
    backgroundColor2: '#f0f1f7',
    backgroundColor3: '#D3D3D3',
    portalColor: '#06BBCC',
    portalTitleColor: '#181d38',
    portalBackgroundColor2: '##22294f',
    greenAssets: '#2bfdbe',
    portalFontColor: '#FFFFFF'
  };
  
  export default theme;