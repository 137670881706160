import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import { USER_API, GROUP_API, COMPANY_API, COMPANYUSER_API, CONSULTCOMPANYYUSER_API,
   DELETECOMPANYYUSER_API, 
   IBGECITIESSTATES_API} from '../../../apiEndPoints';
import axios from 'axios';
import InputMask from 'react-input-mask';
import swal from 'sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import { BackButton, SaveButton, TabButton } from '../../../components/Buttons';
import { EditUserData } from '../../../components/Formdatas';
import { EditUserfields, adminOptions } from '../../../components/Fields/UserFields';
import Checkbox from '../../../components/Checkboxes';
import { Title } from '../../../components/General';
import CircularIndeterminate from '../../../components/LoadingCircle';
import { Container, Container1, FormContainer, Form, ResponsiveDiv, Label, Buttons,
   Loading, TabContainer, List, ListItem } from '../../../components/FormContainers';


const Input = styled(InputMask)`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;


const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  min-width: 100px; 

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;



const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(EditUserData);
  const [loading, setLoading] = useState(false);
  const [selectedSex, setSelectedSex] = useState(null);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [token, setToken] = useState('');
  const [gruposDeAcesso, setGruposDeAcesso] = useState([]);


  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedCompanies, setSelectedCompanies] = useState({});
  const [activeTab, setActiveTab] = useState('user');
  const [companies, setCompanies] = useState([]);
  const [matrices, setMatrices] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);


  

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  
    const fetchGroups = async () => {
      try {
        // setLoading(true); DESCOMENTAR QUANDO USER TIVER COMPANY_ID
        const response = await axios.get(GROUP_API, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setGruposDeAcesso(response.data);
      } catch (error) {
        console.error('Erro ao buscar grupos de acesso:', error);
      }
    };
  
    if (id) {
      const fetchUser = async () => {
        try {
          const response = await axios.get(`${USER_API}/${id}`, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
    
          setFormData(response.data);
    
          console.log('Dados do usuário:', response.data);
    
          if ('group_id' in response.data) {
            setSelectedGroup(response.data.group_id);
          } else {
            console.error('Identificador do grupo não encontrado nos dados do usuário.');
          }
    
          if ('sex' in response.data) {
            setSelectedSex(response.data.sex);
    
          } 
          if ('user_master' in response.data) {
            setSelectedAdmin(response.data.user_master);
          }  else {
            console.error('Sexo não encontrado nos dados do usuário.');
          }
    
          // const userCompanyResponse = await axios.get(`${CONSULTCOMPANYYUSER_API}/${id}`, {
          //   headers: {
          //     Authorization: `Bearer ${storedToken}`,
          //   },
          // });
    
          // const selectedCompaniesMap = {};
          // userCompanyResponse.data.forEach(companyUser => {
          //   selectedCompaniesMap[companyUser.company_id] = true;
          // });
          // setSelectedCompanies(selectedCompaniesMap);
          // setLoading(false);
        } catch (error) {
         
        }
      };
    
      fetchUser();
    }
  
    fetchGroups();
  }, [id]);


  

  // useEffect(() => {
  //   const fetchCompanies = async () => {
  //     try {
  //       const response = await axios.get(COMPANY_API,{
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
        
    
  //       setCompanies(response.data);
  //     } catch (error) {
  //       console.error('Erro ao buscar empresas:', error);
  //     }
  //   };
  
  //   fetchCompanies();
  // }, [token]);

  // useEffect(() => {
  //   if (companies.length > 0) {
  //     const matrices = companies.filter(company => company.matrix === 1);
  //     const subsidiaries = companies.filter(company => company.matrix === 0);
  //     setMatrices(matrices);
  //     setSubsidiaries(subsidiaries);
  //   }
  // }, [companies]);

  
  
  useEffect(() => {
    if (gruposDeAcesso.length > 0) {
      const userGroup = gruposDeAcesso.find((grupo) => grupo.id === selectedGroup);
      if (userGroup) {
        setSelectedGroup(userGroup.id);
      }
    }
  }, [gruposDeAcesso, selectedGroup]);

  

  const handleCepBlur = async (value) => {
    if (value.length === 9) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
  
        if (response.data.erro) {
          swal({
            title: 'Ops!',
            text: 'CEP inválido. Verifique o CEP informado.',
            icon: 'error',
            timer: 2000,
            buttons: false,
          });
          setFormData(prevData => ({
            ...prevData,
            cep: '',
            address: '',
            district: '',
            city: '',
            state: '',
          }));
        } else {
          const { logradouro, bairro, cep, localidade, uf } = response.data;
  
          const ibgeToken = localStorage.getItem('IBGEtoken');
          const ibgeResponse = await axios.get(`${IBGECITIESSTATES_API}`, {
            headers: {
              Authorization: `Bearer ${ibgeToken}`,
            },
          });
  
          const cities = ibgeResponse.data;
          const cityExists = cities.some((city) => city.name.toLowerCase() === localidade.toLowerCase());
  
          if (cityExists) {
            setFormData(prevData => ({
              ...prevData,
              cep: cep,
              address: logradouro,
              district: bairro,
              city: localidade,
              state: uf,
            }));
          } else {
            setFormData(prevData => ({
              ...prevData,
              cep: cep,
              address: logradouro,
              district: bairro,
              city: '',
              state: uf,
            }));
          }
        }
      } catch (error) {
        console.error('Erro ao buscar CEP:', error);
      }
    } else {
      swal({
        title: 'Ops!',
        text: 'CEP inválido. Verifique o CEP informado.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      setFormData(prevData => ({
        ...prevData,
        cep: '',
        address: '',
        district: '',
        city: '',
        state: '',
      }));
    }
  };
  
  
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'cep') {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  
  useEffect(() => {
    const cepInput = document.querySelector('input[name="cep"]');
    
    if (cepInput && typeof handleCepBlur === 'function') {
      const handleBlur = () => handleCepBlur(cepInput.value);
      cepInput.addEventListener('blur', handleBlur);
      return () => {
        cepInput.removeEventListener('blur', handleBlur);
      };
    }
  }, [handleCepBlur]);
  
  // const handleCheckboxChange = (companyId) => {
  //   setSelectedCompanies(prevSelectedCompanies => {
  //     const updatedSelectedCompanies = {
  //       ...prevSelectedCompanies,
  //       [companyId]: !prevSelectedCompanies[companyId]
  //     };
  //     return updatedSelectedCompanies;
  //   });
  // };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const updatedFormData = {
      ...formData,
      cep: formData.cep.replace(/\D/g, ''),
      // group_id: selectedGroup,
      sex: selectedSex,
      user_master: selectedAdmin,
    };
  
    try {
      setLoadingSubmit(true);
  
      const response = await axios.put(`${USER_API}/${id}`, updatedFormData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      
      console.log(response);
      
      if (response.status === 200) {
        swal({
          title: 'OK!',
          text: 'Usuário atualizado com sucesso!',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        navigate('/cadastro/pessoa');
      } else {
        swal({
          title: 'Ops!',
          text: 'Ocorreu um erro ao editar dados',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Erro ao processar o formulário:', error);
    } finally {
      setLoadingSubmit(false);
    }
  };

  


  const campos = [
    ...EditUserfields,
    // {
    //   name: 'group_id',
    //   type: 'select',
    //   placeholder: 'Grupo de Usuário',
    //   fullWidth: true,
    //   width: 'calc(20% - 7.5px)',
    //   value: selectedGroup,
    //   onChange: (e) => setSelectedGroup(e.target.value),
    //   options: gruposDeAcesso.filter((grupo) => grupo.status === 1 && grupo.company_id === parseInt(localStorage.getItem('CompanyID'))).map((grupo) => grupo.id),
    // },
    {
      name: 'sex',
      type: 'select',
      placeholder: 'Sexo',
      fullWidth: true,
      width: 'calc(20% - 7.5px)',
      value: selectedSex,
      onChange: (e) => setSelectedSex(e.target.value),
      options: [
        { value: 0, label: 'Feminino' },
        { value: 1, label: 'Masculino' }
      ]
    },
    {
      name: 'status',
      type: 'select',
      placeholder: 'Status',
      fullWidth: true,
      width: 'calc(10% - 7.5px)',
      value: formData.status,
      onChange: handleChange,
      options: [
        { value: 0, label: 'Inativo' },
        { value: 1, label: 'Ativo' }
      ]
    },
    {
      name: 'user_master',
      type: 'select',
      placeholder: 'Nível de Usuário',
      fullWidth: true,
      width: 'calc(20% - 7.5px)',
      value: selectedAdmin,
      onChange: (e) => setSelectedAdmin(e.target.value),
      options: adminOptions
    },
  ];
  

  const handleGoBack = () => {
    window.history.back();
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <FormContainer>
      <TabContainer>
        <TabButton
          active={activeTab === 'user'}
          onClick={() => handleTabChange('user')}
        >
          Dados do Usuário
        </TabButton>
        {/* <TabButton
          active={activeTab === 'company'}
          onClick={() => handleTabChange('company')}
        >
          Empresas
        </TabButton> */}
      </TabContainer>

      {loading ? ( 
       <Loading><CircularIndeterminate/></Loading>
      ) : (
        <Container>
          <Title text={'Editar Usuário'} backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'}/>
          <Container1>
            <Form onSubmit={handleSubmit}>
            {activeTab === 'user' && (
              <>
                {campos.map((campo) => (
                  <ResponsiveDiv key={campo.name} width={campo.width}>
                    {campo.name === 'group_id' ? (
                      <Select
                        name={campo.name}
                        value={selectedGroup}
                        onChange={(e) => setSelectedGroup(e.target.value)}
                        style={{ width: '100%' }}
                      >
                        <option value="" disabled style={{ color: '#999' }}>
                          Grupo de Acesso
                        </option>
                        {gruposDeAcesso
                          .filter((grupo) => grupo.status === 1 && grupo.company_id === parseInt(localStorage.getItem('companyID')))
                          .map((grupo) => (
                            <option key={grupo.id} value={grupo.id}>
                              {grupo.name}
                            </option>
                          ))}
                      </Select>
                    ) : campo.name === 'status' ? (
                      <Select
                        name={campo.name}
                        value={formData.status}
                        onChange={handleChange}
                        style={{ width: '100%'  }}
                      >
                        <option disabled value="" style={{ color: '#999' }}>
                          Status
                        </option>
                        <option value={1}>Ativo</option>
                        <option value={0}>Inativo</option>
                      </Select>
                    ) : campo.name === 'sex' ? (
                      <Select
                        name={campo.name}
                        value={selectedSex}
                        onChange={(e) => setSelectedSex(e.target.value)}
                        style={{ width: '100%' }}
                      >
                        <option disabled value="" style={{ color: '#999' }}>
                          Sexo
                        </option>
                        <option value={0}>Feminino</option>
                        <option value={1}>Masculino</option>
                      </Select>
                    ) : campo.name === 'user_master' ? (
                      <Select
                        name={campo.name}
                        value={selectedAdmin}
                        onChange={(e) => setSelectedAdmin(e.target.value)}
                        style={{ width: '100%'  }}
                      >
                        <option disabled value="" style={{ color: '#999' }}>
                          Nível de Usuário
                        </option>
                        <option value={1}>Administrador</option>
                        <option value={2}>Professor</option>
                        <option value={0}>Aluno</option>
                      </Select>
                    ) : (
                      <Input
                        type={campo.type}
                        name={campo.name}
                        value={formData[campo.name]}
                        onChange={campo.onChange || handleChange}
                        mask={campo.mask}
                        maskChar={null}
                        required={campo.required}
                        disabled={campo.disabled}
                        fullWidth={campo.fullWidth}
                        style={{ width: '100%' }}
                      />
                    )}
                    <Label htmlFor={campo.name} >
                      {campo.placeholder}
                    </Label>
                  </ResponsiveDiv>
                ))}
              </>
            )}
                      
          {/* {activeTab === 'company' && (
              <div style={{ width: '100%', display:'flex', flexDirection:'column'}}>
                <Title text={'Matrizes'} fontSize={'28px'}/>
                <List>
                  {matrices.map((company) => (
                     <ListItem key={company.id}>
                     <Checkbox
                       id={`company-${company.id}`}
                       checked={selectedCompanies[company.id]}
                       onChange={() => handleCheckboxChange(company.id)}
                       label={company.fantasy_name}
                        info={ `|| ${company.district}, ${company.city}, ${company.state}, (${company.dd_cell_phone})${company.cell_phone}, ${company.email}`
                       }
                     />
                   </ListItem>
                  ))}
                </List>
                <Title text={'Filiais'} fontSize={'28px'}/>
                <List>
                  {subsidiaries.map((company) => (
                     <ListItem key={company.id}>
                     <Checkbox
                       id={`company-${company.id}`}
                       checked={selectedCompanies[company.id]}
                       onChange={() => handleCheckboxChange(company.id)}
                       label={company.fantasy_name}
                        info={ `|| ${company.district}, ${company.city}, ${company.state}, (${company.dd_cell_phone})${company.cell_phone}, ${company.email}`
                       }
                     />
                   </ListItem>
                  ))}
                </List>
              </div>
          )} */}
           <Buttons>
                <BackButton onClick={handleGoBack} />
                <SaveButton onClick={handleSubmit} disabled={loadingSubmit} />
              </Buttons>
              </Form>
          </Container1>
        </Container>
      )}
    </FormContainer>
  );
};

export default EditUser;