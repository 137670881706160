import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { Title } from '../../../components/General';
import { FORUM_API } from '../../../apiEndPoints';
import { BackButton } from '../../../components/Buttons';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { Inputmodules } from '../../../components/InputModules';

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
    margin-top: 1rem;
`;


const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

function PostQuestionPage() {
  const [title, setTitle] = useState('');
  const [question, setQuestion] = useState('');
  const [tags, setTags] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem('userId');

    const postData = {
      user_id: userId,
      title,
      question,
      tags: JSON.stringify(tags.split(',').map(tag => tag.trim()))
    };

    try {
      const response = await axios.post(FORUM_API, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.status === 201 || response.status === 200) {
        const questionId = response.data.id; 
        navigate(`/forum/question/${questionId}`);
        console.log(questionId)
      } else {
        console.error('Error posting question:', response.statusText);
      }
    } catch (error) {
      console.error('Error posting question:', error);
    }
  };
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <Container>
      <Title text="Faça seu Post" />
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Título"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <ReactQuill
          value={question}
          onChange={setQuestion}
          placeholder="Questão"
          required
          modules={Inputmodules}

        />
        <Input
          type="text"
          placeholder="Tags (separe com vírgulas)"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          required
        />
        <ButtonContainer>
        <BackButton
               onClick={handleGoBack}/>

          <button className="btn btn-primary" type="submit">Postar</button>
        </ButtonContainer>
      </form>
    </Container>
  );
}

export default PostQuestionPage;
