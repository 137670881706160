import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Spinner } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { COURSE_API, INSCRIPTIONCOURSE_API, GETMODULEBYTRAIL_API } from '../../../apiEndPoints';
import DOMPurify from 'dompurify';
import swal from 'sweetalert';

function CursoIntro() {
  const { id: IDGet } = useParams();
  const navigate = useNavigate(); // Hook para navegação programática
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState({ summary_course: '' });
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [error, setError] = useState(null);
  const [trails, setTrails] = useState([]);
  const [enrollments, setEnrollments] = useState({});

  useEffect(() => {
    const fetchCourseData = async () => {
      const storedToken = localStorage.getItem('token');
      const userId = parseInt(localStorage.getItem('userId'));

      try {
        const courseResponse = await axios.get(`${COURSE_API}/${IDGet}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setCourse(courseResponse.data);

        const enrollmentsResponse = await axios.get(INSCRIPTIONCOURSE_API, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const enrollmentsData = enrollmentsResponse.data.filter(enrollment => enrollment.user_id === userId);
        const enrollmentsMap = {};
        enrollmentsData.forEach((enrollment) => {
          enrollmentsMap[enrollment.course_id] = enrollment;
        });

        setEnrollments(enrollmentsMap);
        setIsEnrolled(enrollmentsMap[IDGet] ? enrollmentsMap[IDGet].status === 1 : false);

        const trailsResponse = await axios.get(`${GETMODULEBYTRAIL_API}/${IDGet}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setTrails(trailsResponse.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchCourseData();
  }, [IDGet]);

  const handleEnroll = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const storedToken = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const companyID = localStorage.getItem('companyID');
    const courseEnrollment = enrollments[IDGet];

    if (courseEnrollment) {
      if (courseEnrollment.status === 1) {
        const confirmCancel = await swal({
          title: "Você tem certeza?",
          text: "Você deseja cancelar a inscrição?",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true,
        });

        if (!confirmCancel) {
          return;
        }
      }

      try {
        await axios.put(
          `${INSCRIPTIONCOURSE_API}/${courseEnrollment.id}`,
          {
            status: courseEnrollment.status === 1 ? 0 : 1,
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        setEnrollments((prevEnrollments) => ({
          ...prevEnrollments,
          [IDGet]: { ...courseEnrollment, status: courseEnrollment.status === 1 ? 0 : 1 },
        }));
        setIsEnrolled(prev => !prev); // Alterna o estado de inscrição
      } catch (error) {
        console.error('Error updating enrollment:', error);
      }
    } else {
      try {
        const response = await axios.post(
          INSCRIPTIONCOURSE_API,
          {
            user_id: userId,
            course_id: IDGet,
            company_id: companyID,
            status: 1,
            trails_id: 1, // Mudar no futuro
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        setEnrollments((prevEnrollments) => ({
          ...prevEnrollments,
          [IDGet]: { id: response.data.id, status: 1 },
        }));
        setIsEnrolled(true); // Atualiza o estado para inscrito
      } catch (error) {
        console.error('Error enrolling in the course:', error);
      }
    }
  };

  if (loading) {
    return (
      <div className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" variant="primary" style={{ width: '3rem', height: '3rem' }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {/* Categories Start */}
      <div className="container-xxl py-5 category">
        <div className="text-center">
          <p style={{ fontSize: '22px', fontWeight: 'bold' }}>{course?.title}</p>
        </div>
        {/* Conteúdo */}
        <div className="container mt-5" id="introducao">
          <h2>Resumo do Curso</h2>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(course?.summary_course) }} />
        </div>

        <div className="container mt-5" id="modulos">
          <h2>Módulos</h2>
          {trails.map(trail => (
            <div className="row mb-4" key={trail.id}>
              <div className="col-md-4">
                <img
                  src={trail.arquivo}
                  alt={trail.title}
                  className="img-fluid"
                  style={{
                    width: '180px',
                    height: '120px',
                    objectFit: 'cover',
                    objectPosition: 'center'
                  }}
                />
              </div>
              <div className="col-md-8">
                <h3>{trail.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(trail.summary_modules) }} />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Categories End */}

      <div className="container">
        <div className="row">
          <div className="col footer-btn text-end">
            {isEnrolled ? (
              <a href={`/cursos/intro/${course.id}`} className="btn btn-primary d-grid gap-0 col-4 mx-auto">Iniciar</a>
            ) : (
              <button className="btn btn-primary d-grid gap-0 col-4 mx-auto" onClick={handleEnroll}>
                Inscrever-se
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Back to Top */}
    </div>
  );
}

export default CursoIntro;
