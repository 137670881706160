import React from "react";
import { TTRAILLIST_API} from "../../../apiEndPoints";
import theme from "../../../theme";
import Lists from "../../../components/Lists";
import { TrailCollumns } from "../../../components/TableColumns";


const TrailList = () => {
  const token = localStorage.getItem('token');

  return(
    <div>
      <Lists
      title={'Trilhas'}
      api={TTRAILLIST_API}
      buttonColor={theme.primaryColor}
      addURL={`/cadastro/trailregister`}
      editURL={`/edittrail`}
      filterdisplay='block'
      fields={TrailCollumns}
      token={token}
      applyCompanyIdFilter={true}
      />
    </div>
  )
}

export default TrailList