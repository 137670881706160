import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { EVALUATION_API, QUESTIONS_API, EVALUATIONRESPONSES_API, GETEVALUATIONRESPONSES_API } from '../../apiEndPoints';
import { SaveButton } from '../../components/Buttons';

const Evaluation = () => {
  const { id } = useParams();
  const [evaluation, setEvaluation] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(null);
  const [scorePercentage, setScorePercentage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    const fetchEvaluationAndQuestions = async () => {
      try {
        const existingResponse = await axios.get(GETEVALUATIONRESPONSES_API, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
    
        const userResponses = existingResponse.data.filter(
          (response) => response.user_id === parseInt(userId) && response.evaluation_id === parseInt(id)
        );
        console.log(userResponses, 'ff')
    
        if (userResponses.length > 0) {
          const userResponse = userResponses[0];
          const answers = JSON.parse(userResponse.answers);
          let correctAnswers = 0;
        
          const questionsResponse = await axios.get(QUESTIONS_API, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
        
          const filteredQuestions = questionsResponse.data.filter(
            (question) => question.evaluation_id === parseInt(id)
          );
        
          setQuestions(filteredQuestions);
        
          filteredQuestions.forEach((question, index) => {
            const correctAnswerIndex = JSON.parse(question.options).correctAnswer;
            if (answers[index] === correctAnswerIndex) {
              correctAnswers++;
            }
          });
        
          const totalQuestions = filteredQuestions.length;
          const percentage = Math.round((correctAnswers / totalQuestions) * 100);
        
          setCorrectAnswersCount(correctAnswers);
          setScorePercentage(percentage);
          setIsSubmitted(true);
        }  else {
          const evaluationResponse = await axios.get(`${EVALUATION_API}/${id}`, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
          setEvaluation(evaluationResponse.data);
    
          const questionsResponse = await axios.get(QUESTIONS_API, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
    
          const filteredQuestions = questionsResponse.data.filter(
            (question) => question.evaluation_id === parseInt(id)
          );
          setQuestions(filteredQuestions);
        }
      } catch (error) {
        console.error('Error fetching evaluation or questions:', error);
      }
    };

    fetchEvaluationAndQuestions();
  }, [id]);

  const handleAnswerChange = (questionIndex, answerIndex) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: answerIndex,
    }));
  };

  const handleSubmitEvaluation = async () => {
    const storedToken = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    try {
      await axios.post(EVALUATIONRESPONSES_API, {
        user_id: userId,
        evaluation_id: id,
        answers: JSON.stringify(userAnswers),
      }, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      let correctAnswers = 0;
      questions.forEach((question, index) => {
        const correctAnswerIndex = JSON.parse(question.options).correctAnswer;
        if (userAnswers[index] === correctAnswerIndex) {
          correctAnswers++;
        }
      });

      const totalQuestions = questions.length;
      const percentage = Math.round((correctAnswers / totalQuestions) * 100);

      setCorrectAnswersCount(correctAnswers);
      setScorePercentage(percentage);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting evaluation:', error);
    }
  };

  const finishEvaluation = () => {
    navigate('/course/completed');
  };

  const isAllQuestionsAnswered = questions.length > 0 && 
    questions.every((_, index) => userAnswers.hasOwnProperty(index));

  if (!evaluation && !isSubmitted) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="container d-flex justify-content-center align-items-start" style={{ padding: '40px' }}>
      <div className="bg-light p-5 rounded shadow w-100" style={{ maxWidth: '1200px', margin: '0 15px', backgroundColor: '#f8f9fa' }}>
        {isSubmitted ? (
          <>
            <h1 className="mb-4">{evaluation?.title}</h1>
            <p className="mb-4">Você acertou {correctAnswersCount}/{questions.length} ({scorePercentage}%).</p>
            <div className="d-flex justify-content-end mt-4">
              <button className="btn btn-primary" onClick={finishEvaluation}>Voltar</button>
            </div>
          </>
        ) : (
          <>
            <h1 className="mb-4">{evaluation.title}</h1>
            <p className="mb-4" dangerouslySetInnerHTML={{ __html: evaluation.description}}/>

            {questions.map((question, questionIndex) => (
              <div key={questionIndex} className="evaluation-question mb-4">
                <h3 dangerouslySetInnerHTML={{ __html: question.question_text }} />
                {JSON.parse(question.options).options.map((answer, answerIndex) => (
                  <div key={answerIndex}>
                    <input
                      type="radio"
                      id={`question-${questionIndex}-answer-${answerIndex}`}
                      name={`question-${questionIndex}`}
                      value={answerIndex}
                      onChange={() => handleAnswerChange(questionIndex, answerIndex)}
                      disabled={isSubmitted}
                    />
                    <label
                      htmlFor={`question-${questionIndex}-answer-${answerIndex}`}
                      style={{
                        display: 'inline-block',
                        padding: '5px 10px',
                        borderRadius: '4px',
                        margin: '5px 0',
                      }}
                      dangerouslySetInnerHTML={{ __html: answer }}
                    />
                  </div>
                ))}
              </div>
            ))}

            <div className="d-flex justify-content-end mt-4">
              <SaveButton
                backgroundColor={'#06BBCC'}
                className="btn btn-success"
                onClick={handleSubmitEvaluation}
                disabled={!isAllQuestionsAnswered}
              >
                Enviar
              </SaveButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Evaluation;
