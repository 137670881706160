import React from "react";
import { CATEGORY_API, CATEGORYLIST_API} from "../../../apiEndPoints";
import theme from "../../../theme";
import Lists from "../../../components/Lists";
import { CategoryCollumns, TrailCollumns } from "../../../components/TableColumns";


const CategoryList = () => {
  const token = localStorage.getItem('token');

  return(
    <div>
      <Lists
      title={'Categorias'}
      api={CATEGORYLIST_API}
      buttonColor={theme.primaryColor}
      addURL={`/cadastro/categoryregister`}
      editURL={`/editcategory`}
      filterdisplay='block'
      fields={CategoryCollumns}
      token={token}
      applyCompanyIdFilter={false}
      />
    </div>
  )
}

export default CategoryList