import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import styles from './Navbar.module.css';
import LogoutModal from '../Logoutbutton';
import '../../css/bootstrap1.min.css';
import swal from 'sweetalert';
import theme from '../../theme';
import styled from 'styled-components';
import { ME_API } from '../../apiEndPoints';


const CustomDropdownItem = styled.a`
  color: ${theme.portalFontColor};
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 1.5rem;
  
  &:hover {
    background-color: ${theme.portalBackgroundColor2};
    color: ${theme.portalFontColor}; 
  }
`

const CustomItemStatic = styled.p`
  color: ${theme.portalFontColor};
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 1.5rem;
  border-bottom: 1px solid white;
  
 `

const Navbar = () => {
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const navigate = useNavigate();
  const [userName, setUserName] = useState('');


    const handleLogout = () => {
        setShowLogoutModal(true);
    };

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        const fetchUserData = async () => {
          try {
            const response = await fetch(ME_API, {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${storedToken}`,
              },
            });
    
            if (response.ok) {
              const data = await response.json();
              setUserName(data.name);
            } else {
              console.error('Erro ao buscar dados do usuário');
            }
          } catch (error) {
            console.error('Erro na chamada da API:', error);
          }
        };
    
        fetchUserData();
      }, []);
    

    const handleLogoutConfirmed = () => {
        setShowLogoutModal(false);
        navigate('/login');
        localStorage.clear();
    };

    const handleCloseLogoutModal = () => {
        setShowLogoutModal(false);
    };

    const handlePasswordChange = () => {
        navigate('/newpassword');
    };

    useEffect(() => {
        const tokenStoredTimestamp = parseInt(localStorage.getItem('tokenTimestamp'));
        if (!isNaN(tokenStoredTimestamp)) {
            const currentTime = new Date().getTime();
            const elapsedTimeMilliseconds = currentTime - tokenStoredTimestamp;
            const minutesSinceTokenStored = Math.floor(elapsedTimeMilliseconds / (1000 * 60));
            if (minutesSinceTokenStored > 60) {
                localStorage.removeItem('token');
                swal({
                    title: 'Tempo expirado!',
                    text: 'Faça o Login novamente!',
                    icon: 'warning',
                    timer: 2000,
                    buttons: false,
                });
                navigate('/login');
            } else {
                console.log(minutesSinceTokenStored, 'minutos');
            }
        } else {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <div>
            <nav className={`navbar navbar-expand-lg bg-dark navbar-light shadow sticky-top p-0 ${styles.navbarCustom}`}>
                <a href='/home' className="navbar-brand d-flex align-items-center px-4 px-lg-5">
                    <h2 className="m-0 text-primary text-white"><i className="fa fa-book me-3"></i>Concentra EAD</h2>
                </a>
                <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0">
                        <li className="nav-item dropdown">
                            <a className="nav-link text-white" href="/trilhas" id="navbarDropdownMenuLink" role="button" aria-haspopup="true" aria-expanded="false">
                               <b>Trilhas</b>
                            </a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link text-white" href="/cursos" id="navbarDropdownMenuLink" role="button" aria-haspopup="true" aria-expanded="false">
                               <b>Cursos</b>
                            </a>
                        </li>
                    </div>

                    {/* Dropdown para o botão Perfil */}
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn py-4 px-lg-5 d-none d-lg-block text-black"
                            style={{ backgroundColor: theme.portalColor, border: 'none' }}
                        >
                            <b>Perfil</b>
                        </Dropdown.Toggle>

                        <Dropdown.Menu 
                        style={{ 
                            width: '25vw', 
                            padding: '20px', 
                            fontSize: '1.5rem',
                            backgroundColor: `${theme.portalTitleColor}`,
                        }}
                    >
                        <CustomItemStatic href="#">{userName}</CustomItemStatic>
                        <CustomDropdownItem href="#">Editar Perfil</CustomDropdownItem>
                        {/* <CustomDropdownItem href="#">Configurações</CustomDropdownItem> */}
                        {/* <CustomDropdownItem href="#">Histórico de Atividades</CustomDropdownItem> */}
                        <CustomDropdownItem href="#">Favoritos</CustomDropdownItem>
                        <CustomDropdownItem href="#">Suporte/Ajuda</CustomDropdownItem>
                        {/* <CustomDropdownItem href="#">Preferências</CustomDropdownItem>
                        <CustomDropdownItem href="#">Plano de Assinatura</CustomDropdownItem> */}
                        <CustomDropdownItem onClick={handlePasswordChange}>Alterar Senha</CustomDropdownItem>
                        <CustomDropdownItem onClick={handleLogout}>Sair</CustomDropdownItem>
                    </Dropdown.Menu>
                    </Dropdown>
                </div>
            </nav>

            {/* Modal de Logout */}
            <LogoutModal 
                showModal={showLogoutModal} 
                handleClose={handleCloseLogoutModal} 
                handleLogout={handleLogoutConfirmed} 
            />
        </div>
    );
};

export default Navbar;
