import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import routes from '../../routes';
import { SidebarToggleButton } from '../Buttons';
import styled from 'styled-components';

const ToggleButtonContainer = styled.div`
  display: ${props => (props.show ? 'block' : 'none')}; 
`;

const Sidebar = ({ toggleSidebar, isSidebarOpen }) => {
  const [cadastrosOpen, setCadastrosOpen] = useState(false);
  const [vendasOpen, setVendasOpen] = useState(false);
  const [comprasOpen, setComprasOpen] = useState(false);
  const [utilitiesOpen, setUtilitiesOpen] = useState(false);
  const [serasaOpen, setSerasaOpen] = useState(false);
  const [financeiroOpen, setFinanceiroOpen] = useState(false);
  const [fiscalOpen, setFiscalOpen] = useState(false);
  const [vistoriaOpen, setVistoriaOpen] = useState(false);
  const [oficinaOpen, setOficinaOpen] = useState(false);
  const [relatorioOpen, setRelatorioOpen] = useState(false);
  const [showToggleButton, setShowToggleButton] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setShowToggleButton(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCadastrosToggle = () => {
    setCadastrosOpen(!cadastrosOpen);
    closeOtherMenus('cadastros');
  };

  const handleVendasToggle = () => {
    setVendasOpen(!vendasOpen);
    closeOtherMenus('vendas');
  };

  const handleComprasToggle = () => {
    setComprasOpen(!comprasOpen);
    closeOtherMenus('compras');
  };


  const handleSerasaToggle = () => {
    setSerasaOpen(!serasaOpen);
    closeOtherMenus('serasa');
  };

  const handleUtilitiesToggle = () => {
    setUtilitiesOpen(!utilitiesOpen);
    closeOtherMenus('utilities');
  };

  const handleOficinaToggle = () => {
    setOficinaOpen(!oficinaOpen);
    closeOtherMenus('oficina');
  };
  const handleVistoriaToggle = () => {
    setVistoriaOpen(!vistoriaOpen);
    closeOtherMenus('vistoria');
  };
  const handleRelatorioToggle = () => {
    setRelatorioOpen(!relatorioOpen);
    closeOtherMenus('relatorio');
  };

  const handleFinanceiroToggle = () => {
    setFinanceiroOpen(!financeiroOpen);
    closeOtherMenus('financeiro');
  };
  const handleFiscalToggle = () => {
    setFiscalOpen(!fiscalOpen);
    closeOtherMenus('fiscal');
  };

  const closeOtherMenus = menu => {
    if (menu !== 'cadastros') setCadastrosOpen(false);
    if (menu !== 'compras') setComprasOpen(false);
    if (menu !== 'vendas') setVendasOpen(false);
    if (menu !== 'vistoria') setVistoriaOpen(false);
    if (menu !== 'oficina') setOficinaOpen(false);
    if (menu !== 'relatorio') setRelatorioOpen(false);
    if (menu !== 'utilities') setUtilitiesOpen(false);
    if (menu !== 'serasa') setSerasaOpen(false);
    if (menu !== 'financeiro') setFinanceiroOpen(false);
    if (menu !== 'fiscal') setFiscalOpen(false);
  };

  const handleSubmenuItemClicked = menu => {
    switch (menu) {
      case 'cadastros':
        setCadastrosOpen(false);
        break;
        case 'compras':
          setComprasOpen(false);
          break;
        case 'vendas':
          setVendasOpen(false);
          break;  
        case 'vistoria':
          setVistoriaOpen(false);
          break; 
       case 'oficina':
          setOficinaOpen(false);
          break; 
       case 'relatorio':
          setRelatorioOpen(false);
          break;
      case 'utilities':
        setUtilitiesOpen(false);
        break;
      case 'serasa':
        setSerasaOpen(false);
       break;
      case 'financeiro':
        setFinanceiroOpen(false);
      break;
        case 'fiscal':
        setFiscalOpen(false);
        break;
      default:
        break;
    }
  };

  const renderChildLinks = (categoryLabel, currentPath, menu) => {
    const category = routes.find(route => route.label === categoryLabel);

    if (category && category.children) {
      return category.children.map((child, index) => {
        const childPath = `${currentPath}${child.path}`.replace(/\/+/g, '/');
        return (
          <Link
            key={index}
            to={childPath} 
            className="collapse-item"
            style={{ cursor: 'pointer' }}
            onClick={() => handleSubmenuItemClicked(menu)}
          >
            {child.label}
          </Link>
        );
      });
    }
    return null;
  };

  return (
    <div
      className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${
        isSidebarOpen ? '' : 'toggled'
      }`}
      id="accordionSidebar"
      
    >
      <ToggleButtonContainer show={showToggleButton}>
      
      </ToggleButtonContainer>
      

      <ul className="navbar-nav accordion" id="accordionSidebar">
       
        <li className="nav-item active">
          <Link className="nav-link" to="/dashboard">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        <div className="sidebar-heading"></div>
        <li className="nav-item">
          <a
            className={`nav-link collapsed ${cadastrosOpen ? '' : 'collapsed'}`}
            onClick={handleCadastrosToggle}
            aria-expanded={cadastrosOpen}
            style={{ cursor: 'pointer' }}
          >
            <i className="fas fa-user-plus"></i>
            <span>Cadastros</span>
          </a>
          <Collapse in={cadastrosOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Cadastros</h6>
                {renderChildLinks('Cadastros', '/', 'cadastros')}
              </div>
            </div>
          </Collapse>
        </li>


        {/* <li className="nav-item">
          <a
            className={`nav-link collapsed ${comprasOpen ? '' : 'collapsed'}`}
            onClick={handleComprasToggle}
            aria-expanded={comprasOpen}
            style={{ cursor: 'pointer' }}
          >
            <i class="fas fa-shopping-cart"></i>
            <span>Compras</span>
          </a>
          <Collapse in={comprasOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Compras</h6>
                {renderChildLinks('Compras', '/', 'compras')}
              </div>
            </div>
          </Collapse>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${vendasOpen ? '' : 'collapsed'}`}
            onClick={handleVendasToggle}
            aria-expanded={vendasOpen}
            style={{ cursor: 'pointer' }}
          >
            <i class="fa fa-solid fa-money-bill"></i>
            <span>Vendas</span>
          </a>
          <Collapse in={vendasOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Vendas</h6>
                {renderChildLinks('Vendas', '/', 'vendas')}
              </div>
            </div>
          </Collapse>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${utilitiesOpen ? '' : 'collapsed'}`}
            onClick={handleUtilitiesToggle}
            aria-expanded={utilitiesOpen}
            style={{ cursor: 'pointer' }}
          >
            <i className="fas fa-box"></i>
            <span>Estoque</span>
          </a>
          <Collapse in={utilitiesOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Estoque</h6>
                {renderChildLinks('Utilities', '/', 'utilities')}{' '}
              
              </div>
            </div>
          </Collapse>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${financeiroOpen ? '' : 'collapsed'}`}
            onClick={handleFinanceiroToggle}
            aria-expanded={financeiroOpen}
            style={{ cursor: 'pointer' }}
          >
            <i className="fas solid fa-coins"></i>
            <span>Financeiro</span>
          </a>
          <Collapse in={financeiroOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Financeiro</h6>
                {renderChildLinks('Financeiro', '/', 'financeiro')}
              </div>
            </div>
          </Collapse>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${fiscalOpen ? '' : 'collapsed'}`}
            onClick={handleFiscalToggle}
            aria-expanded={fiscalOpen}
            style={{ cursor: 'pointer' }}
          >
            <i className="fas solid fa-hashtag"></i>
            <span>Fiscal</span>
          </a>
          <Collapse in={fiscalOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Fiscal</h6>
                {renderChildLinks('Fiscal', '/', 'fiscal')}
              </div>
            </div>
          </Collapse>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${oficinaOpen ? '' : 'collapsed'}`}
            onClick={handleOficinaToggle}
            aria-expanded={oficinaOpen}
            style={{ cursor: 'pointer' }}
          >
            <i class="fas fa-wrench"></i>
            <span>Oficina</span>
          </a>
          <Collapse in={oficinaOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Oficina</h6>
                {renderChildLinks('Oficina', '/', 'oficina')}
              </div>
            </div>
          </Collapse>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${vistoriaOpen ? '' : 'collapsed'}`}
            onClick={handleVistoriaToggle}
            aria-expanded={vistoriaOpen}
            style={{ cursor: 'pointer' }}
          >
            <i class="fas fa-search-plus"></i>
            <span>Vistoria</span>
          </a>
          <Collapse in={vistoriaOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Vistoria</h6>
                {renderChildLinks('Vistoria', '/', 'vistoria')}
              </div>
            </div>
          </Collapse>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${relatorioOpen ? '' : 'collapsed'}`}
            onClick={handleRelatorioToggle}
            aria-expanded={relatorioOpen}
            style={{ cursor: 'pointer' }}
          >
            <i class="fas fa-chart-line"></i>
            <span>Relatórios</span>
          </a>
          <Collapse in={relatorioOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Relatórios</h6>
                {renderChildLinks('Relatorio', '/', 'relatorio')}
              </div>
            </div>
          </Collapse>
        </li> */}

        <hr className="sidebar-divider d-none d-md-block" />
      </ul>
    </div>
  );
};

export default Sidebar;
