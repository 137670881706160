import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../vendor/fontawesome-free/css/all.min.css'
import '../../css/sb-admin-2.min.css'
 

function ButtonSubmit({ onClick, disabled }) {
  return (
    <div className="form-group">
      <button
        type="button" 
        className="btn btn-primary btn-user btn-block"
        onClick={onClick}
        disabled={disabled}
      >
        Login
      </button>
    </div>
  );
}


export default ButtonSubmit