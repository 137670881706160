import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { Link } from 'react-router-dom';
import SearchInput from '../../components/SearchInput';
import { AddNewButton, EditButton, FirstButton, LastButton, NextButton, PrevButton } from '../../components/Buttons';
import { Title } from '../General';
import theme from '../../theme';

const TableWrapper = styled.div`
  padding: 10px;
  max-width: 90%;
  margin: auto;
  margin-top: 20px;

  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      border: 1px solid #ddd;
      padding: 8px;
    }

    tbody tr {
      transition: background-color 0.3s; 

      &:hover {
        background-color: #f5f5f5;
        cursor: pointer;
      }
    }

    tbody td:hover {
      background-color: #f5f5f5; 
    }
  }
`;

const FilterWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  margin-left: 6rem;
`;

const Input = styled.input`
  width: 60px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  text-align: center;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const Select = styled.select`
  margin-left: 5px;
  border-radius: 10px;
  padding: 2px 0 2px 2px;
`;

const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 0 6rem;
  gap: 0 20px;
`;

const SearchDiv = styled.div`
  margin-left: auto;
  // margin-right: 10rem;
`;

const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const PageNumberButton = styled.button`
  color: ${(props) => props.color || 'black'};
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  opacity: ${(props) => (props.selected ? '1' : '0.5')}; /* Adjust opacity for selected page */

  ${(props) =>
    props.selected &&
    `
    background-color: ${props.selectedBackgroundColor || 'white'};
    color: ${props.selectedColor || 'white'};
  `}
`;

const Lists = ({ buttonColor, api, addURL, editURL, fields, title, filterdisplay, buttonAddDisplay, token, applyCompanyIdFilter = true }) => {
  const [subject, setSubject] = useState([]);
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [inputPageValue, setInputPageValue] = useState(1);

  const fetchData = useCallback(
    async (page) => {
      console.log('Fetching data for page:', page);
      try {
        const storedCompanyId = parseInt(localStorage.getItem('companyID'));
  
    
        const response = await axios.get(api, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            company_id: storedCompanyId,
            page: page, 
          },
        });
  
        const responseData = response.data.data; 
        const totalPages = response.data.last_page; 
        console.log(response.data);
  
        if (applyCompanyIdFilter) {
          const filteredSubjects = responseData.filter(subject => subject.company_id === storedCompanyId);
          setSubject(filteredSubjects);
        } else {
          setSubject(responseData);
        }
  
        setTotalPage(totalPages || 0); 
  
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
      }
    }, [api, token, applyCompanyIdFilter]);
  
  useEffect(() => {
    fetchData(currentPage); 
  }, [currentPage, fetchData]);
  
  
  

  useEffect(() => {
    fetchData(); 
  }, []);

  const filteredComponents = useMemo(() => 
    (subject || []).filter((item) => {
      const statusCondition = statusFilter === 'all' || item.status === (statusFilter === 'active' ? 1 : 0);
      const searchCondition = Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      );
      return statusCondition && searchCondition;
    }),
    [subject, statusFilter, searchTerm]
  );

  const columns = useMemo(() => [
    ...fields,
    {
      Header: 'Ação',
      accessor: 'id',
      Cell: ({ value }) => (
        <Link to={`${editURL}/${value}`}>
          <EditButton color={'blue'} />
        </Link>
      ),
    },
  ], [editURL, fields]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setGlobalFilter,
    page,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data: filteredComponents,
      initialState: { pageIndex: 0, pageSize: 20 },
      manualPagination: false,
    },
    useGlobalFilter,
    usePagination
  );

  const handleSearch = () => {
    if (currentPage == 1) {
      fetchData();
    } else {
      setCurrentPage(1);
    }
  }

  useEffect(() => {
    fetchData(); 
  }, [currentPage, fetchData]);

  return (
    <div>
      <FilterWrapper>
        <label style={{ display: filterdisplay }}>
          Filtrar por Status:
          <Select
            defaultValue={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}>
            <option value="all">Todos</option>
            <option value="active">Ativos</option>
            <option value="inactive">Inativos</option>
          </Select>
        </label>
      
      </FilterWrapper>

      <ContainerHeader>
        <Title text={title} />
        <AddNewButton 
          as={Link} 
          to={addURL}
          backgroundColor={buttonColor}
          displaybutton={buttonAddDisplay || 'block'}
        />

<SearchDiv>
  <SearchInput
    customStyle={true}
    searchTerm={state.globalFilter || ''}
    setSearchTerm={(value) => setGlobalFilter(value)}
    handleSearch={handleSearch}
  />
</SearchDiv>
      </ContainerHeader>

      <TableWrapper>
        <table {...getTableProps()} className="table table-bordered table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    {column.canSort && (
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' ↓'
                            : ' ↑'
                          : ''}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableWrapper>

      <ContainerPagination>
  <Pagination>
    <PrevButton
      onClick={() => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          setInputPageValue(currentPage - 1);
        }
      }}
      disabled={currentPage <= 1 || loading}
      backgroundColor={buttonColor}
    />
    <NextButton
      onClick={() => {
        if (currentPage < totalPage) {
          setCurrentPage(currentPage + 1); 
          setInputPageValue(currentPage + 1);
        }
      }}
      disabled={(currentPage >= totalPage) || loading}
      backgroundColor={buttonColor}
    />
    <div>
      <div>
        <span style={{'font-weight': 'bold'}}>&nbsp;&nbsp;
          <Input 
            value={inputPageValue} 
            onChange={(e) =>  setInputPageValue(e.target.value.replace(/[^0-9]/g, ''))}
            onBlur={(e) => {
              const valueOnlyNumbers = e.target.value.replace(/[^0-9]/g, '');
              if (valueOnlyNumbers) {
                const newPage = parseInt(valueOnlyNumbers, 10);
                if (newPage >= 1 && newPage <= totalPage) {
                  setCurrentPage(newPage); 
                }
              }
            }} 
          /> &nbsp;{`de ${totalPage}`}
        </span>
      </div>
    </div>
  </Pagination>
</ContainerPagination>

    </div>
  );
};

export default Lists;
