import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as solidHeart, faHeartBroken as regularHeart } from '@fortawesome/free-solid-svg-icons';

const CardContainer = styled.div`
  width: 185px; 
  height: 280px; 
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  margin: 10px;
  background-color: #f8f9fa;
`;

const CardHeader = styled.div`
  background-color: #f5f5f5;
  padding: 0px;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
`;

const CardImages = styled.div`
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
`;

const CardImage = styled.img`
  width: 100%;
  height: 140px; 
  object-fit: cover;
  object-position: center;
    transition: transform 0.3s ease; 
  
  &:hover {
    transform: scale(1.1); 
  }
`;

const CardContent = styled.div`
  text-align: center;
  height: 90px;
  overflow: hidden;
`;

const CardFooter = styled.div`
  padding: 5px;
  background-color: #DCDCDC;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LikeButton = styled.button`
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
`;

const IconWrapper = styled.div`
  transition: transform 0.3s ease, opacity 0.3s ease;
  ${(props) =>
    props.like
      ? css`
          transform: scale(1.2);
          opacity: 1;
        `
      : css`
          transform: scale(1);
          opacity: 0.7;
        `}
`;

const Button = styled.button`
  padding: 5px 20px;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
  ${(props) =>
    props.enrolled
      ? css`
          background-color: #28a745;
        `
      : css`
          background-color: #06BBCC; 
        `}
`;

const CourseCard = ({ course, enrolled, like, likeCount, onEnroll, onLike }) => {
  return (
    <CardContainer>
      {/* <CardHeader>Curso</CardHeader> */}
      <a href={`/cursos/${course.id}`} className="d-block">
        <CardImages>
          <CardImage
            className="img-fluid"
            src={course.arquivo}
            alt={course.title}
          />
        </CardImages>
      </a>
      <CardContent>
        <h3 className="mb-0">{course.price}</h3>
        <div className="mb-3">
          {Array.from({ length: course.rating }).map((_, i) => (
            <small key={i} className="fa fa-star text-primary"></small>
          ))}
        </div>
        <h5 className="mb-4">{course.title}</h5>
      </CardContent>
      <CardFooter>
        <Button enrolled={enrolled} onClick={onEnroll}>
          {enrolled ? 'Matricualdo' : 'Matricular'}
        </Button>
        <LikeButton onClick={onLike}>
          <IconWrapper like={like}>
            <FontAwesomeIcon 
              icon={like ? solidHeart : regularHeart} 
              color={like ? 'red' : 'grey'}
            />
            <span style={{ marginLeft: '5px' }}>
              {likeCount >= 1000 ? `${(likeCount / 1000).toFixed(1)}k` : likeCount}
            </span>
          </IconWrapper>
        </LikeButton>
      </CardFooter>
    </CardContainer>
  );
};

export default CourseCard;
