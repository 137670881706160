import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { GETCOMPLETETRAIL_API, INSCRIPTIONCOURSE_API, LIKES_API } from '../../../apiEndPoints';
import styled, { css } from 'styled-components';
import swal from 'sweetalert';
import CourseCard from '../../../components/CourseCard';
import TrailCardReg from '../../../components/TrailCardReg';

const FlexContainer = styled.div`
  display: flex;
  // justify-content: space-between;

`;




function TrailsCourse() {
  const { id: IDGet } = useParams();
  const [loading, setLoading] = useState(true);
  const [trails, setTrails] = useState([]);
  const [error, setError] = useState(null);
  const [enrollments, setEnrollments] = useState({});
  const [likes, setLikes] = useState({});
  const [likeCounts, setLikeCounts] = useState({});

  useEffect(() => {
    const fetchTrailsAndData = async () => {
      const storedToken = localStorage.getItem('token');
      const companyID = parseInt(localStorage.getItem('companyID'));
      const userId = parseInt(localStorage.getItem('userId'));

      try {
        const trailsResponse = await axios.get(`${GETCOMPLETETRAIL_API}/${IDGet}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const filteredTrails = trailsResponse.data.filter(trail => trail.company_id === companyID);
        setTrails(filteredTrails);

        const courses = trailsResponse.data.flatMap(trail => trail.course);

        const enrollmentsResponse = await axios.get(INSCRIPTIONCOURSE_API, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const likesResponse = await axios.get(LIKES_API, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const userEnrollments = enrollmentsResponse.data.filter(enrollment => enrollment.user_id === userId);
        const userLikes = likesResponse.data.filter(like => like.user_id === userId);


          const enrollmentsData = {};
          userEnrollments.forEach((enrollment) => {
            if (enrollment.course_id) {
              enrollmentsData[enrollment.course_id] = { ...enrollment, type: 'course' }; 
            } else if (enrollment.trails_id) {
              enrollmentsData[enrollment.trails_id] = { ...enrollment, type: 'trail' }; 
            }
          });

        const likesData = {};
        userLikes.forEach((like) => {
          likesData[like.course_id] = like;
        });

        const likeCountsData = {};
        likesResponse.data.forEach((like) => {
          if (like.like) {
            likeCountsData[like.course_id] = (likeCountsData[like.course_id] || 0) + 1;
          }
        });

        setEnrollments(enrollmentsData);
        setLikes(likesData);
        setLikeCounts(likeCountsData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchTrailsAndData();
  }, []);


  const handleTrailEnroll = async (trailId, event) => {
    event.preventDefault();
    event.stopPropagation();
  
    const enrollment = enrollments[trailId]; 
    const storedToken = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const companyID = localStorage.getItem('companyID');
  
    if (enrollment && enrollment.id) {
      try {
        await axios.put(`${INSCRIPTIONCOURSE_API}/${enrollment.id}`, {
          status: enrollment.status === 1 ? 0 : 1,
        },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setEnrollments((prevEnrollments) => ({
          ...prevEnrollments,
          [trailId]: { ...enrollment, status: enrollment.status === 1 ? 0 : 1 },
        }));
      } catch (error) {
        console.error('Error updating enrollment:', error);
      }
    } else {
      try {
        const response = await axios.post(INSCRIPTIONCOURSE_API, {
          user_id: userId,
          trail_id: trailId, 
          company_id: companyID,
          status: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setEnrollments((prevEnrollments) => ({
          ...prevEnrollments,
          [trailId]: { id: response.data.id, status: 1, type: 'trail' },
        }));
      } catch (error) {
        console.error('Error enrolling in the trail:', error);
      }
    }
  };
  

  const handleEnroll = async (courseId, event) => {
    event.preventDefault();
    event.stopPropagation();

    const enrollment = enrollments[courseId];
    const storedToken = localStorage.getItem('token');
    const userId = parseInt(localStorage.getItem('userId'));
    const companyID = parseInt(localStorage.getItem('companyID'));

    if (enrollment && enrollment.id) {
        if (enrollment.status === 1) {
            const confirmCancel = await swal({
                title: "Você tem certeza?",
                text: "Você deseja cancelar a inscrição?",
                icon: "warning",
                buttons: ["Cancelar", "Confirmar"],
                dangerMode: true,
            });

            if (!confirmCancel) {
                return;
            }
        }

        try {
            await axios.put(
                `${INSCRIPTIONCOURSE_API}/${enrollment.id}`,
                {
                    status: enrollment.status === 1 ? 0 : 1,
                },
                {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                }
            );

            setEnrollments((prevEnrollments) => ({
                ...prevEnrollments,
                [courseId]: { ...enrollment, status: enrollment.status === 1 ? 0 : 1 },
            }));
        } catch (error) {
            console.error('Error updating enrollment:', error);
        }
    } else {
        try {
            const response = await axios.post(
                INSCRIPTIONCOURSE_API,
                {
                    user_id: userId,
                    course_id: courseId,
                    company_id: companyID,
                    trails_id: 1, //MUDAR NO FUTURO
                    status: '1',
                },
                {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                }
            );

            setEnrollments((prevEnrollments) => ({
                ...prevEnrollments,
                [courseId]: { id: response.data.id, status: 1, type: 'course' },
            }));
        } catch (error) {
            console.error('Error enrolling in the course:', error);
        }
    }
};

  const handleLike = async (courseId, event) => {
    event.preventDefault();
    event.stopPropagation();

    const like = likes[courseId];
    const storedToken = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const companyID = localStorage.getItem('companyID');

    if (like && like.id) {
      try {
        const response = await axios.put(
          `${LIKES_API}/${like.id}`,
          {
            like: !like.like,
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        setLikes((prevLikes) => ({
          ...prevLikes,
          [courseId]: { ...like, like: !like.like },
        }));
        setLikeCounts((prevLikeCounts) => ({
          ...prevLikeCounts,
          [courseId]: like.like ? (prevLikeCounts[courseId] || 1) - 1 : (prevLikeCounts[courseId] || 0) + 1,
        }));
      } catch (error) {
        console.error('Error updating like:', error);
      }
    } else {
      try {
        const response = await axios.post(
          LIKES_API,
          {
            user_id: userId,
            course_id: courseId,
            like: true,
            company_id: companyID,
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        setLikes((prevLikes) => ({
          ...prevLikes,
          [courseId]: { id: response.data.id, like: true },
        }));
        setLikeCounts((prevLikeCounts) => ({
          ...prevLikeCounts,
          [courseId]: (prevLikeCounts[courseId] || 0) + 1,
        }));
      } catch (error) {
        console.error('Error liking the course:', error);
      }
    }
  };


  if (loading) {
    return (
      <div className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" variant="primary" style={{ width: '3rem', height: '3rem' }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        {/* <h6 className="section-title bg-white text-center text-primary px-3" style={{ margin: '30px' }}>Trilhas</h6> */}
      </div>

      <div className="container">
        {trails.map((trail) => (
          <div key={trail.id} className="mb-5">
            <p> </p>
            <FlexContainer>
            <h2>Trilha: {trail.title}</h2> 
            {/* <TrailCardReg
              trailEnrolled={
                enrollments[trail.id] && enrollments[trail.id].status === 1 && enrollments[trail.id].type === 'trail' 
              }
              trailOnenroll={(event) => handleTrailEnroll(trail.id, event)} 
            /> */}
          </FlexContainer>
            <p>{trail.description}</p>

            {trail.course && trail.course.length > 0 ? (
            <div className="row g-4">
              {trail.course
                .filter(course => course.status === 1) // Filtro para cursos com status == 1
                .map((course) => (
                  <div className="col-lg-2 col-md-4" key={course.id}>
                    <CourseCard
                      course={course}
                      enrolled={
                          enrollments[course.id] && enrollments[course.id].status === 1 && enrollments[course.id].type === 'course'
                      }
                      like={likes[course.id] && likes[course.id].like}
                      likeCount={likeCounts[course.id] || 0}
                      onEnroll={(event) => handleEnroll(course.id, event)} 
                      onLike={(event) => handleLike(course.id, event)}
/>

                  </div>
                ))}
            </div>
          ) : (
            <p>Essa trilha ainda não possui cursos</p>
          )}
          </div>
        ))}
      </div>

      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="fas fa-caret-up"></i>
      </a>
    </div>
  );
}

export default TrailsCourse;
