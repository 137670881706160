// Footer.js
import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
   width: 100%;
  background: radial-gradient(circle, rgba(211, 211, 211, 1) 0%, rgba(211, 211, 211, 0.8) 40%, rgba(211, 211, 211, 0.6) 70%, rgba(211, 211, 211, 0.4) 100%);
  color: #969a92;
`;

const Container = styled.div`
  padding: 13px;
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
       
        <div className="external-link text-center">
          <a href="https://www.concentraautos.com" target="_blank" rel="noopener noreferrer">
          <p>Copyright &copy; Concentra Autos 2024</p> 
          </a>
        </div>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
