import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { COURSE_API, GETMODULEBYTRAIL_API, INSCRIPTIONCOURSE_API } from '../../../apiEndPoints';
import DOMPurify from 'dompurify';
import swal from 'sweetalert';

function CursoIntro() {
  const { id: IDGet } = useParams();
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState({ summary_course: '' });
  const [trails, setTrails] = useState([]);
  const [error, setError] = useState(null);
  const [enrollment, setEnrollment] = useState(null);

  const fetchCourseData = async () => {
    const storedToken = localStorage.getItem('token');
    try {
      const response = await axios.get(`${COURSE_API}/${IDGet}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      setCourse(response.data);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchTrailsData = async () => {
    const storedToken = localStorage.getItem('token');
    try {
      const response = await axios.get(`${GETMODULEBYTRAIL_API}/${IDGet}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      const filteredTrails = response.data.filter(trail => trail.status == 1);
      setTrails(filteredTrails);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchEnrollmentData = async () => {
    const storedToken = localStorage.getItem('token');
    const userId = parseInt(localStorage.getItem('userId'));

    try {
      const response = await axios.get(INSCRIPTIONCOURSE_API, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      const userEnrollment = response.data.find(enrollment => 
        enrollment.course_id === parseInt(IDGet) && enrollment.user_id === userId
      );

      setEnrollment(userEnrollment || null);
    } catch (error) {
      console.error('Error fetching enrollment data:', error);
    }
  };

  const handleError = (error) => {
    if (error.response) {
      setError(`Erro: ${error.response.status} - ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      setError('Erro: Sem resposta do servidor. Por favor, tente novamente mais tarde.');
    } else {
      setError(`Erro: ${error.message}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchCourseData();
    fetchTrailsData();
    fetchEnrollmentData();
  }, [IDGet]);

  const handleEnroll = async () => {
    const storedToken = localStorage.getItem('token');
    const userId = parseInt(localStorage.getItem('userId'));
    const companyID = parseInt(localStorage.getItem('companyID'));

    if (enrollment && enrollment.id) {
      if (enrollment.status === 1) {
        const confirmCancel = await swal({
          title: "Você tem certeza?",
          text: "Você deseja cancelar a inscrição?",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true,
        });
  
        if (!confirmCancel) {
          return;
        }
      }

      try {
        await axios.put(
          `${INSCRIPTIONCOURSE_API}/${enrollment.id}`,
          {
            status: enrollment.status === 1 ? 0 : 1,
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        setEnrollment({ ...enrollment, status: enrollment.status === 1 ? 0 : 1 });
      } catch (error) {
        console.error('Error updating enrollment:', error);
      }
    } else {
      try {
        const response = await axios.post(
          INSCRIPTIONCOURSE_API,
          {
            user_id: userId,
            course_id: IDGet,
            company_id: companyID,
            trails_id: 1, //MUDAR NO FUTURO
            status: '1',
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        setEnrollment({ id: response.data.id, status: 1 });
      } catch (error) {
        console.error('Error enrolling in the course:', error);
      }
    }
  };

  if (loading) {
    return (
      <div className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" variant="primary" style={{ width: '3rem', height: '3rem' }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* Categories Start */}
      <div className="container-xxl py-5 category">
        <div className="text-center">
          <p style={{ fontSize: '34px', fontWeight: 'bold' }}>{course?.title}</p>
        </div>
        {/* Conteúdo */}
        <div className="container mt-5" id="introducao">
          <h2>Resumo do Curso</h2>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(course?.summary_course) }} />
        </div>

        {/* <div className="container mt-5" id="modulos">
          <h2>Módulos</h2>
          {trails.map(trail => (
            <div className="row mb-4" key={trail.id}>
              <div className="col-md-4">
                <img
                  src={trail.arquivo}
                  alt={trail.title}
                  className="img-fluid"
                  style={{
                    width: '180px',
                    height: '120px',
                    objectFit: 'cover',
                    objectPosition: 'center'
                  }}
                />
              </div>
              <div className="col-md-8">
                <h3>{trail.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(trail.summary_modules) }} />
              </div>
            </div>
          ))}
        </div> */}
      </div>
      {/* Categories End */}

      {/* Footer Button */}
      <div className="container">
        <div className="row">
          <div className="col footer-btn text-end">
            {!enrollment || enrollment.status !== 1 ? (
              <button className="btn btn-secondary  gap-0 mx-auto" onClick={handleEnroll}>Inscrever-se</button>
            ) : null}
            {enrollment && enrollment.status == 1 ? (
              <a href={`/curso/modulos/${course.id}`} className="btn btn-primary gap-0  mx-auto">Iniciar</a>
            ) : null}
          </div>
        </div>
      </div>

    </div>
  );
}

export default CursoIntro;
