export const EditUserfields = [
    { name: 'name', type: 'text', placeholder: 'Nome Completo', width: 'calc(99% - 7.5px)', },
    { name: 'email', type: 'email', placeholder: 'Email', width: 'calc(48% - 7.5px)' },
    { name: 'fone', type: 'text', placeholder: 'Telefone', width: 'calc(25% - 7.5px)', mask: '(99)99999-9999' },
    { name: 'cep', type: 'text', placeholder: 'CEP', width: 'calc(24% - 7.5px)', mask: '99999-999' },
    { name: 'address', type: 'text', placeholder: 'Endereço', width: 'calc(73% - 7.5px)' },
    { name: 'number', type: 'text', placeholder: 'Número', width: 'calc(25% - 7.5px)' },
    { name: 'district', type: 'text', placeholder: 'Bairro', width: 'calc(42% - 7.5px)' },
    { name: 'city', type: 'text', placeholder: 'Cidade', width: 'calc(38% - 7.5px)', disabled: true },
    { name: 'state', type: 'text', placeholder: 'Estado', width: 'calc(17% - 7.5px)',disabled: true }
    ]


    export const Userfields = [
    { name: 'name', type: 'text', placeholder: 'Nome Completo *', width: 'calc(98% - 7.5px)',required:true },
    { name: 'email', type: 'email', placeholder: 'Email *', width: 'calc(48% - 7.5px)',required:true },
    { name: 'password', type: 'password', placeholder: 'Senha *', width: 'calc(50% - 7.5px)',required:true },
    { name: 'cpf', type: 'text', placeholder: 'CPF *', width: 'calc(32% - 7.5px)', mask: '999.999.999-99',required:true },
    { name: 'fone', type: 'text', placeholder: 'Telefone *', width: 'calc(33% - 7.5px)', mask: '(99)99999-9999',required:true },
    { name: 'cep', type: 'text', placeholder: 'CEP', width: 'calc(33% - 7.5px)', mask: '99999-999' },
    { name: 'address', type: 'text', placeholder: 'Endereço', width: 'calc(73% - 7.5px)' },
    { name: 'number', type: 'text', placeholder: 'Número', width: 'calc(25% - 7.5px)' },
    { name: 'district', type: 'text', placeholder: 'Bairro', width: 'calc(56% - 7.5px)' },
    { name: 'city', type: 'text', placeholder: 'Cidade', width: 'calc(42% - 7.5px)' },
    { name: 'state', type: 'text', placeholder: 'Estado', width: 'calc(20% - 7.5px)'}
    ]

    export const sexOptions = [
        { label: 'Masculino', value: 1, id:2},
        { label: 'Feminino', value: 0 , id:1},

    ]
    export const adminOptions = [
        { label: 'Administrador', value: 1, id:1},
        { label: 'Professor', value: 2, id:3},
        { label: 'Aluno', value: 0 , id:2},

    ]

    export const statusOptions = [
        { label: 'Ativo', value: 1, id:2},
        { label: 'Inativo', value: 0 , id:1},
    ]