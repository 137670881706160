import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, GETLESSONBYMODULE_API, MODULE_API } from '../../../apiEndPoints';
import { SaveButton } from '../../../components/Buttons';

const Lesson = () => {
  const { moduleId, lessonId } = useParams();
  const [lessons, setLessons] = useState([]);
  const [courseId, setCourseId] = useState('');
  const [currentLessonIndex, setCurrentLessonIndex] = useState(null);
  const [quiz, setQuiz] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});
  const [quizResult, setQuizResult] = useState(null);
  const [videoKey, setVideoKey] = useState(0); // Chave para forçar a atualização do vídeo
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const fetchLessons = async () => {
      try {
        const response = await axios.get(`${GETLESSONBYMODULE_API}/${moduleId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const courseresponse = await axios.get(`${MODULE_API}/${moduleId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setCourseId(courseresponse.data.courses_id);
        console.log(response.data); 
        setLessons(response.data);

        const index = response.data.findIndex((lesson) => lesson.id === parseInt(lessonId));
        setCurrentLessonIndex(index);

        if (index !== -1) {
          const lesson = response.data[index];
          if (lesson.quiz) {
            setQuiz(JSON.parse(lesson.quiz));
            setUserAnswers({});
            setQuizResult(null);
          } else {
            setQuiz([]);
            setUserAnswers({});
            setQuizResult(null);
          }
        }

        setVideoKey((prevKey) => prevKey + 1); // Forçar atualização do vídeo

      } catch (error) {
        console.error('Error fetching lessons:', error);
      }
    };

    fetchLessons();
  }, [moduleId, lessonId]); // Atualiza sempre que o `lessonId` mudar

  const handleAnswerChange = (questionIndex, answerIndex) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: answerIndex,
    }));
    setQuizResult(null);
  };

  const handleSubmitQuiz = () => {
    const results = quiz.map((question, index) => ({
      question: question.question,
      isCorrect: userAnswers[index] === question.correctAnswer,
    }));
    setQuizResult(results);
  };

  const goToNextLesson = () => {
    if (currentLessonIndex !== null && currentLessonIndex < lessons.length - 1) {
      const nextLesson = lessons[currentLessonIndex + 1];
      navigate(`/modules/${moduleId}/lessons/${nextLesson.id}`);
    } else {
      navigate(`/curso/${courseId}/completion`);
    }
  };

  const goToPreviousLesson = () => {
    if (currentLessonIndex > 0) {
      const previousLesson = lessons[currentLessonIndex - 1];
      navigate(`/modules/${moduleId}/lessons/${previousLesson.id}`);
    }
  };

  const goToForum = () => {
    window.open(`/forum/lesson/${lessonId}`, '_blank');
  };

  if (lessons.length === 0 || currentLessonIndex === null) {
    return <div>Loading...</div>;
  }

  const currentLesson = lessons[currentLessonIndex];
  const isLastLesson = currentLessonIndex === lessons.length - 1;
  const hasQuiz = quiz.length > 0;
  const hasValidQuestions = quiz.some((question) => question.question.trim() !== '');
  const allAnswersCorrect = hasQuiz
    ? quizResult
      ? quiz.filter((question) => question.question.trim() !== '').every((question, index) => quizResult[index]?.isCorrect)
      : !hasValidQuestions
    : true;

  return (
    <div className="container d-flex justify-content-center align-items-start" style={{ padding: '40px' }}>
      <div className="bg-light p-5 rounded shadow w-100" style={{ maxWidth: '1200px', margin: '0 15px', backgroundColor: '#f8f9fa' }}>
        <h1 className="mb-4">{currentLesson.title}</h1>

        {currentLesson.video && (
          <div className="lesson-video mb-4">
            <video key={videoKey} controls width="100%">
              <source src={`${API_BASE_URL}/storage/${currentLesson.video}`} type="video/mp4" />
              <source src={`${API_BASE_URL}/storage/${currentLesson.videoWebm}`} type="video/webm" />
              <source src={`${API_BASE_URL}/storage/${currentLesson.videoOgg}`} type="video/ogg" />
              Seu navegador não suporta o elemento <code>video</code>.
            </video>
          </div>
        )}

        <div className="mb-4" dangerouslySetInnerHTML={{ __html: currentLesson.summary_lessons }} />

        {hasQuiz && (
          <div className="quiz-section mb-4">
            {quiz.map((question, index) =>
              question.question ? (
                <div key={index} className="quiz-question mb-3">
                  <h3 dangerouslySetInnerHTML={{ __html: question.question }} />
                  {question.answers.map((answer, answerIndex) => {
                    const isSelected = userAnswers[index] === answerIndex;
                    const isCorrect = quizResult && quizResult[index]?.isCorrect;
                    const isUserAnswerCorrect = isSelected && isCorrect;
                    const isUserAnswerWrong = isSelected && !isCorrect;

                    return (
                      <div key={answerIndex}>
                        <input
                          type="radio"
                          id={`question-${index}-answer-${answerIndex}`}
                          name={`question-${index}`}
                          value={answerIndex}
                          onChange={() => handleAnswerChange(index, answerIndex)}
                        />
                        <label
                          htmlFor={`question-${index}-answer-${answerIndex}`}
                          style={{
                            backgroundColor: quizResult
                              ? isUserAnswerCorrect
                                ? 'rgba(0, 128, 0, 0.1)'
                                : isUserAnswerWrong
                                ? 'rgba(255, 0, 0, 0.1)'
                                : ''
                              : '',
                            border: quizResult
                              ? isUserAnswerCorrect
                                ? '1px solid green'
                                : isUserAnswerWrong
                                ? '1px solid red'
                                : ''
                              : '',
                            display: 'inline-block',
                            padding: '5px 10px',
                            borderRadius: '4px',
                            margin: '5px 0',
                          }}
                          dangerouslySetInnerHTML={{ __html: answer }}
                        ></label>
                      </div>
                    );
                  })}
                </div>
              ) : null
            )}
            {hasValidQuestions && (
              <SaveButton backgroundColor={'#06BBCC'} className="btn btn-success" onClick={handleSubmitQuiz}>
                Submit
              </SaveButton>
            )}
            {quizResult && (
              <div className="quiz-result mb-4">
                {quizResult.map((result, index) => (
                  <div key={index} className={`quiz-result-item ${result.isCorrect ? 'text-success' : 'text-danger'}`}>
                    <p>{result.isCorrect ? 'Correto' : 'Incorreto'}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        <div className="d-flex justify-content-between">
          <button className="btn btn-primary" onClick={goToPreviousLesson} disabled={currentLessonIndex === 0}>
            <i className="fa fa-arrow-left ms-1" />
          </button>
          <button className="btn btn-primary" onClick={goToForum}>
            Forum
          </button>
          <button className="btn btn-primary" onClick={goToNextLesson} disabled={!allAnswersCorrect}>
            {isLastLesson ? 'Finalizar' : <i className="fa fa-arrow-right ms-1"></i>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Lesson;
