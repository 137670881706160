import React from 'react';





//Companies

export const CompanyData = {
  razao_social: '',
  email: '',
  fantasy_name: '',
  cnpj: '',
  zip_code: '',
  address: '',
  district: '',
  cell_phone: '',
  phone:'',
  city: '',
  state: '',
  matrix: '',
  company_id: '',
  dd_cell_phone: '',
  dd_phone:'',
}

//People

export const EditPersonData = {
  name: '',
  document: '',
  state_registration: '',
  zip_code: '',
  address: '',
  district:'',
  city:'',
  state:'',
  dd_1:'',
  phone_1: '',
  dd_2:'',
  phone_2: '',
  email: '',
  economic_activity: '',
  rg: '',
  profession: '',
  type_people: '',
  status: '',
  company_id: '',
}  

export const PersorRegisterFormData = {
  name: '',
  document: '',
  state_registration: '',
  zip_code: '',
  address: '',
  district:'',
  city:'',
  state:'',
  dd_1:'',
  phone_1: '',
  dd_2:'',
  phone_2: '',
  email: '',
  economic_activity: '',
  rg: '',
  profession: '',
  type_people: '1',
  status: '',
  company_id: '',
  }


//Users

export const EditUserData = {
  name: '',
  email: '',
  fone: '',
  cep: '',
  address: '',
  number: '',
  district: '',
  city: '',
  state: '',
  group_id: '',
  status: '',
  user_master:'',
  sex:'',
}  

export const UserRegisterFormData = {
  name: '',
  email: '',
  password: '',
  cpf: '',
  fone: '',
  cep: '',
  address: '',
  number: '',
  district: '',
  city: '',
  state: '',
  group_id: '',
  user_master:'',
  sex:'',
  status: 1,
}  


export const TableCFOPFormdata ={
  code:'',
  description:''
}

export const CnaeFormdata ={
  cnae_class_id: '',
  code: '',
  description:'',
}

export const TaxesFormdata ={
tax:'',
aliquot:'',
status:'',
company_id:'',
}



export const DocTypeFormdata ={
  chart_accounts:'',
  status:'',
  code:'',
  integration_pay:'',
  integration_receive:'',
  grupos:'',
  type_code:'',
  }

export const MeasurementFormdata ={
  description:'',
  status:'',
  company_id:'',
  }

export const BankFormdata ={
code:'',
name:'',
document:'',
company_id:'',
}

export const AgencyFormdata ={
  agency:'',
  type_agency:'',
  account:'',
  type_account:'',
  phone:'',
  manager:'',
  email:'',
  banck_id:'',
  company_id:'',
  }
  

export const PayCondFormdata ={
    code:'',
    description:'',
    conditions:'',
    status:'',
    user_id:'',
    company_id:''
}


export const ProductsFormdata ={
  company_id : '',
  additional_description: '',
  marca_id : '',
  modelo_id : '',
  modelo_ano_id : '',
  combustivel_id : '',
  tipo_veiculo : '', 
  numero_portas : '',
  ano_fabricacao : '',
  cor_externa : '',
  cor_interna : '',
  km : '',
  placa : '',
  uf_placa: '',
  renavam : '',
  blindado : '',
  cambio : '',
  garantia_fabrica : '',
  ipva_pago : '',
  adaptado_deficientes_fisicos : '',
  alienado : '',   
  licenciado : '',
  revisao_oficina_agendamento : '',
  observacao : '',
  preco_real : '',
  preco_revenda : '',
  preco_vendido: '',
  // data_inclusao : '20230120',
  // data_ultima_alteracao : '0230120',
  status : 1,
  aggregate_numbers : '',
  engine_numbers : '',
  chassi : '',
  glass_numbers : '',
  structure_assessment : '',
  user_inclusao_id : '',
  user_id:'',
  unit_measurements_id:1,
}


export const CourseFormdata ={
company_id : '',
title : '',
description : '',
status : '',
user_id:'',
// people_id:'',
summary_course:'',
arquivo:'',
trails_id:'',
}

export const TrailFormdata ={
  company_id : '',
  title : '',
  description : '',
  status : '',
  user_id:'',
  // people_id:'',
  // curse_id:'',
  summary_trails:'',
  }

  export const CategoryFormdata ={
    description : '',
    }

export const ModuleFormdata ={
company_id : '',
title : '',
description : '',
status : '',
user_id:'',
people_id:'',
courses_id:'',
summary_modules:'',
}

export const LesssonFormdata ={
company_id : '',
title : '',
description : '',
status : '',
user_id:'',
people_id:'',
module_id:'',
summary_lessons:'',
quiz:'',
}

export const AssesmentFormdata ={
company_id:'',
user_id:'',
people_id:'',
lesson_id:'',
question:'',
answer_one:'',
answer_two:'',
answer_true:'',
response_three:'',
status:'',

 }

export const PurchaseOrderFormdata ={
  company_id:'',
  user_id:'',
  supplier_id:'',
  payment_conditions_id:'',
  observation:'',
}


export const SalesOrderFormData ={
  company_id:'',
  user_id:'',
  seller_id:'',
  client_id:'',
  car_client_id:'',
  discount_value:'',
  total_product:'',
  total_taxes:'',
  status:'',
  payment_conditions_id:'',
  observation:'',
}



export const InOutFormdata ={
  cfop_id:'',
  user_id:'',
  comment:'',
  sayings:'',
  comes_with_body_nf:'',
  low_stock:'',
  processing:'',
  suframa:'',
  issue_duplicate:''
}


export const InOutTaxFormdata ={
  configuration_input_output_id:'',
  user_id:'',
  taxe_id:'',
  aliquot:'',
  reduction:'',
  exemption:'',
}

export const OrderFormData = {
  id: '',
  user_id: '',
  products_id: '',
  input_output_configuration_id: '',
  amount: '',
  unitary_value: '',
  tax_icms: '',
  tax_icms_st: '',
  tax_ipi: '',
  tax_cofins: '',
  tax_pis: '',
  tax_iss: '',
  shipping_value: '',
  discount_value: '',
  percentage_discount: '',
  validity: '',
  observation: '',
};


export const PeopleDocumentFormdata ={
  user_id:'',
  people_id:'',
  company_id:'',
  observation:'',
}

export const PersonDocumentFormdata ={
  user_id:'',
  company_id:'',
  observation:'',
  document_id:'',
  name_file:'',
  arquivo:'',
}

export const PhotoCoverFormdata ={
  user_id:'',
  company_id:'',
  observation:'',
  product_id:'',
  status:'',
  announcement_id:'',
}


export const PhotoItemFormdata ={
  user_id:'',
  company_id:'',
  observation:'',
  photo_id:'',
  name_file:'',
  arquivo:''
}

export const CarLinkFormdata ={

  observation:'',
}