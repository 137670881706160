import React from "react";
import { PEOPLE_API } from "../../../apiEndPoints";
import theme from "../../../theme";
import Lists from "../../../components/Lists";
import { PeopleColumns } from "../../../components/TableColumns";

const PeopleList = () => {
  const token = localStorage.getItem('token');
  return(
    <div>
      <Lists
      title={'Pessoas'}
      api={PEOPLE_API}
      buttonColor={theme.primaryColor}
      addURL={'/cadastro/personregister'}
      editURL={`/editpeople`}
      fields={PeopleColumns}
      token={token}
      applyCompanyIdFilter={true}
      />
    </div>
  )
}

export default PeopleList