import React from "react";
import { COMPANY_API } from "../../../apiEndPoints";
import theme from "../../../theme";
import Lists from "../../../components/Lists";
import { CompanyColumns } from "../../../components/TableColumns";

const CompanyList = () => {
  const token = localStorage.getItem('token');
  return(
    <div>
      <Lists
      title={'Empresas'}
      api={COMPANY_API}
      buttonColor={theme.primaryColor}
      addURL={'/cadastro/companyregister'}
      editURL={`/editcompany`}
      filterdisplay='none'
      token={token}
      fields={CompanyColumns}
      applyCompanyIdFilter={false}
      />
    </div>
  )
}

export default CompanyList