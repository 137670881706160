import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import theme from '../../../theme';
import axios from 'axios';
import { useParams, useNavigate} from 'react-router-dom';
import { CATEGORY_API, PHOTOITEM_API,  GETPHOTOITEMBYPHOTOID_API, TRAIL_API, GETPHOTOTRAIL_API, PUTTRAIL_API} from '../../../apiEndPoints';
import swal from 'sweetalert';
import { BackButton, SaveButton , TabButton} from '../../../components/Buttons';
import { TrailFormdata, } from '../../../components/Formdatas';
import { Container, FormContainer, Form, ResponsiveDiv, Label, Buttons, Container1 ,TabContainer } from '../../../components/FormContainers';
import { Title } from '../../../components/General';
import PhotoCoverEdit from '../../../components/PhotoCoverEdit';
import { CourseEditFields, CourseStatusOptions } from '../../../components/Fields/CourseFields';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Inputmodules } from '../../../components/InputModules';


const Input = styled.input`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

const Textarea = styled.textarea`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  resize: vertical;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

const EditTrail = ({ match }) => {
  const { id: IDGet } = useParams();
  const [formData, setFormData] = useState(TrailFormdata);
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Trilha');
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [file, setFile] = useState(null);

  const config = {
    readonly: false,
    height: 400,
  };
  const handleUpdate = (newContent) => {
    setContent(newContent);
    setFormData({ ...formData, summary_trails: newContent });
  };

  useEffect(() => {

    const fetchAnnounce = async () => {
      try {
        const response = await axios.get(`${TRAIL_API}/${IDGet}`,{
         ...formData,
    
          headers: {
            
            Authorization: `Bearer ${token}`,
           
          },

        }); 

        console.log(response.data)
      
        setFormData(response.data);
        setSelectedPeople(response.data.people_id)
        setSelectedStatus(response.data.status)
        setContent(response.data.summary_trails)
        setImagePreviewUrl(response.data.arquivo)
        setSelectedCategory(response.data.category_id)


        const byteCharacters = atob(response.data.arquivo.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });
        setFile(blob);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAnnounce();
  }, [IDGet, token]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyID');

  
    const updatedFormdata = new FormData();
    updatedFormdata.append('user_id', userId);
    updatedFormdata.append('company_id', companyId);
    updatedFormdata.append('status', selectedStatus);
    updatedFormdata.append('summary_trails', content);
    // updatedFormdata.append('category_id', selectedCategory);
    updatedFormdata.append('file_document', file);
    updatedFormdata.append('title', formData.title);
    updatedFormdata.append('description', formData.description);
    try {

      const response = await axios.post(`${PUTTRAIL_API}/${IDGet}`,
        updatedFormdata

        , {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },

        });
      console.log(response, 'response')

      if (response.status === 200) {
        swal({
          title: 'OK!',
          text: 'Trilha cadastrada com sucesso',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        handleGoBack()

      } else {
        swal({
          title: 'Ops!',
          text: 'Algo deu errado, tente denovo.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Error processing the form:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const reader = new FileReader();
      const file = files[0];
      
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
  
      reader.readAsDataURL(file);
  
      setFile(file);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`${CATEGORY_API}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        const options = response.data.map((category) => ({
          label: category.description,
          value: category.id
        }));
  
        setCategoryOptions(options);
      } catch (error) {
        console.error('Erro ao buscar opções de categorias:', error);
      }
    };
  
    fetchCategory();
  }, [token]);
  
  


  const handleGoBack = () => {
    window.history.back();
  };

  // useEffect(() => {
  //   const fetchPeople = async () => {
  //     try {
  //       const response = await axios.get(`${PEOPLE_API}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`
  //         }
  //       });

  //       const companyId = parseInt(localStorage.getItem('companyID'), 10);
  //       const filteredProducts = response.data.filter(
  //         (people) => people.company_id === companyId && people.type_people === 1
  //       );

  //       const options = filteredProducts.map((people) => ({
  //         label: people.name,
  //         value: people.id
  //       }));

  //       setPeopleOptions(options);
  //     } catch (error) {
  //       console.error('Erro ao buscar opções de produtos:', error);
  //     }
  //   };

  //   fetchPeople();
  // }, []);

  
  
    const campos = [
      // {
      //   name: 'category_id',
      //   type: 'select',
      //   placeholder: 'Categoria',
      //   width: 'calc(99% - 7.5px)',
      //   value: selectedCategory,
      //   onChange: (e) => {
      //     setSelectedCategory(e.target.value);
      // //   },
      //   options: categoryOptions,
      // },
        // {
        //     name: 'people_id',
        //     type: 'select',
        //     placeholder: 'Responsável',
        //     width: 'calc(50% - 7.5px)',
        //     value: selectedPeople,
        //     onChange: (e) => {
        //       setSelectedPeople(e.target.value);
        //     },
        //     options: peopleOptions,
        //   },
        ...CourseEditFields,
        {
          name: 'status',
          type: 'select',
          placeholder: 'Status',
          width: 'calc(10.5% - 7.5px)',
          value: selectedStatus,
          onChange: (e) => setSelectedStatus(e.target.value),
          options: CourseStatusOptions
        },
  ]

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  }

  return (
    <div>
    <FormContainer>
    <TabContainer>
        <TabButton
          active={activeTab === 'Trilha'}
          onClick={() => handleTabChange('Trilha')}
        >
          Trilha
        </TabButton>
     
      </TabContainer>
      <Container>
        <Title text={'Editar Trilha'} backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'}/>
        <Container1>
            <Form onSubmit={handleSubmit}>
            {activeTab === 'Trilha' && (
              <>
              {campos.map((campo) => (
                <ResponsiveDiv key={campo.name} width={campo.width}>
                  {campo.type === 'select' ? (
                        <Select
                        name={campo.name}
                        value={campo.value}
                        onChange={campo.onChange}
                        style={{ width: '100%' }}
                        disabled={campo.disabled }
                      >
                        <option value="" style={{ color: '#999' }} disabled>
                          {campo.placeholder}
                        </option>
                        {campo.options.map((option, index) => (
                          <option key={index} value={option.value}>
                               {option.label}
                              </option>
                            ))}
                          </Select>
                        ) : campo.type === 'file' ? (
                          <div style={{ display: 'flex', alignItems: 'end' }}>
                            <div>
                              {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />}
                            </div>
                            <div>
                              <Input
                                id={campo.name}
                                type="file"
                                name={campo.name}
                                onChange={handleChange}
                                required
                                accept={campo.accept}
                                disabled={campo.disabled}
                                fullWidth={campo.fullWidth}
                                style={{ width: '100%' }}
                              />
                            </div>
                          </div>
                        ) : campo.type === 'textarea' ? (
                          <Textarea
                          id={campo.name}
                          name={campo.name}
                          value={formData[campo.name]}
                          onChange={campo.onChange || handleChange}
                          required={campo.required}
                          disabled={campo.disabled }
                          fullWidth={campo.fullWidth}
                          style={{ width: '100%' }}
                        />
                      ) : (
                    <Input
                      id={campo.name}
                      type={campo.type}
                      name={campo.name}
                      value={formData[campo.name]}
                      onChange={campo.onChange || handleChange}
                      mask={campo.mask}
                      maskChar={null}
                      required
                      disabled={campo.disabled }
                      fullWidth={campo.fullWidth}
                      style={{ width: '100%' }}
                    />
                  )}
                  <Label htmlFor={campo.name}>{campo.placeholder}</Label>
                </ResponsiveDiv>
              ))}
                      <ResponsiveDiv width={'99%'}>
                      Resumo da trilha
                      <ReactQuill
                      ref={editor}
                      value={content}
                      onChange={handleUpdate}
                      style={{ height: '100px', marginBottom:'2em', resize: 'vertical', overflow: 'auto' }}
                      modules={Inputmodules}
                    />
                  </ResponsiveDiv>

                </>
                 
            )} 
           
              <Buttons>
                <BackButton onClick={handleGoBack} />
                <SaveButton onClick={handleSubmit} disabled={loading} />
              </Buttons>
            
          </Form>
          {activeTab === 'Fotos' && (
              <PhotoCoverEdit GetPhotosCoverById={GETPHOTOTRAIL_API} GetPhotosItemsByID={GETPHOTOITEMBYPHOTOID_API} PostApi={PHOTOITEM_API}/>
            )}
          </Container1>
        </Container>
      </FormContainer>
    </div>
  );
};

export default EditTrail;