import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import { USER_API, GROUP_API, COMPANY_API, COMPANYUSER_API, IBGECITIESSTATES_API } from '../../../apiEndPoints';
import axios from 'axios';
import InputMask from 'react-input-mask';
import swal from 'sweetalert';
import { useNavigate, Link  } from 'react-router-dom';
import { BackButton, SaveButton, TabButton } from '../../../components/Buttons';
import { UserRegisterFormData } from '../../../components/Formdatas';
import { Userfields, adminOptions, sexOptions } from '../../../components/Fields/UserFields';
import { Title } from '../../../components/General';
import Checkbox from '../../../components/Checkboxes';
import { Container, Container1, FormContainer, Form, ResponsiveDiv, Label, Buttons,
  Loading, TabContainer, List, ListItem } from '../../../components/FormContainers';


const Input = styled(InputMask)`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

   @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const UserRegister = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState(UserRegisterFormData);
  const [loading] = useState(false);
  const [activeTab, setActiveTab] = useState('user');
  const [companies, setCompanies] = useState([]);
  // const [matrices, setMatrices] = useState([]);
  // const [subsidiaries, setSubsidiaries] = useState([]);
  const [token, setToken] = useState('');
  const [gruposDeAcesso, setGruposDeAcesso] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedSex, setSelectedSex] = useState('');
  const [selectedAdmin, setSelectedAdmin] = useState('');
  const [selectedCompanies, setSelectedCompanies] = useState({});


  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
    const fetchGroups = async () => {
      try {
        const response = await axios.get(GROUP_API, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setGruposDeAcesso(response.data);
      } catch (error) {
        console.error('Erro ao buscar grupos de acesso:', error);
      }
    };

    fetchGroups();
  }, []);

  // useEffect(() => {
  //     const storedToken = localStorage.getItem('token');
  //     if (storedToken) {
  //       setToken(storedToken);
  //     }
  //   const fetchCompanies = async () => {
  //     try {
  //       const response = await axios.get(COMPANY_API,{
  //         headers: {
  //           Authorization: `Bearer ${storedToken}`,
  //         },
  //       });
        
  //       setCompanies(response.data);
  //     } catch (error) {
  //       console.error('Erro ao buscar empresas:', error);
  //     }
  //   };

  //   fetchCompanies();
  // }, []);

  // useEffect(() => {
  //   if (companies.length > 0) {
  //     const matrices = companies.filter(company => company.matrix === 1);
  //     const subsidiaries = companies.filter(company => company.matrix === 0);
  //     setMatrices(matrices);
  //     setSubsidiaries(subsidiaries);
  //   }
  // }, [companies]);

  // const handleCheckboxChange = (companyId) => {
  //   setSelectedCompanies({
  //     ...selectedCompanies,
  //     [companyId]: !selectedCompanies[companyId]
  //   });
  // };

  const handleCepBlur = async (value) => {
    if (value.length === 9) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
  
        if (response.data.error) {
          swal({
            title: 'Ops!',
            text: 'CEP inválido. Verifique o CEP informado.',
            icon: 'error',
            timer: 2000,
            buttons: false,
          });
          setFormData(prevData => ({
            ...prevData,
            cep: '',
            address: '',
            district: '',
            city: '',
            state: '',
          }));
        } else {
          const { logradouro, bairro, cep, localidade, uf } = response.data;
          const ibgeToken = localStorage.getItem('IBGEtoken');
          const ibgeResponse = await axios.get(`${IBGECITIESSTATES_API}`, {
            headers: {
              Authorization: `Bearer ${ibgeToken}`,
            },
          });
  
          const cities = ibgeResponse.data;
          const cityExists = cities.some((city) => city.name.toLowerCase() === localidade.toLowerCase());
  
          if (cityExists) {
            setFormData(prevData => ({
              ...prevData,
              cep: cep,
              address: logradouro,
              district: bairro,
              city: localidade,
              state: uf,
            }));
          } else {
            setFormData(prevData => ({
              ...prevData,
              cep: cep,
              address: logradouro,
              district: bairro,
              city: '',
              state: uf,
            }));
          }
        }
      } catch (error) {
        console.error('Erro ao buscar CEP:', error);
      }
    } else {
      swal({
        title: 'Ops!',
        text: 'CEP inválido. Verifique o CEP informado.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      setFormData(prevData => ({
        ...prevData,
        cep: '',
        address: '',
        district: '',
        city: '',
        state: '',
      }));
    }
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'cep') {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  
  useEffect(() => {
    const cepInput = document.querySelector('input[name="cep"]');
    const handleBlur = () => handleCepBlur(cepInput.value);
    cepInput.addEventListener('blur', handleBlur);
    return () => {
      cepInput.removeEventListener('blur', handleBlur);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const cleanedFormData = {
      ...formData,
      cep: formData.cep.replace(/\D/g, ''),
      cpf: formData.cpf.replace(/\D/g, ''),
      sex: selectedSex,
      group_id: 1, 
      // selectedGroup,
      user_master: selectedAdmin
    };
  
    try {
      const response = await axios.post(USER_API, cleanedFormData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        swal({
          title: 'OK!',
          text: 'Usuário cadastrado com sucesso!',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        navigate('/cadastro/pessoa');
      } else {
        swal({
          title: 'Ops!',
          text: 'Por favor preencha os campos obrigatórios.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Erro ao processar o formulário:', error);
  
      if (error.response && error.response.status === 409) {
        swal({
          title: 'Ops!',
          text: 'Usuário já cadastrado no sistema',
          icon: 'warning',
          timer: 2000,
          buttons: false,
        });
      }
    }
  };


  const campos = [
    ...Userfields,
    {

      name: 'sex',
      type: 'select',
      placeholder: 'Sexo',
      fullWidth: true,
      width: 'calc(20% - 7.5px)',
      value: selectedSex,
      onChange: (e) => setSelectedSex(parseInt(e.target.value, 10)),
      options: sexOptions
    },
    // {
    //   name: 'group_id',
    //   type: 'select',
    //   placeholder: 'Grupo de Acesso',
    //   fullWidth: true,
    //   width: 'calc(25% - 7.5px)',
    //   value: selectedGroup,
    //   onChange: (e) => setSelectedGroup(e.target.value),
    //   options: gruposDeAcesso
    //   .filter((grupo) => grupo.status === 1 && grupo.company_id === parseInt(localStorage.getItem('companyID')))
    //   .map((grupo) => ({ label: grupo.name, value: grupo.id })),
    // },
    {
      name: 'user_master',
      type: 'select',
      placeholder: 'Nivel de Usuário',
      fullWidth: true,
      width: 'calc(20% - 7.5px)',
      value: selectedAdmin,
      onChange: (e) => setSelectedAdmin(parseInt(e.target.value, 10)),
      options: adminOptions
    },
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div>
      <FormContainer>
      <TabContainer>
            <TabButton
              active={activeTab === 'user'}
              onClick={() => handleTabChange('user')}
            >
              Dados do Usuário
            </TabButton>
            {/* <TabButton
              active={activeTab === 'company'}
              onClick={() => handleTabChange('company')}
            >
              Empresas
            </TabButton> */}
          </TabContainer>
        <Container>
          <Title text={'Cadastrar Usuário'} backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'}/>
          
          <Container1>
            <Form onSubmit={handleSubmit}>
            {activeTab === 'user' &&
              campos.map((campo) => (
                <ResponsiveDiv key={campo.name} width={campo.width}>
                  {campo.type === 'select' ? (
                    <Select
                      name={campo.name}
                      value={campo.value}
                      onChange={campo.onChange}
                      style={{ width: '100%' }}
                    >
                      <option value="" style={{ color: '#999' }} disabled>
                        {campo.placeholder}
                      </option>
                      {campo.options.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      id={campo.name}
                      type={campo.type}
                      name={campo.name}
                      value={formData[campo.name]}
                      onChange={campo.onChange || handleChange}
                      mask={campo.mask}
                      maxLength={80}
                      maskChar={null}
                      required={campo.required}
                      disabled={campo.disabled}
                      fullWidth={campo.fullWidth}
                      style={{ width: '100%' }}
                    />
                  )}
                  <Label htmlFor={campo.name} >
                    {campo.placeholder}
                  </Label>
                </ResponsiveDiv>
              ))}

              {/* {activeTab === 'company' && (
                <div style={{ width: '100%', display:'flex', flexDirection:'column'}}>
                   <Title text={'Matrizes'} fontSize={'28px'}/>
                   <List>
                     {matrices.map((company) => (
                  <ListItem key={company.id}>
                  <Checkbox
                    id={`company-${company.id}`}
                    checked={selectedCompanies[company.id]}
                    onChange={() => handleCheckboxChange(company.id)}
                    label={company.fantasy_name}
                     info={ ` || ${company.district}, ${company.city}, ${company.state}, (${company.dd_cell_phone})${company.cell_phone}, ${company.email}`
                    }
                  />
                </ListItem>
              ))}
            </List>
            <Title text={'Filiais'} fontSize={'28px'}/>
            <List>
              {subsidiaries.map((company) => (
              <ListItem key={company.id}>
              <Checkbox
                id={`company-${company.id}`}
                checked={selectedCompanies[company.id]}
                onChange={() => handleCheckboxChange(company.id)}
                label={company.fantasy_name}
                 info={ `|| ${company.district}, ${company.city}, ${company.state}, (${company.dd_cell_phone})${company.cell_phone}, ${company.email}`
                }
              />
            </ListItem>
                     ))}
                   </List>
               </div>
               )} */}
              <Buttons>
                <BackButton as={Link} to="/cadastro/pessoa" />
                <SaveButton onClick={handleSubmit} disabled={loading} />
              </Buttons>
            </Form>
          </Container1>
        </Container>
      </FormContainer>
    </div>
  );
};

export default UserRegister;
