import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const StartPage = () => {
  return (
    <div>
      {/* Spinner Start */}
      {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div> */}
      {/* Spinner End */}

      {/* Navbar Start */}
      <nav className="navbar navbar-expand-lg bg-dark navbar-light shadow sticky-top p-0">
        <Link to="/index1.html" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
          <h2 className="m-0 text-primary text-white">
            <i className="fa fa-book me-3"></i>Concentra EAD
          </h2>
        </Link>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            {/* <Link to="#" className="nav-item nav-link active"></Link>
            <Link to="#" className="nav-item nav-link"></Link>
            <Link to="#" className="nav-item nav-link"></Link>
            <Link to="#" className="nav-item nav-link"></Link> */}
            <Link to="/login" className="btn btn-primary py-2 px-4 mt-3 d-lg-none text-black">
              <b>Login</b>
              <i className="fa fa-arrow-right ms-3"></i>
            </Link>
          </div>
          <Link to="/login" className="btn btn-primary py-4 px-lg-5 d-none d-lg-block">
            Login<i className="fa fa-arrow-right ms-3"></i>
          </Link>
        </div>
      </nav>
      {/* Navbar End */}

      {/* Carousel Start */}
      <div className="position-relative">
        <img
          className="img-fluid"
          src="img/sssa.jpg"
          alt=""
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        <div
          className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-end"
          style={{ background: 'rgba(24, 29, 56, .7)', paddingBottom: '15em' }}
        >
          <div className="container">
            <div className="row justify-content-end">
              <div
                className="text-end"
                style={{
                  background: 'rgba(255, 255, 255, 0)', 
                  borderTop: '4px solid white',
                  borderRight: '4px solid white',
                  borderRadius: '10px',
                  maxWidth: '500px',
                  textAlign: 'center',
                }}
              >
                <p
                  className="fs-4 text-white mb-4 pb-2"
                  style={{
                    padding: '10px',
                  }}
                >
                  Transforme o aprendizado em experiências digitais inovadoras com nossa plataforma EAD,
                  projetada para facilitar a educação e maximizar o potencial de seus alunos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Carousel End */}

      {/* About Start */}
      {/* <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: '400px' }}>
              <div className="position-relative h-100">
                <img className="img-fluid position-absolute w-100 h-100" src="img/about.jpg" alt="" style={{ objectFit: 'cover' }} />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">About Us</h6>
              <h1 className="mb-4">Plataforma de ensino Concentra EAD</h1>
              <p className="mb-4">
                Use os caminhos de aprendizagem para oferecer jornadas personalizadas que sejam exclusivas para cada aluno.
                Concentra Autos EAD tem uma variedade de ferramentas - programas, competências, cursos e páginas personalizadas -
                que dão aos alunos a liberdade e as ferramentas para decidir como alcançar seus objetivos.
              </p>
              <p className="mb-4">Crie experiências de aprendizado híbrido que combinem o melhor do ensino presencial e on-line para estimular o envolvimento dos alunos.</p>
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>Instrutores Qualificados
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>Classes Online
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>Certificado Internacional
                  </p>
                </div>
              </div>
              <Link className="btn btn-primary py-3 px-5 mt-2" to="https://concentraautos.com/ead.html" target="_blank">Leia +</Link>
            </div>
          </div>
        </div>
      </div> */}
      {/* About End */}

      {/* Back to Top */}
      {/* <Link to="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="bi bi-arrow-up"></i>
      </Link> */}

      {/* JavaScript Libraries */}
      <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js"></script>

      {/* Template Javascript */}
      <script src="js/main.js"></script>
    </div>
  );
};

export default StartPage;
