// src/pages/CoursesList.js

import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { COURSE_API, INSCRIPTIONCOURSE_API, LIKES_API } from '../../apiEndPoints';
import { Spinner } from 'react-bootstrap';
import swal from 'sweetalert';
import CourseCard from '../../components/CourseCard';
import SearchInput from '../../components/SearchInput';
import theme from '../../theme';



function CoursesList() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [enrollments, setEnrollments] = useState({});
  const [likes, setLikes] = useState({});
  const [likeCounts, setLikeCounts] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchCoursesAndData = async () => {
      const storedToken = localStorage.getItem('token');
      const companyID = parseInt(localStorage.getItem('companyID'));
      const userId = parseInt(localStorage.getItem('userId'));

      try {
        const coursesResponse = await axios.get(COURSE_API, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const filteredCourses = coursesResponse.data.filter(course => course.status == 1);
        setCourses(filteredCourses);

        const enrollmentsResponse = await axios.get(INSCRIPTIONCOURSE_API, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const likesResponse = await axios.get(LIKES_API, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const userEnrollments = enrollmentsResponse.data.filter(enrollment => enrollment.user_id === userId);
        const userLikes = likesResponse.data.filter(like => like.user_id === userId);

        const enrollmentsData = {};
        userEnrollments.forEach((enrollment) => {
          enrollmentsData[enrollment.course_id] = enrollment;
        });

        const likesData = {};
        const likeCountsData = {};
        likesResponse.data.forEach((like) => {
          if (like.like) {
            likeCountsData[like.course_id] = (likeCountsData[like.course_id] || 0) + 1;
          }
          if (like.user_id === userId) {
            likesData[like.course_id] = like;
          }
        });

        setEnrollments(enrollmentsData);
        setLikes(likesData);
        setLikeCounts(likeCountsData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchCoursesAndData();
  }, []);

  const handleEnroll = async (courseId, event) => {
    event.preventDefault();
    event.stopPropagation();

    const enrollment = enrollments[courseId];
    const storedToken = localStorage.getItem('token');
    const userId = parseInt(localStorage.getItem('userId'));
    const companyID = parseInt(localStorage.getItem('companyID'));

    if (enrollment && enrollment.id) {
      if (enrollment.status === 1) {
        const confirmCancel = await swal({
          title: "Você tem certeza?",
          text: "Você deseja cancelar a inscrição?",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true,
        });
  
        if (!confirmCancel) {
          return;
        }
      }

      try {
        await axios.put(
          `${INSCRIPTIONCOURSE_API}/${enrollment.id}`,
          {
            status: enrollment.status === 1 ? 0 : 1,
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        setEnrollments((prevEnrollments) => ({
          ...prevEnrollments,
          [courseId]: { ...enrollment, status: enrollment.status === 1 ? 0 : 1 },
        }));
      } catch (error) {
        console.error('Error updating enrollment:', error);
      }
    } else {
      try {
        const response = await axios.post(
          INSCRIPTIONCOURSE_API,
          {
            user_id: userId,
            course_id: courseId,
            company_id: companyID,
            trails_id: 1, //MUDAR NO FUTURO
            status: '1',
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        setEnrollments((prevEnrollments) => ({
          ...prevEnrollments,
          [courseId]: { id: response.data.id, status: 1 },
        }));
      } catch (error) {
        console.error('Error enrolling in the course:', error);
      }
    }
  };

  const handleLike = async (courseId, event) => {
    event.preventDefault();
    event.stopPropagation();

    const like = likes[courseId];
    const storedToken = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const companyID = localStorage.getItem('companyID');

    if (like && like.id) {
      try {
        const response = await axios.put(
          `${LIKES_API}/${like.id}`,
          {
            like: !like.like,
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        setLikes((prevLikes) => ({
          ...prevLikes,
          [courseId]: { ...like, like: !like.like },
        }));
        setLikeCounts((prevCounts) => ({
          ...prevCounts,
          [courseId]: like.like ? prevCounts[courseId] - 1 : prevCounts[courseId] + 1,
        }));
      } catch (error) {
        console.error('Error updating like:', error);
      }
    } else {
      try {
        const response = await axios.post(
          LIKES_API,
          {
            user_id: userId,
            course_id: courseId,
            like: true,
            company_id: companyID,
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        setLikes((prevLikes) => ({
          ...prevLikes,
          [courseId]: { id: response.data.id, like: true },
        }));
        setLikeCounts((prevCounts) => ({
          ...prevCounts,
          [courseId]: (prevCounts[courseId] || 0) + 1,
        }));
      } catch (error) {
        console.error('Error liking the course:', error);
      }
    }
  };

  const filteredCourses = courses.filter(course =>
    course.title.toLowerCase().includes(searchTerm.toLowerCase()) 
  );

  if (loading) {
    return (
      <div className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" variant="primary" style={{ width: '3rem', height: '3rem' }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
         {/* <h6 className="section-title bg-white text-center text-primary px-3" style={{ margin: '30px' }}>Cursos</h6> */}
        <p> </p>
        <h1 className="mb-5">Todos os Cursos</h1> 
      </div>

      <div className="container">
        <SearchInput
          type="text"
          placeholder="O que você quer aprender?"
          className="form-control mb-4"
          searchTerm={searchTerm} 
          setSearchTerm={setSearchTerm}
          backgroundColor={theme.portalColor}
          inputWidth={'100%'}
        />
      </div>

      <div className="py-5">
        <div className="container">
        <div className="row g-4">
            {filteredCourses.map((course) => (
              <div className="col-lg-2 col-md-4" key={course.id}>
                <CourseCard
                  course={course}
                  enrolled={enrollments[course.id] && enrollments[course.id].status == 1}
                  like={likes[course.id] && likes[course.id].like}
                  likeCount={likeCounts[course.id] || 0}
                  onEnroll={(event) => handleEnroll(course.id, event)}
                  onLike={(event) => handleLike(course.id, event)}
                />
              </div>
            ))}
          </div>
        </div>

        <a href="#" className="btn btn-primary">
          <i className="fas fa-caret-up"></i>
        </a>
      </div>
    </div>
  );
}

export default CoursesList;