import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import theme from '../../../theme';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { PHOTOITEM_API, DELETEPHOTOITEM_API, MODULE_API, LESSON_API } from '../../../apiEndPoints';
import swal from 'sweetalert';
import { BackButton, SaveButton, TabButton, AddNewButton } from '../../../components/Buttons';
import { LesssonFormdata } from '../../../components/Formdatas';
import { Container, FormContainer, Form, ResponsiveDiv, Label, Buttons, Container1, TabContainer } from '../../../components/FormContainers';
import { Title } from '../../../components/General';
import PhotoItem from '../../../components/PhotoCover/PhotoItem';
import { CourseFields, LessonFields } from '../../../components/Fields/CourseFields';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const Input = styled.input`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

const Textarea = styled.textarea`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  resize: vertical;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

const Margin = styled.div`
  margin-top: 1rem;
`;


const ProgressBar = styled.div`
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-top: 10px;
`;

const Progress = styled.div`
  width: ${(props) => props.progress}%;
  height: 10px;
  background-color: ${theme.primaryColor};
  border-radius: 5px;
`;

function LessonRegister() {
  const [formData, setFormData] = useState(LesssonFormdata);
  const token = localStorage.getItem('token');
  const [selectedModule, setSelectedModule] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [phID,] = useState('');
  const [numPhotosRegister, setPhotosRegister] = useState(1);
  const [activeTab, setActiveTab] = useState('Aula');
  const [videoFile, setVideoFile] = useState(null)
  const [showDocumentsTab, ] = useState(false);
  const [fieldsDisabled,] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const [content, setContent] = useState("Digite o Resumo da Aula");
  const [quiz, setQuiz] = useState([{ question: "", answers: ["", "", "", ""], correctAnswer: 0 }]);
  const [showQuizFields, setShowQuizFields] = useState(false);
  const navigate = useNavigate();
  const editor = useRef(null);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const config = {
    readonly: false,
    height: 400,
  };

  const handleUpdate = (newContent) => {
    setContent(newContent);
    setFormData({ ...formData, summary_lessons: newContent });
  };
  
  const handleQuizChange = (index, field, value) => {
    const newQuiz = [...quiz];
    newQuiz[index][field] = value;
    setQuiz(newQuiz);
  };
  
  const handleQuizAnswerChange = (questionIndex, answerIndex, value) => {
    const newQuiz = [...quiz];
    newQuiz[questionIndex].answers[answerIndex] = value;
    setQuiz(newQuiz);
  };

  const addQuizQuestion = () => {
    setQuiz([...quiz, { question: "", answers: ["", "", "", ""], correctAnswer: 0 }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.file_document || !videoFile) {
      swal({
        title: 'Ops!',
        text: 'Por favor, carregue uma imagem e um vídeo antes de salvar.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      return;
    }

    const missingFields = campos.filter(campo => campo.required && !formData[campo.name]);

    if (missingFields.length > 0) {
      swal({
        title: 'Ops!',
        text: 'Por favor, preencha todos os campos obrigatórios.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      return;
    }

    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyID');

    const updatedFormdata = new FormData();
    updatedFormdata.append('user_id', userId);
    updatedFormdata.append('company_id', companyId);
    updatedFormdata.append('status', 1);
    updatedFormdata.append('summary_lessons', content);
    updatedFormdata.append('module_id', selectedModule);
    updatedFormdata.append('file_document', formData.file_document);
    updatedFormdata.append('file_video', videoFile); 
    updatedFormdata.append('title', formData.title);
    updatedFormdata.append('description', formData.description);
    updatedFormdata.append('quiz', JSON.stringify(quiz));

    try {
      setLoading(true);
      const response = await axios.post(`${LESSON_API}`,
        updatedFormdata,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total;
            const current = progressEvent.loaded;
            setUploadProgress(Math.round((current / total) * 100));
          }
        });

      if (response.status === 200 || response.status === 201) {
        swal({
          title: 'OK!',
          text: 'Aula cadastrada com sucesso',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        handleGoBack();
      } else {
        swal({
          title: 'Ops!',
          text: 'Algo deu errado, tente de novo.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Error processing the form:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    
    if (files) {
      const file = files[0];
      
      if (name === 'file_document') {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
        
        setFormData({ ...formData, [name]: file });
      }
      
      if (name === 'video') {
        const videoReader = new FileReader();
        videoReader.onloadend = () => {
          setVideoPreviewUrl(videoReader.result);
        };
        videoReader.readAsDataURL(file);
        
        setVideoFile(file);
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  
  
  useEffect(() => {
    const fetchModule = async () => {
      try {
        const response = await axios.get(`${MODULE_API}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const companyId = parseInt(localStorage.getItem('companyID'), 10);
        const filteredModules = response.data.filter(
          (module) => module.company_id === companyId
        );

        const options = filteredModules.map((module) => ({
          label: module.title,
          value: module.id
        }));

        setModuleOptions(options);
      } catch (error) {
        console.error('Erro ao buscar opções de módulos:', error);
      }
    };

    fetchModule();
  }, []);

 
  const handleGoBack = () => {
    setFormData(LesssonFormdata); 
    setLoading(false); 
    window.history.back(); 
  };
  

  const campos = [
    {
      name: 'module_id',
      type: 'select',
      placeholder: 'Módulo',
      width: 'calc(99% - 7.5px)',
      value: selectedModule,
      onChange: (e) => {
        setSelectedModule(e.target.value);
      },
      options: moduleOptions,
    },
    ...LessonFields,
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  }

  const toggleQuizFields = () => {
    setShowQuizFields(!showQuizFields);
  }

  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      ['link', 'image'],
      [{ 'align': [] }, { 'color': [] }, { 'background': [] }],
      ['clean']
    ]
  };

  return (
    <div>
      <FormContainer>
        <TabContainer>
          <TabButton
            active={activeTab === 'Aula'}
            onClick={() => handleTabChange('Aula')}
          >
            Aula
          </TabButton>
          {showDocumentsTab && (
            <TabButton
              active={activeTab === 'Fotos'}
              onClick={() => handleTabChange('Fotos')}
            >
              Fotos
            </TabButton>
          )}
        </TabContainer>
        <Container>
          <Title
            text="Registrar Aula"
            backgroundColor={theme.backgroundColor2}
            padding="5px 0 5px 20px"
          />
          <Container1>
            <Form onSubmit={handleSubmit}>
              {activeTab === 'Aula' && (
                <>
                  {campos.map((campo) => (
                      <ResponsiveDiv key={campo.name} width={campo.width}>
                      {campo.type === 'select' ? (
                        <Select
                          name={campo.name}
                          value={campo.value}
                          onChange={campo.onChange}
                          style={{ width: '100%' }}
                          disabled={campo.disabled}
                        >
                          <option value="" style={{ color: '#999' }} disabled>
                            {campo.placeholder}
                          </option>
                          {campo.options.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Select>
                          ) : campo.type === 'file' && campo.name === 'file_document' ? (
                            <div style={{ display: 'flex', alignItems: 'end' }}>
                              <div>
                                {imagePreviewUrl && (
                                  <img
                                    src={imagePreviewUrl}
                                    alt="Preview da Imagem"
                                    style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }}
                                  />
                                )}
                              </div>
                              <div>
                                <Input
                                  id={campo.name}
                                  type="file"
                                  name={campo.name}
                                  onChange={handleChange}
                                  required
                                  accept={campo.accept}
                                  disabled={campo.disabled}
                                  fullWidth={campo.fullWidth}
                                  style={{ width: '100%' }}
                                />
                              </div>
                            </div>
                          ) : campo.type === 'file' && campo.name === 'video' ? (
                            <div style={{ display: 'flex', alignItems: 'end' }}>
                              <div>
                                {videoPreviewUrl && (
                                  <video
                                    src={videoPreviewUrl}
                                    controls
                                    style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }}
                                  />
                                )}
                              </div>
                              <div>
                                <Input
                                  id={campo.name}
                                  type="file"
                                  name={campo.name}
                                  onChange={handleChange}
                                  required
                                  accept={campo.accept}
                                  disabled={campo.disabled}
                                  fullWidth={campo.fullWidth}
                                  style={{ width: '100%' }}
                                />
                              </div>
                            </div>
                          
                      ) : campo.type === 'textarea' ? (
                        <Textarea
                          id={campo.name}
                          name={campo.name}
                          value={formData[campo.name]}
                          onChange={campo.onChange || handleChange}
                          required={campo.required}
                          disabled={campo.disabled}
                          fullWidth={campo.fullWidth}
                          style={{ width: '100%' }}
                        />
                      ) : (
                        <Input
                          id={campo.name}
                          type={campo.type}
                          name={campo.name}
                          value={formData[campo.name]}
                          onChange={campo.onChange || handleChange}
                          mask={campo.mask}
                          maskChar={null}
                          required
                          disabled={campo.disabled}
                          fullWidth={campo.fullWidth}
                          style={{ width: '100%' }}
                        />
                      )}
                      <Label htmlFor={campo.name}>{campo.placeholder}</Label>
                    </ResponsiveDiv>
                  ))}
                  <ResponsiveDiv width="99%">
                    Conteúdo da Aula
                    <ReactQuill
                      ref={editor}
                      value={content}
                      onChange={handleUpdate}
                      style={{ height: '100px', marginBottom: '2em', resize: 'vertical', overflow: 'auto' }}
                    />
                  </ResponsiveDiv>
                </>
              )}
  
              <Title text="Adicione uma Questão (Opcional)" padding="20px 0 5px 20px" />
              <AddNewButton type="button" onClick={toggleQuizFields}>
                {showQuizFields ? 'Ocultar Questões' : 'Adicionar Questões'}
              </AddNewButton>
              {showQuizFields &&
                quiz.map((question, questionIndex) => (
                  <div key={questionIndex} style={{ width: '100%', marginBottom: '20px' }}>
                    <ResponsiveDiv width="99%">
                      Pergunta
                      <ReactQuill
                        value={question.question}
                        onChange={(value) => handleQuizChange(questionIndex, 'question', value)}
                        modules={modules}
                        style={{ marginBottom: '2em', resize: 'vertical', overflow: 'auto' }}
                      />
                    </ResponsiveDiv>
  
                    {question.answers.map((answer, answerIndex) => (
                      <ResponsiveDiv key={answerIndex} width="99%">
                        <Margin>
                          {`Resposta ${answerIndex + 1}`}
                          <ReactQuill
                            value={answer}
                            onChange={(value) => handleQuizAnswerChange(questionIndex, answerIndex, value)}
                            style={{ marginBottom: '2em', resize: 'vertical', overflow: 'auto' }}
                          />
                        </Margin>
                      </ResponsiveDiv>
                    ))}
  
                    <ResponsiveDiv width="99%">
                      <Label htmlFor={`correctAnswer-${questionIndex}`} style={{ display: 'block', marginBottom: '5px' }}>
                        Resposta Correta:
                      </Label>
                      <Select
                        id={`correctAnswer-${questionIndex}`}
                        value={question.correctAnswer}
                        onChange={(e) => handleQuizChange(questionIndex, 'correctAnswer', parseInt(e.target.value))}
                        style={{ width: '100%' }}
                      >
                        {question.answers.map((_, answerIndex) => (
                          <option key={answerIndex} value={answerIndex}>
                            {`Resposta ${answerIndex + 1}`}
                          </option>
                        ))}
                      </Select>
                    </ResponsiveDiv>
                  </div>
                ))}
  
              {uploadProgress > 0 && (
                <ProgressBar>
                  <Progress progress={uploadProgress} />
                </ProgressBar>
              )}
  
              {activeTab !== 'Fotos' && (
                <Buttons>
                  <BackButton onClick={handleGoBack} />
                  <SaveButton type="submit" onClick={handleSubmit} disabled={loading || uploadProgress > 0} />
                </Buttons>
              )}
            </Form>
  
            {showDocumentsTab && activeTab === 'Fotos' && (
              <>
                <Title text="Fotos" />
                {Array.from({ length: numPhotosRegister }).map((_, index) => (
                  <PhotoItem key={index} apiItem={PHOTOITEM_API} deleteApi={DELETEPHOTOITEM_API} phId={phID} />
                ))}
                <FormContainer paddingWrap="0px">
                  <AddNewButton onClick={() => setPhotosRegister(numPhotosRegister + 1)} />
                </FormContainer>
              </>
            )}
          </Container1>
        </Container>
      </FormContainer>
    </div>
  );
  
};

export default LessonRegister;
