export const Links = [
    // {
    //   name: 'Empresas',
    //   id: '1',
    //   path: '/cadastro/empresa',
    //   color: 'primary',
    //   sub:''
    // },
    {
      name: 'Usuários',
      id: '2',
      path: '/cadastro/pessoa',
      color: 'success',
      sub:''
    },
    // {
    //   name: 'Grupos de Usuários',
    //   id: '3',
    //   path: '/cadastro/usuario',
    //   color: 'info',
    //   sub:''
    // },
   
    // {
    //   name: 'Definições de Acesso',
    //   id: '4',
    //   path: '/cadastro/acesso',
    //   color: 'warning',
    //   sub:''
    // },
  ];