export const CourseFields = [
    {
      name: 'title',
      type: 'text',
      placeholder: 'Título',
      width: 'calc(99% - 7.5px)',
    },
    {
      name: 'description',
      type: 'textarea', 
      placeholder: 'Descrição',
      width: 'calc(99% - 7.5px)',
    },
    {
      name: 'file_document',
      type: 'file', 
      placeholder: 'Capa',
      accept: "image/*",
      width: 'calc(99% - 7.5px)',
    },
  ]
  
  export const LessonFields = [
    {
      name: 'title',
      type: 'text',
      placeholder: 'Título',
      width: 'calc(99% - 7.5px)',
    },
    {
      name: 'description',
      type: 'textarea', 
      placeholder: 'Descrição',
      width: 'calc(99% - 7.5px)',
    },
    {
      name: 'video',
      type: 'file', 
      placeholder: 'Vídeo da Aula',
      accept: "video/*",
      width: 'calc(49% - 7.5px)',
    },
    {
      name: 'file_document',
      type: 'file', 
      accept: "image/*",
      placeholder: 'Capa',
      width: 'calc(49% - 7.5px)',
    },
  ]

  export const CourseModulesFields = [
    {
      name: 'title',
      type: 'text',
      placeholder: 'Título',
      width: 'calc(99% - 7.5px)',
    },
    {
      name: 'file_document',
      type: 'file', 
      accept: "image/*",
      placeholder: 'Capa',
      width: 'calc(99% - 7.5px)',
    },
  ]

  export const TrailsFields = [
    {
      name: 'title',
      type: 'text',
      placeholder: 'Título',
      width: 'calc(99% - 7.5px)',
    },
    {
      name: 'description',
      type: 'textarea', 
      placeholder: 'Descrição',
      width: 'calc(99% - 7.5px)',
    },
    {
      name: 'file_document',
      type: 'file', 
      placeholder: 'Capa',
      width: 'calc(99% - 7.5px)',
    },
  ]

  export const CategoryFields = [
    {
      name: 'description',
      type: 'text', 
      placeholder: 'Descrição',
      width: 'calc(99% - 7.5px)',
    }
  ]

  export const CourseEditFields = [
    {
      name: 'title',
      type: 'text',
      placeholder: 'Título',
      width: 'calc(99% - 7.5px)',
    },
    {
      name: 'description',
      type: 'textarea', 
      placeholder: 'Descrição',
      width: 'calc(99% - 7.5px)',
    },
    {
      name: 'file_document',
      type: 'file', 
      placeholder: 'Capa',
      width: 'calc(70% - 7.5px)',
    },
  ]

  export const CourseModuleEditFields = [
    {
      name: 'title',
      type: 'text',
      placeholder: 'Título',
      width: 'calc(99% - 7.5px)',
    },
   
    {
      name: 'file_document',
      type: 'file', 
      placeholder: 'Capa',
      accept: "image/*",
      width: 'calc(70% - 7.5px)',
    },
  ]


  export const CourseStatusOptions = [
    {
      label: 'Ativo',
      value: 1
    },
    {
      label: 'Inativo',
      value: 0
    }
  ]

  export const AssessmentFields = [
       {
      name: 'question',
      type: 'text', 
      placeholder: 'Pergunta',
      width: 'calc(99% - 7.5px)',
    },
    {
      name: 'answer_one',
      type: 'text', 
      placeholder: 'Resposta Um',
      width: 'calc(99% - 7.5px)',
    },
    {
      name: 'answer_two',
      type: 'text', 
      placeholder: 'Resposta Dois',
      width: 'calc(99% - 7.5px)',
    },
    {
      name: 'answer_three',
      type: 'text', 
      placeholder: 'Resposta Três',
      width: 'calc(99% - 7.5px)',
    },
    {
      name: 'response_true',
      type: 'text', 
      placeholder: 'Resposta',
      width: 'calc(99% - 7.5px)',
    },
  
  ];
  