// apiEndpoints.js

export const API_BASE_URL = process.env.REACT_APP_URL_BACKEND_AGENCIA || 'http://127.0.0.1:8000' ; 


export const USER_API = `${API_BASE_URL}/api/user`;
export const LOGIN_API = `${API_BASE_URL}/api/login`;
export const ME_API = `${API_BASE_URL}/api/autentic/me`;
export const GROUP_API = `${API_BASE_URL}/api/group`;
export const COMPANY_API = `${API_BASE_URL}/api/company`;
export const PEOPLE_API = `${API_BASE_URL}/api/people`;

export const COMPANYUSER_API = `${API_BASE_URL}/api/filter/company/users`;
export const CONSULTCOMPANYYUSER_API = `${API_BASE_URL}/api/consulta/company/user`
export const DELETECOMPANYYUSER_API = `${API_BASE_URL}/api/delete/company/user`

export const COURSE_API = `${API_BASE_URL}/api/course`;
export const TRAIL_API = `${API_BASE_URL}/api/trails`;
export const MODULE_API = `${API_BASE_URL}/api/module`;
export const LESSON_API = `${API_BASE_URL}/api/lessons`;
export const ASSESMENT_API = `${API_BASE_URL}/api/assessments`;

export const LIKES_API = `${API_BASE_URL}/api/like/course`;
export const GETLIKES_API = `${API_BASE_URL}/api/consulta/like/course/user`;

export const GETCOMPLETETRAIL_API = `${API_BASE_URL}/api/consulta/relacionada/consulta`;

export const FORUM_API = `${API_BASE_URL}/api/forum/questions`;
export const FORUMLIKE_API = `${API_BASE_URL}/api/forum/forum/like`;

export const COURSELIST_API = `${API_BASE_URL}/api/consulta/course/all`;
export const EVALUATIONLIST_API = `${API_BASE_URL}/api/consulta/avaliacao/all`;
export const TTRAILLIST_API = `${API_BASE_URL}/api/consulta/trail/all`;
export const LESSONLIST_API = `${API_BASE_URL}/api/consulta/lessons/all`;
export const MODULELIST_API = `${API_BASE_URL}/api/consulta/module/all`;
export const CATEGORYLIST_API = `${API_BASE_URL}/api/consulta/categories/all`;



export const FORUMRESPONSES_API = `${API_BASE_URL}/api/forum/responses`;
export const RESPONSE_API = `${API_BASE_URL}/api/consulta/forum/questionid`;
export const LESSONFORUM_API = `${API_BASE_URL}/api/consulta/forum/lessonsid`;

export const LIKECOUNT_API = `${API_BASE_URL}/api/consulta/total/like`;

export const CATEGORY_API = `${API_BASE_URL}/api/category`;

export const LINKTRAILCATEGORY_API = `${API_BASE_URL}/api/category`;
export const CATEGORYTRAIL_API = `${API_BASE_URL}/api/consulta/relacionada/trail/category`;
export const POSTCATEGORYTRAIL_API = `${API_BASE_URL}/api/category/trails`;
export const DELETECATEGORYTRAIL_API = `${API_BASE_URL}/api/delete/category/trails`;
export const GETLIKESBYRESPONSE_API = `${API_BASE_URL}/api/consulta/forum/like`;



export const EVALUATION_API = `${API_BASE_URL}/api/evaluations`;
export const EVALUATIONRESPONSES_API = `${API_BASE_URL}/api/evaluations/response`;
export const GETEVALUATIONRESPONSES_API = `${API_BASE_URL}/api/consulta/evaluations/responses`;
export const QUESTIONS_API = `${API_BASE_URL}/api/questions`;


export const CHANGEPASSWORD_API = `${API_BASE_URL}/api/altarar/senha`















export const GETPHOTOLESSON_API = `${API_BASE_URL}/api/photos/lesson`;
export const GETPHOTOMODULE_API = `${API_BASE_URL}/api/photos/module`;
export const GETPHOTOTRAIL_API = `${API_BASE_URL}/api/photos/trails`;
export const GETPHOTOCOURSE_API = `${API_BASE_URL}/api/photos/course`;

export const GETASSESMENTEBYLESSON_API = `${API_BASE_URL}/api/consulta/assessments/lessonsId`;
export const GETLESSONBYMODULE_API = `${API_BASE_URL}/api/consulta/lessons/modules`;
export const GETMODULEBYTRAIL_API = `${API_BASE_URL}/api/consulta/module/trails`;
export const GETCOURSEBYTRAIL_API = `${API_BASE_URL}/api/consulta/course/trails`;


export const PUTCOURSE_API = `${API_BASE_URL}/api/course/update`;
export const PUTTRAIL_API = `${API_BASE_URL}/api/trails/update`;
export const PUTMODULE_API = `${API_BASE_URL}/api/modules/update`;
export const PUTLESSON_API = `${API_BASE_URL}/api/lessons/update`;


export const INSCRIPTIONCOURSE_API = `${API_BASE_URL}/api/student/registration`;
export const DELETEINSCRIPTIONCOURSE_API = `${API_BASE_URL}/api/delete/student/registration`;
export const GETINSCRIPTIONCOURSE_API = `${API_BASE_URL}/api/consulta/registration/course/user`;

//*-------------------------------------------------------------------------------------

export const SELLER_API = `${API_BASE_URL}/api/seller`;
export const CONSULTSELLER_API = `${API_BASE_URL}/api/consulta/collection/people/seller`;

export const SUPPLIER_API = `${API_BASE_URL}/api/supplier`;
export const CONSULTSUPPLIER_API = `${API_BASE_URL}/api/consulta/collection/people/suppliers`;
export const CONSULTSUPPLIERCAR_API = `${API_BASE_URL}/api/consulta/supplier/car`;
export const SUPPLIERCAR_API = `${API_BASE_URL}/api/supplier/cars`;
export const DELETESUPPLIERCAR_API = `${API_BASE_URL}/api/delete/supplier/cars`;

export const APPRAISER_API = `${API_BASE_URL}/api/appraiser`;
export const CONSULTAPPRAISER_API = `${API_BASE_URL}/api/consulta/collection/people/appraiser`;

export const BUYER_API = `${API_BASE_URL}/api/buyer`;
export const CONSULTBUYER_API = `${API_BASE_URL}/api/consulta/collection/people/buyers`;

export const CNAEPEOPLEDELETE_API = `${API_BASE_URL}/api/delete/cnae/people`;
export const CNAEPEOPLE_API = `${API_BASE_URL}/api/cnae/people`;
export const CNAEPEOPLECONSULT_API = `${API_BASE_URL}/api/consulta/people/cnae`;

export const TAXES_API = `${API_BASE_URL}/api/taxes`;
export const MEASUREMENT_API = `${API_BASE_URL}/api/unit/measurement`;
export const DOCTYPE_API = `${API_BASE_URL}/api/type/document`;
export const PAYCOND_API = `${API_BASE_URL}/api/payment/conditions`;

export const BANKS_API = `${API_BASE_URL}/api/bancks`;
export const AGENCY_API = `${API_BASE_URL}/api/bancks/Agency`;
export const CONSULTAGENCY_API = `${API_BASE_URL}/api/consulta/banck/agencia`;
export const ANNOUNCE_API = `${API_BASE_URL}/api/announcement`;

export const INOUT_API = `${API_BASE_URL}/api/configuration/input/outout`;
export const INOUTAX_API = `${API_BASE_URL}/api/taxes/input/outout`;
export const INOUTAXCONSULT_API = `${API_BASE_URL}/api/consulta/entry/exit`;
export const INOUTAXDELETE_API = `${API_BASE_URL}/api/delete/taxes/input/outout`;

export const PRODUCT_API = `${API_BASE_URL}/api/product`;

export const PEOPLEDOCUMENTS_API = `${API_BASE_URL}/api/documents/save/document`;
export const GETPEOPLEDOCUMENTS_API = `${API_BASE_URL}/api/documents/index`//{company_id}/{people_id};
export const PERSONDOCUMENTS_API = `${API_BASE_URL}/api/documents/save/documentItem`;


export const GETPERSONDOCUMENTS_API = `${API_BASE_URL}/api/documents/documentItem/documentid`;
export const GETPERSONDOCUMENTSBYID_API = `${API_BASE_URL}/api/documents/documentItem/id`;
export const DELETEPERSONDOCUMENTS_API = `${API_BASE_URL}/api/documents/documentItem/index`;
export const PUTPERSONDOCUMENTS_API = `${API_BASE_URL}/api/documents/put/document/item`;

export const PHOTOCOVER_API = `${API_BASE_URL}/api/photos/save/photo`;
export const GETPHOTOCOVER_API = `${API_BASE_URL}/api/photos/index`;

export const GETPHOTOITEM_API = `${API_BASE_URL}/api/photos/photoItem/id`;
export const GETPHOTOITEMBYPHOTOID_API = `${API_BASE_URL}/api/photos/photoItem/photo_id`;

export const PHOTOITEM_API = `${API_BASE_URL}/api/photos/save/photoItem`;
export const DELETEPHOTOITEM_API = `${API_BASE_URL}/api/photos/photoItem/photo`;

export const GETPRODUCTPHOTOCOVER_API = `${API_BASE_URL}/api/photos/busca/product`;
export const GETANNOUNCEPHOTOCOVER_API = `${API_BASE_URL}/api/photos/busca/Announce`;

export const CLIENTCAR_API = `${API_BASE_URL}/api/client/cars`;

export const GETCLIENTCAR_API = `${API_BASE_URL}/api/consulta/client/car`;

export const PURCHASEORDER_API = `${API_BASE_URL}/api/purschase/order`;
export const SALESORDER_API = `${API_BASE_URL}/api/sales/order`;

export const DELETESALESORDERITEM_API = `${API_BASE_URL}/api/delete/sales/item`;
export const DELETEPURCHASEORDERITEM_API = `${API_BASE_URL}/api/delete/pursche/item`;

export const PURCHASEORDERITEM_API = `${API_BASE_URL}/api/purschase/order/item`;
export const SALESORDERITEM_API = `${API_BASE_URL}/api/sales/order/item`;

export const GETPURCHASEORDERBYPID_API = `${API_BASE_URL}/api/consulta/purchase/orders/item`;
export const GETSALESORDERITEMBYSID_API = `${API_BASE_URL}/api/consulta/sales/orders/item`;

//IBGE & FIPE API

const API_GENERAL_SERVICES = process.env.REACT_APP_URL_BACKEND_GENERAL || 'http://181.215.134.18/general-services';

export const LOGINIBGE_API = `${API_GENERAL_SERVICES}/api/login`;
export const IBGECITIESSTATES_API = `${API_GENERAL_SERVICES}/api/v1/ibge/cities`;
export const IBGESTATES_API = `${API_GENERAL_SERVICES}/api/v1/ibge/states`;

export const FIPEBRANDS_API = `${API_GENERAL_SERVICES}/api/v1/fipe/brands`;
export const FIPEMODELS_API = `${API_GENERAL_SERVICES}/api/v1/fipe/models`;
export const FIPEYEARS_API = `${API_GENERAL_SERVICES}/api/v1/fipe/years/search/fipe_model_code`;

export const FIPECOLORS_API = `${API_GENERAL_SERVICES}/api/v1/denatran/colors`;
export const FIPEFUEL_API = `${API_GENERAL_SERVICES}/api/v1/denatran/fuels`;

export const FIPEVEHICLES_API = `${API_GENERAL_SERVICES}/api/v1/fipe/vehicles`; // /{{fipecode_Marca}}/{{fipecode_Veiculo}}/{{fipecode_AnoVeiculo}}

export const CNPJ_API = process.env.REACT_APP_CNPJ_API; 

export const CFOP_API = `${API_GENERAL_SERVICES}/api/v1/fiscal/cfops`;
export const CNAE_API = `${API_GENERAL_SERVICES}/api/v1/fiscal/cnaes`;
export const NCM_API = `${API_GENERAL_SERVICES}/api/v1/fiscal/ncms`;