//Person Register and Edit Fields

export const camposPessoaFisica = [
  { name: 'name', type: 'text', placeholder: 'Nome *', width: 'calc(100% - 7.5px)',required:true },
  { name: 'document', type: 'text', placeholder: 'CPF *', width: 'calc(33.5% - 7.5px)', mask: '999.999.999-99',required:true },
  { name: 'rg', type: 'text', placeholder: 'RG', width: 'calc(32% - 7.5px)', mask: '99.999.999-9' },
  { name: 'zip_code', type: 'text', placeholder: 'CEP *', width: 'calc(33% - 7.5px)', mask: '99999-999',required:true },
  { name: 'address', type: 'text', placeholder: 'Logradouro', width: 'calc(33% - 7.5px)' },
  { name: 'number', type: 'text', placeholder: 'Número', width: 'calc(10% - 7.5px)' },
  { name: 'district', type: 'text', placeholder: 'Bairro', width: 'calc(25% - 7.5px)' },
  { name: 'city', type: 'text', placeholder: 'Cidade', width: 'calc(20% - 7.5px)' ,disabled:true},
  { name: 'state', type: 'text', placeholder: 'UF', width: 'calc(9% - 7.5px)' ,disabled:true},
  { name: 'email', type: 'email', placeholder: 'Email *', width: 'calc(32% - 7.5px)',required:true },
  { name: 'dd_1', type: 'text', placeholder: 'DDD', width: 'calc(10% - 7.5px)', mask: '99' },
  { name: 'phone_1', type: 'text', placeholder: 'Telefone 1 *', width: 'calc(22.5% - 7.5px)', mask: '9 9999-9999',required:true },
  { name: 'dd_2', type: 'text', placeholder: 'DDD', width: 'calc(10% - 7.5px)', mask: '99' },
  { name: 'phone_2', type: 'text', placeholder: 'Telefone 2', width: 'calc(22.5% - 7.5px)', mask: '9 9999-9999' },
  { name: 'profession', type: 'text', placeholder: 'Profissão', width: 'calc(40% - 7.5px)' },
];





export const fieldsForCompany = ['corporate_name', 'legal_representative_name', 'rg', 'fantasy_name', 'state_registration', 'document', 'zip_code',
'address', 'district', 'city', 'state', 'dd_1', 'phone_1', 'dd_2', 'phone_2', 'email', 'economic_activity', 'income', 'company_id', 'profession']

export const fieldsForPerson = ['corporate_name', 'document', 'rg', 'zip_code', 'address', 'district', 'state', 'dd_1', 'phone_1', 'dd_2', 'phone_2',
        'email', 'profession', 'city', 'income', 'company_id', 'state_registration'];

export const maskedFields = ['document', 'rg', 'zip_code', 'state_registration', 'phone_1', 'phone_2'];