export const PeopleDocFields = [
    {
        name: 'observation',
        type: 'text',
        placeholder: 'Descrição',
        width: 'calc(99% - 7.5px)',
      },
    ]


    export const PersonDocFields = [
          {
            name: 'observation',
            type: 'text',
            placeholder: 'Descrição',
            width: 'calc(99% - 7.5px)',
          },
          {
            name: 'file_document',
            type: 'file', 
            placeholder: '',
            width: 'calc(99% - 7.5px)',
          },
        ]