import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import InputLogin from '../../../components/InputLogin';
import ButtonSubmit from '../../../components/ButtonSubmit';
import {LOGIN_API, ME_API} from '../../../apiEndPoints';
import swal from 'sweetalert';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../vendor/fontawesome-free/css/all.min.css';
import '../../../css/sb-admin-2.min.css';
import theme from '../../../theme';

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const BackgroundImg = styled.div`
background-color: ${theme.backgroundColor3};
`

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  
  useEffect(() => {
    const tokenStoredTimestamp = parseInt(localStorage.getItem('tokenTimestamp'));
    const token = localStorage.getItem('token');
    
    if (token && !isNaN(tokenStoredTimestamp)) {
      const currentTime = new Date().getTime();
      const elapsedTimeMilliseconds = currentTime - tokenStoredTimestamp;
      const minutesSinceTokenStored = Math.floor(elapsedTimeMilliseconds / (1000 * 60)); 
      
      if (minutesSinceTokenStored < 59) {
        navigate('/dashboard');
      } else {
      }
    } else {

    }
  }, [navigate]);

  const handleLogin = async () => {
    try {
      setLoading(true);
  
      const response = await fetch(LOGIN_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (response.ok) {
        const data = await response.json();
        const token = data.access_token;
        const expire = data.expires_in;
        const userMaster = data.user_master; 
        const currentTime = new Date().getTime(); 

        console.log(userMaster)

        localStorage.setItem('tokenTimestamp', currentTime);
        localStorage.setItem('token', token);
        localStorage.setItem('expire', expire);
     
  
        swal({
          title: 'Login realizado',
          text: 'redirecionando...',
          icon: 'success',
          timer: 2000,
          buttons: false,
      }).then(() => {
          if (userMaster === 0) {
            navigate('/home')
          } else {
              fetchUserId(token);
          }
      });
  } else if (response.status === 404) {
        swal({
          title: 'Ops!',
          text: 'Usuário não encontrado ou inativo.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        }).then(() => {
          window.location.reload();
        });
        setEmail('');
        setPassword('');
      } else {
        const errorData = await response.json();
        console.error(errorData);
        swal({
          title: 'Ops!',
          text: 'CPF ou senha incorretas, tente de novo!',
          icon: 'error',
          timer: 2000,
          buttons: false,
        }).then(() => {
          window.location.reload();
        });
        setEmail('');
        setPassword('');
      }
    } catch (error) {
      console.error('Erro na autenticação:', error);
      swal("Ops!", "Algo deu errado, tente novamente mais tarde", "warning");
      swal({
        title: 'Ops!',
        text: 'Algo deu errado, tente novamente mais tarde',
        icon: 'warning',
        timer: 2000,
        buttons: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 4000);; 
    } finally {
      setLoading(false);
    }
  };
  
  const fetchUserId = async (token, callback) => {
    try {
      const userResponse = await fetch(ME_API, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}), 
      });
  
      if (userResponse.ok) {
        const userData = await userResponse.json();
        const userId = userData.id;
  
        localStorage.setItem('userId', userId);
        localStorage.setItem('companyID', 1);

        navigate('/dashboard')
      } else {
        console.error('Erro ao obter o ID do usuário:', userResponse.statusText);
      }
    } catch (error) {
      console.error('Erro ao obter o ID do usuário:', error);
    }
  };

    return (
      <CenteredContainer className="bg-gradient-ligth">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    {/* IMAGEM */}
                    <BackgroundImg className="col-lg-6 d-none d-lg-block bg-login-image"></BackgroundImg>
                    {/* TROCAR IMAGEM FIM*/}
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">Bem vindo de volta!</h1>
                        </div>
                        <form className="user">

                          {/* CAMPOS DE LOGIN E SENHA */}
                          <InputLogin
                          onChangeCPF={(value) => setEmail(value)}
                          onChangePassword={(value) => setPassword(value)}
                        />
                          {/* FIM  DOS COMPONENTES DE LOGIN*/}

                          <div className="form-group">
                            <div className="custom-control custom-checkbox small">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck"
                              />
                              {/* <label className="custom-control-label" htmlFor="customCheck">
                                Lembrar de mim 
                              </label> */}
                            </div>
                          </div>

                          {/* BOTÃO DE LOGIN */}
                          <ButtonSubmit onClick={handleLogin} disabled={loading} />
                          {/* FIM DO COMPONENTE*/}

    
                        {/* BOTÕES LOGAR COM GOOGLE  E FACEBOOK  */}
                          {/* <InputLoginGoogleFacebook /> */}
                          {/* FIM DO COMPONENTE*/}
                        </form>
                        
                        <div className="text-center">
                          <Link className="small" to="/forgot-password">
                            Esqueceu a senha?
                          </Link>
                        </div>
                        {/* <div className="text-center">
                          <Link className="small" to="/register">
                            Criar uma conta!
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CenteredContainer>
    );
  }

export default Login;
