import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import theme from '../../../theme';
import axios from 'axios';
import { useParams, useNavigate} from 'react-router-dom';
import {PEOPLE_API, LESSON_API, ASSESMENT_API} from '../../../apiEndPoints';
import swal from 'sweetalert';
import { BackButton, SaveButton , TabButton} from '../../../components/Buttons';
import { AssesmentFormdata, } from '../../../components/Formdatas';
import { Container, FormContainer, Form, ResponsiveDiv, Label, Buttons, Container1 ,TabContainer } from '../../../components/FormContainers';
import { Title } from '../../../components/General';
import { AssessmentFields, CourseStatusOptions } from '../../../components/Fields/CourseFields';

const Input = styled.input`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;


const EditAssesment = ({ match }) => {
  const { id: IDGet } = useParams();
  const [formData, setFormData] = useState(AssesmentFormdata);
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [peopleOptions, setPeopleOptions] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState([]);
  const [moduleLesson, setModuleLesson] = useState([]);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Avaliação');

  useEffect(() => {

    const fetchModule = async () => {
      try {
        const response = await axios.get(`${ASSESMENT_API}/${IDGet}`,{
         ...formData,
    
          headers: {
            
            Authorization: `Bearer ${token}`,
           
          },

        }); 

        console.log(response.data)
      
        setFormData(response.data);
        setSelectedPeople(response.data.people_id)
        setSelectedLesson(response.data.lesson_id)
        setSelectedStatus(response.data.status)
      } catch (error) {
        console.log(error)

      }
    };
    fetchModule();
  }, [IDGet,token]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyID');

    const  updatedFormdata = {
      ...formData,
      status: selectedStatus,
      user_id: userId,
      company_id: companyId,
      people_id: selectedPeople,
      lesson_id: selectedLesson

    }
    
    try {

      const response = await axios.put(`${ASSESMENT_API}/${IDGet}`,
        updatedFormdata

        , {
          headers: {
            'Content-Type': 'application/json',
            Authorization:` Bearer ${token}`,
          },

        });
      console.log(response, 'response')

      if (response.status === 200) {
        swal({
          title: 'OK!',
          text: 'Avaliação cadastrada com sucesso',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        handleGoBack()

      } else {
        swal({
          title: 'Ops!',
          text: 'Algo deu errado, tente denovo.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Error processing the form:', error);
    }
  };

  const handleChange = (e) => {const {name,value} = e.target;
  setFormData({...formData, [name]: value});
};
  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const fetchPeople = async () => {
      try {
        const response = await axios.get(`${PEOPLE_API}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const companyId = parseInt(localStorage.getItem('companyID'), 10);
        const filteredProducts = response.data.filter(
          (people) => people.company_id === companyId && people.type_people === 1
        );

        const options = filteredProducts.map((people) => ({
          label: people.name,
          value: people.id
        }));

        setPeopleOptions(options);
      } catch (error) {
        console.error('Erro ao buscar opções de produtos:', error);
      }
    };

    fetchPeople();
  }, []);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(`${LESSON_API}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const companyId = parseInt(localStorage.getItem('companyID'), 10);
        const filteredProducts = response.data.filter(
          (lesson) => lesson.company_id === companyId
        );

        const options = filteredProducts.map((lesson) => ({
          label: lesson.title,
          value: lesson.id
        }));

        setModuleLesson(options);
      } catch (error) {
        console.error('Erro ao buscar opções de produtos:', error);
      }
    };

    fetchCourse();
  }, []);
  
    const campos = [
        {
            name: 'people_id',
            type: 'select',
            placeholder: 'Responsável',
            width: 'calc(50% - 7.5px)',
            value: selectedPeople,
            onChange: (e) => {
              setSelectedPeople(e.target.value);
            },
            options: peopleOptions,
          },
          ...AssessmentFields,
          {
              name: 'lesson_id',
              type: 'select',
              placeholder: 'Aula',
              width: 'calc(13.5% - 7.5px)',
              value: selectedLesson,
              onChange: (e) => {
                setSelectedLesson(e.target.value);
              },
              options: moduleLesson,
            },
    {
        name: 'status',
        type: 'select',
        placeholder: 'Status',
        width: 'calc(13.5% - 7.5px)',
        value: selectedStatus,
        onChange: (e) => setSelectedStatus(e.target.value),
        options: CourseStatusOptions
      },
  ]

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  }

  return (
    <div>
    <FormContainer>
    <TabContainer>
        <TabButton
          active={activeTab === 'Avaliação'}
          onClick={() => handleTabChange('Avaliação')}
        >
          Avaliação
        </TabButton>
        {/* <TabButton
          active={activeTab === 'Fotos'}
          onClick={() => handleTabChange('Fotos')}
        >
          Fotos
        </TabButton> */}
      </TabContainer>
      <Container>
        <Title text={'Editar Avaliação'} backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'}/>
        <Container1>
            <Form onSubmit={handleSubmit}>
            {activeTab === 'Avaliação' && (
              <>
              {campos.map((campo) => (
                <ResponsiveDiv key={campo.name} width={campo.width}>
                  {campo.type === 'select' ? (
                    <Select
                      name={campo.name}
                      value={campo.value}
                      onChange={campo.onChange}
                      style={{ width: '100%' }}
                    >
                      <option value="" style={{ color: '#999' }} disabled>
                        {campo.placeholder}
                      </option>
                      {campo.options.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      id={campo.name}
                      type={campo.type}
                      name={campo.name}
                      value={formData[campo.name]}
                      onChange={campo.onChange || handleChange}
                      mask={campo.mask}
                      maskChar={null}
                      required
                      disabled={campo.disabled}
                      fullWidth={campo.fullWidth}
                      style={{ width: '100%' }}
                    />
                  )}
                  <Label htmlFor={campo.name}>
                    {campo.placeholder}
                  </Label>
                  </ResponsiveDiv>
              ))}
          </>
            )}
              {activeTab !== 'Fotos' && (
              <Buttons>
                <BackButton onClick={handleGoBack} />
                <SaveButton onClick={handleSubmit} disabled={loading} />
              </Buttons>
            )}
          </Form>
          {/* {activeTab === 'Fotos' && (
              <PhotoCoverEdit GetPhotosCoverById={GETANNOUNCEPHOTOCOVER_API} GetPhotosItemsByID={GETPHOTOITEMBYPHOTOID_API} PostApi={PHOTOITEM_API}/>
            )} */}
          </Container1>
        </Container>
      </FormContainer>
    </div>
  );
};

export default EditAssesment;