import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import theme from '../../../theme';
import axios from 'axios';
import { useParams, useNavigate} from 'react-router-dom';
import {PHOTOITEM_API,  GETPHOTOITEMBYPHOTOID_API, CATEGORY_API, GETPHOTOTRAIL_API, PUTTRAIL_API} from '../../../apiEndPoints';
import swal from 'sweetalert';
import { BackButton, SaveButton , TabButton} from '../../../components/Buttons';
import { CategoryFormdata, TrailFormdata, } from '../../../components/Formdatas';
import { Container, FormContainer, Form, ResponsiveDiv, Label, Buttons, Container1 ,TabContainer } from '../../../components/FormContainers';
import { Title } from '../../../components/General';
import PhotoCoverEdit from '../../../components/PhotoCoverEdit';
import { CategoryFields} from '../../../components/Fields/CourseFields';


const Input = styled.input`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

const Textarea = styled.textarea`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  resize: vertical;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

const EditCategory = ({ match }) => {
  const { id: IDGet } = useParams();
  const [formData, setFormData] = useState(CategoryFormdata);
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Categoria');
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [file, setFile] = useState(null);



  useEffect(() => {

    const fetchCategory = async () => {
      try {
        const response = await axios.get(`${CATEGORY_API}/${IDGet}`,{
         ...formData,
    
          headers: {
            
            Authorization: `Bearer ${token}`,
           
          },

        }); 

      
        setFormData(response.data);
    
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategory();
  }, [IDGet, token]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyID');

  
    const updatedFormdata = new FormData();
    updatedFormdata.append('description', formData.description);
    try {

      const response = await axios.put(`${CATEGORY_API}/${IDGet}`,
        updatedFormdata

        , {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },

        });
      console.log(response, 'response')

      if (response.status === 200) {
        swal({
          title: 'OK!',
          text: 'Categoria cadastrada com sucesso',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        handleGoBack()

      } else {
        swal({
          title: 'Ops!',
          text: 'Algo deu errado, tente denovo.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Error processing the form:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const reader = new FileReader();
      const file = files[0];
      
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
  
      reader.readAsDataURL(file);
  
      setFile(file);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


  const handleGoBack = () => {
    window.history.back();
  };

  // useEffect(() => {
  //   const fetchPeople = async () => {
  //     try {
  //       const response = await axios.get(`${PEOPLE_API}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`
  //         }
  //       });

  //       const companyId = parseInt(localStorage.getItem('companyID'), 10);
  //       const filteredProducts = response.data.filter(
  //         (people) => people.company_id === companyId && people.type_people === 1
  //       );

  //       const options = filteredProducts.map((people) => ({
  //         label: people.name,
  //         value: people.id
  //       }));

  //       setPeopleOptions(options);
  //     } catch (error) {
  //       console.error('Erro ao buscar opções de produtos:', error);
  //     }
  //   };

  //   fetchPeople();
  // }, []);

  
  
    const campos = [
        // {
        //     name: 'people_id',
        //     type: 'select',
        //     placeholder: 'Responsável',
        //     width: 'calc(50% - 7.5px)',
        //     value: selectedPeople,
        //     onChange: (e) => {
        //       setSelectedPeople(e.target.value);
        //     },
        //     options: peopleOptions,
        //   },
        ...CategoryFields,
  ]

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  }

  return (
    <div>
    <FormContainer>
    <TabContainer>
        <TabButton
          active={activeTab === 'Categoria'}
          onClick={() => handleTabChange('Categoria')}
        >
          Categoria
        </TabButton>
     
      </TabContainer>
      <Container>
        <Title text={'Editar Categoria'} backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'}/>
        <Container1>
            <Form onSubmit={handleSubmit}>
            {activeTab === 'Categoria' && (
              <>
              {campos.map((campo) => (
                <ResponsiveDiv key={campo.name} width={campo.width}>
                  {campo.type === 'select' ? (
                        <Select
                        name={campo.name}
                        value={campo.value}
                        onChange={campo.onChange}
                        style={{ width: '100%' }}
                        disabled={campo.disabled }
                      >
                        <option value="" style={{ color: '#999' }} disabled>
                          {campo.placeholder}
                        </option>
                        {campo.options.map((option, index) => (
                          <option key={index} value={option.value}>
                               {option.label}
                              </option>
                            ))}
                          </Select>
                        ) : campo.type === 'file' ? (
                          <div style={{ display: 'flex', alignItems: 'end' }}>
                            <div>
                              {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />}
                            </div>
                            <div>
                              <Input
                                id={campo.name}
                                type="file"
                                name={campo.name}
                                onChange={handleChange}
                                required
                                disabled={campo.disabled}
                                fullWidth={campo.fullWidth}
                                style={{ width: '100%' }}
                              />
                            </div>
                          </div>
                        ) : campo.type === 'textarea' ? (
                          <Textarea
                          id={campo.name}
                          name={campo.name}
                          value={formData[campo.name]}
                          onChange={campo.onChange || handleChange}
                          required={campo.required}
                          disabled={campo.disabled }
                          fullWidth={campo.fullWidth}
                          style={{ width: '100%' }}
                        />
                      ) : (
                    <Input
                      id={campo.name}
                      type={campo.type}
                      name={campo.name}
                      value={formData[campo.name]}
                      onChange={campo.onChange || handleChange}
                      mask={campo.mask}
                      maskChar={null}
                      required
                      disabled={campo.disabled }
                      fullWidth={campo.fullWidth}
                      style={{ width: '100%' }}
                    />
                  )}
                  <Label htmlFor={campo.name}>{campo.placeholder}</Label>
                </ResponsiveDiv>
              ))}
                </>
                 
            )}
           
              <Buttons>
                <BackButton onClick={handleGoBack} />
                <SaveButton onClick={handleSubmit} disabled={loading} />
              </Buttons>
            
          </Form>
          {activeTab === 'Fotos' && (
              <PhotoCoverEdit GetPhotosCoverById={GETPHOTOTRAIL_API} GetPhotosItemsByID={GETPHOTOITEMBYPHOTOID_API} PostApi={PHOTOITEM_API}/>
            )}
          </Container1>
        </Container>
      </FormContainer>
    </div>
  );
};

export default EditCategory;