import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { Title } from '../../../components/General';
import { FORUM_API, USER_API } from '../../../apiEndPoints';

const Container = styled.div`
  width: 100%;
  min-height: 100vh; 
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QuestionCard = styled.div`
  width: 100%;
  max-width: 800px; 
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  color: inherit;
  background-color: #f9f9f9; 
  
  &:hover {
    background-color: #e0e0e0;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const CardBody = styled.div`
  margin-bottom: 15px;
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #555;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const TimeAgo = ({ timestamp }) => {
  const timeDifference = (date1, date2) => {
    const diffInMs = date2 - date1;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    if (diffInMinutes < 60) return `${diffInMinutes} minutos atrás`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} horas atrás`;
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 365) return `${diffInDays} dias atrás`;
    const diffInYears = Math.floor(diffInDays / 365);
    return `${diffInYears} anos atrás`;
  };

  const now = new Date();
  const postDate = new Date(timestamp);
  return <span>{timeDifference(postDate, now)}</span>;
};

function ForumPage() {
  const [questions, setQuestions] = useState([]);
  const [userNames, setUserNames] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`${FORUM_API}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setQuestions(response.data);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchQuestions();
  }, []);

  useEffect(() => {
    const fetchUserNames = async () => {
      const userIds = new Set();
      questions.forEach((question) => userIds.add(question.user_id));
      
      const userNamePromises = Array.from(userIds).map(userId =>
        axios.get(`${USER_API}/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
      );

      try {
        const userResponses = await Promise.all(userNamePromises);
        const userNamesMap = userResponses.reduce((acc, response) => {
          acc[response.data.id] = response.data.name;
          return acc;
        }, {});
        setUserNames(userNamesMap);
      } catch (error) {
        console.error('Erro ao buscar os nomes dos usuários:', error);
      }
    };

    if (questions.length > 0) {
      fetchUserNames();
    }
  }, [questions]);

  const handleQuestionClick = (questionId) => {
    navigate(`/forum/question/${questionId}`);
  };

  const handlePostQuestion = () => {
    navigate('/forum/post-question');
  };


  return (
    <Container>
      <Title text="Fórum" />
      {questions.map((question) => (
        <QuestionCard key={question.id} onClick={() => handleQuestionClick(question.id)}>
          <CardHeader>
            <h3>{question.title}</h3>
            <span><TimeAgo timestamp={question.created_at} /></span>
          </CardHeader>
          <CardBody>
            <p>{question.question}</p>
          </CardBody>
          <CardFooter>
            <span><strong>Postado por:</strong> {userNames[question.user_id] || 'Carregando...'}</span>
            <span><strong>Tags:</strong> {Array.isArray(question.tags) ? question.tags.join(', ') : question.tags}</span>
          </CardFooter>
        </QuestionCard>
      ))}
      <ButtonContainer>
     

      <button className="btn btn-primary" onClick={handlePostQuestion}>Postar uma Pergunta</button>
      </ButtonContainer>
    </Container>
  );
}

export default ForumPage;
