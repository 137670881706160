import React from "react";
import { EVALUATIONLIST_API} from "../../../apiEndPoints";
import theme from "../../../theme";
import Lists from "../../../components/Lists";
import { AssesmentColumns} from "../../../components/TableColumns";


const AssesmentList = () => {
  const token = localStorage.getItem('token');

  return(
    <div>
      <Lists
      title={'Avaliações'}
      api={EVALUATIONLIST_API}
      buttonColor={theme.primaryColor}
      addURL={`/cadastro/avaliacoes`}
      editURL={`/editevaluation`}
      filterdisplay='block'
      fields={AssesmentColumns}
      token={token}
      applyCompanyIdFilter={false}
      />
    </div>
  )
}

export default AssesmentList