import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
import '../../css/style1.css';

const FooterPo = () => {
    return (
        <div className="container-fluid bg-dark text-light footer mt-5 wow fadeIn" data-wow-delay="0.1s">
            {/* pt-5 -- classe bootstrap para aumentar o footer
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Mapa</h4>
                        <a className="btn btn-link" href="">Sobre nós</a>
                        <a className="btn btn-link" href="">Entre em Contato</a>
                        <a className="btn btn-link" href="">Política de Privacidade</a>
                        <a className="btn btn-link" href="">Termos & Condições</a>
                        <a className="btn btn-link" href="">FAQs & Ajuda</a>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Contato</h4>
                        <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Rua: Silveira Martins, 810 sala 408 Novo
                            Hamburgo RS</p>
                        <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>(51) 33381 0310</p>
                        <p className="mb-2"><i className="fa fa-envelope me-3"></i>junior@somosfranquias.com.br</p>
                        <div className="d-flex pt-2">
                            <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-youtube"></i></a>
                            <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Galeria</h4>
                        <div className="row g-2 pt-2">
                            <div className="col-4">
                                <img className="img-fluid bg-light p-1" src="img/course-1.jpg" alt="" />
                            </div>
                            <div className="col-4">
                                <img className="img-fluid bg-light p-1" src="img/course-2.jpg" alt="" />
                            </div>
                            <div className="col-4">
                                <img className="img-fluid bg-light p-1" src="img/course-3.jpg" alt="" />
                            </div>
                            <div className="col-4">
                                <img className="img-fluid bg-light p-1" src="img/course-2.jpg" alt="" />
                            </div>
                            <div className="col-4">
                                <img className="img-fluid bg-light p-1" src="img/course-3.jpg" alt="" />
                            </div>
                            <div className="col-4">
                                <img className="img-fluid bg-light p-1" src="img/course-1.jpg" alt="" />
                            </div>
                        </div> 
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Newsletter</h4>
                        <p>Receba em primeira mão nossas novidades.</p>
                        <div className="position-relative mx-auto" style={{ maxWidth: "400px" }}>
                            <input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder="Seu Email" />
                            <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 "style={{ height: "38px" }} >SignUp</button>
                        </div>
                    </div>
                </div>
            </div> */}
           <div className="container">
  <div className="copyright">
    <div className="row justify-content-center">
      <div className="col-md-12 text-center mb-3 mb-md-0">
        &copy; <a className="border-bottom" href="http://www.concentraautos.com" target="_blank">Concentra Autos</a>, All Right Reserved.
      </div>
    </div>
  </div>
</div>
        </div>
    );
}

export default FooterPo;