import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { useParams } from 'react-router-dom';
import { GETPEOPLEDOCUMENTS_API, GETPERSONDOCUMENTS_API} from '../../../apiEndPoints';
import swal from 'sweetalert';
import { AddNewButton, BackButton, SaveButton } from '../../../components/Buttons';
import {FormContainer, Form, ResponsiveDiv, Label, Buttons,  } from '../../../components/FormContainers';
import {PeopleDocumentFormdata } from '../../../components/Formdatas';
import { PeopleDocFields } from '../../../components/Fields/PeopleDocumentsFields';
import PersonDocumentsRegister from '../../RegisterPages/PeopleDocumentsRegister/PersonDocumentsRegister';
import EditPersonDocuments from './EditPersonDocuments';
import { Title } from '../../../components/General';

const Input = styled(InputMask)`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%;
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;


const EditPeopleDocuments = ({ match }) => {
  const { id: PDId } = useParams();
  const [formData, setFormData] = useState(PeopleDocumentFormdata);
  const token = localStorage.getItem('token');
  const [loading] = useState(false);

  const [personDocRecords, setPersonDocRecords] = useState([]);
  const [docId, setDocId] = useState([]);
  const [showExtras,] = useState(true);
  const [numPersonregister, setNumPersonRegister] = useState(0);
  const [show] = useState(false);
  localStorage.setItem('PDId', PDId)

  console.log(docId)

  useEffect(() => {
    const companyID = localStorage.getItem('companyID');
  
    const fetchPeopleDocs = async () => {
      try {
        const response = await axios.get(`${GETPEOPLEDOCUMENTS_API}/${companyID}/${PDId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFormData(response.data[0]);
        console.log(response.data[0])
        setDocId(response.data[0].id);
      } catch (error) {
        console.log(error);
      }
    };
  
    const fetchPersonDocRecords = async () => {
      try {
        const response = await axios.get(`${GETPERSONDOCUMENTS_API}/${docId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPersonDocRecords(response.data);

      } catch (error) {
        console.error('Erro ao buscar registros de InOutTax:', error);
      }
    };
  
    if (docId) {
      fetchPersonDocRecords();
    }
  
    fetchPeopleDocs();
  }, [PDId, docId, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userId = localStorage.getItem('userId');
    const companyID = localStorage.getItem('companyID')

    const updatedFormdata = {
      ...formData,
      user_id: userId,
    };

    try {
      const response = await axios.put(`${GETPEOPLEDOCUMENTS_API}/${companyID}/${PDId}`, updatedFormdata, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response, 'response');

      if (response.status === 200) {
        swal({
          title: 'OK!',
          text: 'Editado com sucesso',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        handleGoBack();
      } else {
        swal({
          title: 'Ops!',
          text: 'Algo deu errado, tente denovo.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Error processing the form:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const campos = [

    ...PeopleDocFields,
  ];



  return (
    <div>
      <Title text={'Documentos'}/>
      {show && (
        <>
            <Form onSubmit={handleSubmit}>
              {campos.map((campo) => (
                <ResponsiveDiv key={campo.name} width={campo.width}>
                  {campo.type === 'select' ? (
                    <Select
                      name={campo.name}
                      value={campo.value}
                      onChange={campo.onChange}
                      style={{ width: '100%' }}
                      disabled={campo.disabled}
                    >
                      <option value="" style={{ color: '#999' }} disabled>
                        {campo.placeholder}
                      </option>
                      {campo.options.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      id={campo.name}
                      type={campo.type}
                      name={campo.name}
                      value={formData[campo.name]}
                      onChange={campo.onChange || handleChange}
                      mask={campo.mask}
                      maskChar={null}
                      required
                      disabled={campo.disabled}
                      fullWidth={campo.fullWidth}
                      style={{ width: '100%' }}
                    />
                  )}
                  <Label htmlFor={campo.name}>{campo.placeholder}</Label>
                </ResponsiveDiv>
              ))}
             
              <Buttons>
                <BackButton onClick={handleGoBack} />
                <SaveButton onClick={handleSubmit} disabled={loading} />
              </Buttons>
            </Form>
            </>
      )}
      {personDocRecords.map((record) => (
        <EditPersonDocuments key={record.id} record={record} personID={record.document_id} peopleDocId={record.id} />
      ))}
       {showExtras && (<>
      {Array.from({ length: numPersonregister }).map((_, index) => (
        <PersonDocumentsRegister key={index} />
      ))}
      <FormContainer paddingWrap={'0px'}>
      <AddNewButton onClick={() => setNumPersonRegister(numPersonregister + 1)} />
      </FormContainer>
      </>
      )}
    </div>
  );
};

export default EditPeopleDocuments;