import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { GETLESSONBYMODULE_API, MODULE_API } from '../../apiEndPoints';

function ClassesList() {
  const { id: IDGet } = useParams();
  const [loading, setLoading] = useState(true);
  const [modules, setModules] = useState(null);
  const [classes, setClasses] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    axios.get(`${MODULE_API}/${IDGet}`, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then(response => {
        setModules(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, [IDGet]);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    axios.get(`${GETLESSONBYMODULE_API}/${IDGet}`, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then(response => {
        setClasses(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);; 
  }, []);

  if (loading) {
    return (
      <div className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" variant="primary" style={{ width: '3rem', height: '3rem' }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {/* Categories Start */}
      <div className="container-xxl py-5 category">
        <div className="text-center">
          <p style={{ fontSize: '22px', fontWeight: 'bold' }}>{modules?.title}</p>
        </div>
        {/* Conteúdo */}

        {/* <div className="container mt-5" id="modulos">
          <h2>Trilhas</h2>
          {classes.map(lesson => (
            <div className="row mb-4" key={lesson.id}>
              <div className="col-md-4">
                <img src={lesson.arquivo} alt={lesson.title} className="img-fluid" />
              </div>
              <div className="col-md-8">
                <h3>{lesson.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: lesson.summary_lessons }} />
              </div>
            </div>
          ))}
        </div> */}

        <div className="container">
      <h2>Aulas de {modules.title}</h2>
      <ul className="list-group">
        {classes.map((lesson, index) => (
          <li key={index} className="list-group-item">
            <div className="media">
              <a href={`/curso/modulo/aula/${lesson.id}`}>
                <img src={lesson.arquivo} className="mr-3" alt="Imagem de exemplo" 
                 style={{
                  width: '180px',
                  height: '120px',
                  objectFit: 'cover',
                  objectPosition: 'center'
                }}/>
              </a>
              <div className="media-body">
                <h5 className="mt-0">{lesson.title}</h5>
                <div dangerouslySetInnerHTML={{ __html: lesson.summary_lessons }} />
                <ul>
                  {/* {classes.map((detail, detailIndex) => (
                    <li key={detailIndex}>
                      <strong>{detail.title}:</strong> {detail.content}
                    </li>
                  ))} */} 
                </ul>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>

{/* 
        <div className="container mt-5" id="avaliacao">
          <h2>Avaliação</h2>
        </div> */}
      </div>
      {/* Categories End */}

      {/* Progress Bar */}
      <div className="container-xxl py-5">
        <div className="progress">
          <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">0%</div>
        </div>
      </div>

      {/* Footer Button */}
      {/* <div className="container">
        <div className="row">
          <div className="col footer-btn text-end">
            <a href={`/cursos/intro/${modules.id}`} className="btn btn-primary py-3 px-lg-4 d-none d-lg-block">Iniciar<i className="fa fa-arrow-right ms-1"></i></a>
          </div>
        </div>
      </div> */}

      {/* Back to Top */}
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="fas fa-caret-up"></i></a>
    </div>
  );
}

export default ClassesList;
