import React from "react";
import { LESSONLIST_API} from "../../../apiEndPoints";
import theme from "../../../theme";
import Lists from "../../../components/Lists";
import { LessonColumns} from "../../../components/TableColumns";


const LessonList = () => {
  const token = localStorage.getItem('token');

  return(
    <div>
      <Lists
      title={'Aulas'}
      api={LESSONLIST_API}
      buttonColor={theme.primaryColor}
      addURL={`/cadastro/lessonregister`}
      editURL={`/editlesson`}
      filterdisplay='block'
      fields={LessonColumns}
      token={token}
      applyCompanyIdFilter={true}
      />
    </div>
  )
}

export default LessonList