import React from 'react';
import styled, { css } from 'styled-components';

const CardFooter = styled.div`
  padding: 5px;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const Button = styled.button`
  padding: 5px 20px;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
  ${(props) =>
    props.trailEnrolled
      ? css`
          background-color: #28a745;
        `
      : css`
          background-color: #06BBCC; 
        `}
`;

const TrailCardReg = ({ trail, trailEnrolled, like, likeCount, trailOnenroll, onLike }) => {
  return (
    // <CardContainer>
    //   <CardHeader>Curso</CardHeader>
    //   <a href={`/cursos/${trail.id}`} className="d-block">
    //     <CardImages>
    //       <CardImage
    //         className="img-fluid"
    //         src={trail.arquivo}
    //         alt={trail.title}
    //       />
    //     </CardImages>
    //   </a>
    //   <CardContent>
    //     <h3 className="mb-0">{trail.price}</h3>
    //     <div className="mb-3">
    //       {Array.from({ length: trail.rating }).map((_, i) => (
    //         <small key={i} className="fa fa-star text-primary"></small>
    //       ))}
    //     </div>
    //     <h5 className="mb-4">{trail.title}</h5>
    //   </CardContent>
      <CardFooter>
        <Button trailEnrolled={trailEnrolled} onClick={trailOnenroll}>
          {trailEnrolled ? 'Matricualdo' : 'Matricular'}
        </Button>
        {/* <LikeButton onClick={onLike}>
          <IconWrapper like={like}>
            <FontAwesomeIcon 
              icon={like ? solidHeart : regularHeart} 
              color={like ? 'red' : 'grey'}
            />
            <span style={{ marginLeft: '5px' }}>
              {likeCount >= 1000 ? `${(likeCount / 1000).toFixed(1)}k` : likeCount}
            </span>
          </IconWrapper>
        </LikeButton> */}
      </CardFooter>
    // </CardContainer>
  );
};

export default TrailCardReg;
