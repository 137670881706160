import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LESSON_API } from '../../../apiEndPoints';
import styled from 'styled-components';
import { NextButton, PrevButton } from '../../../components/Buttons';

const ArrowsContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: right;
  margin-right: 4rem;
`;


function ClassIntroduction() {
  const { id: IDGet } = useParams();
  const [loading, setLoading] = useState(true);
  const [lesson, setLesson] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate();


  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    axios.get(`${LESSON_API}/${IDGet}`, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then(response => {
        setLesson(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, [IDGet]);



  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);; 
  }, []);

  if (loading) {
    return (
      <div className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" variant="primary" style={{ width: '3rem', height: '3rem' }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {/* Categories Start */}
      <div className="container-xxl py-5 category">
        <div className="text-center">
          <p style={{ fontSize: '22px', fontWeight: 'bold' }}>{lesson?.title}</p>
        </div>
        {/* Conteúdo */}
        <div className="container mt-5" id="introducao">
          <h2>Introdução</h2>
          <div dangerouslySetInnerHTML={{ __html: lesson?.summary_lessons }} />
        </div>


       

      </div>
      {/* Categories End */}

      {/* Progress Bar */}
      <div className="container-xxl py-5">
        <div className="progress">
          <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">0%</div>
        </div>
      </div>

      <ArrowsContainer class="container">
      <div className="row">
          <div  style={{ gap: '5px' }} className="col footer-btn d-flex justify-content-end">
          <PrevButton backgroundColor={'#06BBCC'} onClick={() => handleGoBack()} className="btn btn-primary"><i className="fa fa-arrow-right ms-1"></i></PrevButton>
          <NextButton backgroundColor={'#06BBCC'} onClick={() => navigate(`/curso/modulo/aula/${lesson.id}`)} className="btn btn-primary me-2"><i className="fa fa-arrow-left ms-1"></i></NextButton>
          </div>
        </div>
      </ArrowsContainer>

     

      {/* Back to Top */}
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="fas fa-caret-up"></i></a>
    </div>
  );
}

export default ClassIntroduction;
