import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp as solidThumbsUp, faThumbsDown as regularThumbsUp } from '@fortawesome/free-solid-svg-icons'; // Substituindo os ícones

const LikeButton = styled.button`
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
`;

const IconWrapper = styled.div`
  transition: transform 0.3s ease, opacity 0.3s ease;
  ${(props) =>
    props.like
      ? css`
          transform: scale(1.2);
          opacity: 1;
        `
      : css`
          transform: scale(1);
          opacity: 0.7;
        `}
`;

const ResponseLikesButton = ({ like, likeCount, onLike }) => {
  return (
        <LikeButton onClick={onLike}>
          <IconWrapper like={like}>
            <FontAwesomeIcon 
              icon={like ? solidThumbsUp : solidThumbsUp}
              color={like ? 'blue' : 'grey'} 
            />
            <span style={{ marginLeft: '5px' }}>
              {likeCount >= 1000 ? `${(likeCount / 1000).toFixed(1)}k` : likeCount}
            </span>
          </IconWrapper>
        </LikeButton>
  );
};

export default ResponseLikesButton;
