import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import VRoutes from './ZROTAS/VictorRoutes';
import ERoutes from './ZROTAS/EduardoRoutes';


const routes = [
  ...VRoutes,
  ...ERoutes,
];

 const root = ReactDOM.createRoot(document.getElementById('root'));

 root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </BrowserRouter>
    </DndProvider>
  </React.StrictMode>
);
