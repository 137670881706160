import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import {
  LINKTRAILCATEGORY_API,
  TRAIL_API,
  POSTCATEGORYTRAIL_API,
  CATEGORYTRAIL_API,
  DELETECATEGORYTRAIL_API
} from '../../../apiEndPoints';
import { Container, Container1, FormContainer } from '../../../components/FormContainers';
import { Title } from '../../../components/General';
import theme from '../../../theme';
import swal from 'sweetalert';


const SubTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 10px;
  text-align: left;
  color: #333;
`;

const Instructions = styled.p`
  font-size: 16px;
  color: #666;
  bottom: 10px;
  text-align: center;
  justify-content: center;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color:${theme.primaryColor};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: ${theme.secondaryColor};
  }
`;

const TrailList = styled.ul`
  margin-top: 20px;
  list-style-type: none;
  padding: 0;
`;

const TrailItem = styled.li`
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinkCategoryTrail = () => {
  const [categories, setCategories] = useState([]);
  const [trails, setTrails] = useState([]);
  const [availableTrails, setAvailableTrails] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTrail, setSelectedTrail] = useState('');
  const [associatedTrails, setAssociatedTrails] = useState([]);
  const token = localStorage.getItem('token');

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await axios.get(LINKTRAILCATEGORY_API, axiosConfig);
        const allCategories = categoryResponse.data;

        const categoryTrailResponse = await axios.get(CATEGORYTRAIL_API, axiosConfig);
        const categoriesWithTrails = categoryTrailResponse.data;

        const combinedCategories = allCategories.map((category) => {
          const categoryWithTrails = categoriesWithTrails.find(
            (catWithTrails) => catWithTrails.categoria.id === category.id
          );
          return {
            categoria: category,
            trilhas: categoryWithTrails ? categoryWithTrails.trilhas : [],
          };
        });

        setCategories(combinedCategories);

        const trailsResponse = await axios.get(TRAIL_API, axiosConfig);
        const allTrails = trailsResponse.data;

        setTrails(allTrails);
        setAvailableTrails(allTrails);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      const selectedCategoryObj = categories.find(
        (category) => category.categoria.id === parseInt(selectedCategory)
      );
  
      if (selectedCategoryObj) {
        const associatedTrailIds = selectedCategoryObj.trilhas.map(trail => trail.id);
  
        const filteredTrails = trails.filter(trail => !associatedTrailIds.includes(trail.id));
  
        setAvailableTrails([...filteredTrails]);
        setAssociatedTrails([...selectedCategoryObj.trilhas]);
      } else {
        setAssociatedTrails([]);
        setAvailableTrails([...trails]);
      }
    } else {
      setAvailableTrails([...trails]);
      setAssociatedTrails([]);
    }
  
    setSelectedTrail('');
  }, [selectedCategory, categories, trails]);
  
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleAssociate = async () => {
    if (selectedCategory && selectedTrail) {
      try {
        await axios.post(
          POSTCATEGORYTRAIL_API,
          {
            category_id: selectedCategory,
            trails_id: selectedTrail,
          },
          axiosConfig
        );
        swal({
          title: 'OK!',
          text: 'Trilha Associada com Sucesso',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });

        const updatedCategories = [...categories].map((category) => {
          if (category.categoria.id === parseInt(selectedCategory)) {
            return {
              ...category,
              trilhas: [...category.trilhas, trails.find((trail) => trail.id === parseInt(selectedTrail))],
            };
          }
          return category;
        });
        setCategories(updatedCategories);

        const updatedAvailableTrails = availableTrails.filter((trail) => trail.id !== parseInt(selectedTrail));
        setAvailableTrails(updatedAvailableTrails);

        setAssociatedTrails(updatedCategories.find(category => category.categoria.id === parseInt(selectedCategory)).trilhas);
        setSelectedTrail(''); 
      } catch (error) {
        console.error('Erro ao associar trilha:', error);
      }
    } else {
      swal({
        title: 'Ops!',
        text: 'Selecione uma Categoria e uma Trilha',
        icon: 'warning',
        timer: 2000,
        buttons: false,
      });
    }
  };

  const handleRemoveTrail = async (trailId) => {
    try {
      await axios.delete(`${DELETECATEGORYTRAIL_API}/${trailId}/${selectedCategory}`, axiosConfig);

      const updatedCategories = [...categories].map((category) => {
        if (category.categoria.id === parseInt(selectedCategory)) {
          return {
            ...category,
            trilhas: category.trilhas.filter((trail) => trail.id !== trailId),
          };
        }
        return category;
      });
      setCategories(updatedCategories);

      const removedTrail = trails.find((trail) => trail.id === trailId);
      if (removedTrail) {
        setAvailableTrails([...availableTrails, removedTrail]);
      }

      const updatedCategory = updatedCategories.find(category => category.categoria.id === parseInt(selectedCategory));
      if (updatedCategory) {
        setAssociatedTrails(updatedCategory.trilhas);
      }
    } catch (error) {
      console.error('Erro ao remover trilha:', error);
    }
  };

  console.log(associatedTrails, 'sss')

  return (
    <FormContainer>
      <Container>
        <Title text={'Associar Trilha a Categoria'} backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'} />

        <Container1>
          <SubTitle>Selecionar Categoria </SubTitle>

          <Select value={selectedCategory} onChange={handleCategoryChange}>
            <option value="">Selecione uma Categoria</option>
            {categories.map((category) => (
              <option key={category.categoria.id} value={category.categoria.id}>
                {category.categoria.description || 'Sem título'}
              </option>
            ))}
          </Select>

          {selectedCategory && (
            <>
              <SubTitle>Selecionar Trilha</SubTitle>

              <Select value={selectedTrail} onChange={(e) => setSelectedTrail(e.target.value)}>
                <option value="">Selecione uma Trilha</option>
                {availableTrails.map((trail) => (
                  <option key={trail.id} value={trail.id}>
                    {trail.tituloTrilha || trail.title}
                  </option>
                ))}
              </Select>

              <Button onClick={handleAssociate}>Associar</Button>

              <TrailList>
                <SubTitle>Trilhas Associadas</SubTitle>
                {associatedTrails.length > 0 ? (
                  associatedTrails.map((trail) => (
                    <TrailItem key={trail.id}>
                      {trail.tituloTrilha || trail.title}
                      <Button onClick={() => handleRemoveTrail(trail.id)}>X</Button>
                    </TrailItem>
                  ))
                ) : (
                  <p>Nenhuma trilha associada</p>
                )}
              </TrailList>
            </>
          )}
        </Container1>
      </Container>
      <Instructions>Selecione uma categoria para ver as trilhas associadas e associar novas trilhas</Instructions>
    </FormContainer>
  );
};

export default LinkCategoryTrail;
