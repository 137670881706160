

import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { BANKS_API, COURSE_API, FIPEBRANDS_API, FIPEMODELS_API, IBGECITIESSTATES_API, IBGESTATES_API, LESSON_API, MODULE_API, PEOPLE_API, PRODUCT_API, TRAIL_API } from '../../apiEndPoints';

//User Columns
export const UserColumns = [
{ Header: 'Nome', accessor: 'name' },
{ Header: 'Email', accessor: 'email' },
{
  Header: 'Telefone',
  accessor: 'fone',
  Cell: ({ value }) => {
    const valueAsString = String(value);
    const isCnpj = valueAsString.length > 13;
    const mask = isCnpj ? '(99) 9 9999-9999' : '(99) 9999-9999';

    return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <InputMask
          mask={mask}
          value={valueAsString}
          readOnly
        >
          {(inputProps) => <input {...inputProps} style={{ border: 'none', outline: 'none' }} />}
        </InputMask>
      </div>
    );
  },
},
{ Header: 'Cidade', accessor: 'city' },
{ Header: 'Estado', accessor: 'state' },
// { Header: 'Grupo', accessor: 'group_id', Cell: ({ value }) => getGroupName(value) },
{
  Header: 'CPF',
  accessor: 'cpf',
  Cell: ({ value }) => {
    const valueAsString = String(value);
    const isCnpj = valueAsString.length > 11;
    const mask = isCnpj ? '99.999.999/9999-99' : '999.999.999-99';

    return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <InputMask
          mask={mask}
          value={valueAsString}
          readOnly
        >
          {(inputProps) => <input {...inputProps} style={{ border: 'none', outline: 'none' }} />}
        </InputMask>
      </div>
    );
  },
},
{ Header: 'Status', accessor: 'status', Cell: ({ value }) => (value === 1 ? 'Ativo' : 'Inativo') },
]


//Companies Columns

export const CompanyColumns = [
  { Header: 'Nome', accessor: 'fantasy_name' },
  { Header: 'Email', accessor: 'email' },
  {
    Header: 'Telefone', accessor: 'phone',
    Cell: ({ value }) => {
      const valueAsString = String(value);
      const isCellphone = valueAsString.length > 8;
      const mask = isCellphone ? '9 9999-9999' : '9999-9999';

      return (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <InputMask
            mask={mask}
            value={valueAsString}
            readOnly
          >
            {(inputProps) => <input {...inputProps} style={{ border: 'none', outline: 'none' }} />}
          </InputMask>
        </div>)
    }
  },
  { Header: 'Cidade', accessor: 'city' },
  { Header: 'Estado', accessor: 'state' },
  {
    Header: 'CNPJ', accessor: 'cnpj',
    Cell: ({ value }) => {
      const valueAsString = String(value);
      const isCnpj = valueAsString.length > 11;
      const mask = isCnpj ? '99.999.999/9999-99' : '999.999.999-99';

      return (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <InputMask
            mask={mask}
            value={valueAsString}
            readOnly
          >
            {(inputProps) => <input {...inputProps} style={{ border: 'none', outline: 'none' }} />}
          </InputMask>
        </div>
      );
    },
  },
  { Header: 'Hierarquia', accessor: 'matrix', Cell: ({ value }) => (value === 1 ? 'Matriz' : 'Filial') },
  // { Header: 'Status', accessor: 'status', Cell: ({ value }) => (value === 1 ? 'Ativo' : 'Inativo') },
]
//People Columns
async function fetchCityName(cityId) {
  const token = localStorage.getItem('IBGEtoken');
  const response = await fetch(`${IBGECITIESSTATES_API}/search/code/${cityId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const data = await response.json();
  return data[0].name;
}

async function fetchStateName(stateId) {
  const token = localStorage.getItem('IBGEtoken');
  const response = await fetch(`${IBGESTATES_API}/search/code/${stateId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const data = await response.json();
  return data[0].sigla;
}

const CityCell = ({ value }) => {
  const [CityName, setCityName] = useState('');

  useEffect(() => {
    fetchCityName(value)
      .then(CityName => setCityName(CityName))
      .catch(error => console.error('Error fetching city name:', error));
  }, [value]);

  return <span>{CityName}</span>;
};

const StateCell = ({ value }) => {
  const [stateName, setStateName] = useState('');

  useEffect(() => {
    fetchStateName(value)
      .then(stateName => setStateName(stateName))
      .catch(error => console.error('Error fetching state name:', error));
  }, [value]);

  return <span>{stateName}</span>;
};

export const PeopleColumns = [
  { Header: 'Nome', accessor: 'name' },
  { Header: 'Email', accessor: 'email' },
  {
    Header: 'Telefone', accessor: 'phone_1',
    Cell: ({ value }) => {
      const valueAsString = String(value);
      const isCellphone = valueAsString.length > 8;
      const mask = isCellphone ? '9 9999-9999' : '9999-9999';

      return (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <InputMask
            mask={mask}
            value={valueAsString}
            readOnly
          >
            {(inputProps) => <input {...inputProps} style={{ border: 'none', outline: 'none' }} />}
          </InputMask>
        </div>)
    }
  },
  { Header: 'Cidade', accessor: 'city', Cell: CityCell },
  { Header: 'Estado', accessor: 'state', Cell: StateCell },
  {
    Header: 'CPF/CNPJ',
    accessor: 'document',
    Cell: ({ value }) => {
      const valueAsString = String(value);
      const isCnpj = valueAsString.length > 11;
      const mask = isCnpj ? '99.999.999/9999-99' : '999.999.999-99';

      return (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <InputMask
            mask={mask}
            value={valueAsString}
            readOnly
          >
            {(inputProps) => <input {...inputProps} style={{ border: 'none', outline: 'none' }} />}
          </InputMask>
        </div>
      );
    },
  },
  { Header: 'Tipo', accessor: 'type_of_person', Cell: ({ value }) => (value === 1 ? 'Física' : 'Jurídica') },
  { Header: 'Status', accessor: 'status', Cell: ({ value }) => (value === 1 ? 'Ativo' : 'Inativo') },
];


async function fetchNameTrail(id) {
  const token = localStorage.getItem('token');
  const response = await fetch(`${TRAIL_API}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const data = await response.json();
  return data.title;
}

const NameTrailCell = ({ value }) => {
  const [TrailName, setTrailName] = useState('');

  useEffect(() => {
    fetchNameTrail(value)
      .then(TrailName => setTrailName(TrailName))
      .catch(error => console.error('Error fetching  name:', error));
  }, [value]);

  return <span>{TrailName}</span>;
};

//COURSE COLUMNS
// async function fetchNamePerson(id) {
//   const token = localStorage.getItem('token');
//   const response = await fetch(`${PEOPLE_API}/${id}`, {
//     headers: {
//       Authorization: `Bearer ${token}`
//     }
//   });
//   const data = await response.json();
//   return data.name;
// }

// const NamePersonCell = ({ value }) => {
//   const [NamePerson, setNamePerson] = useState('');

//   useEffect(() => {
//     fetchNamePerson(value)
//       .then(NamePerson => setNamePerson(NamePerson))
//       .catch(error => console.error('Error fetching  name:', error));
//   }, [value]);

//   return <span>{NamePerson}</span>;
// };

export const CourseCollumns = [
  { Header: 'Curso', accessor: 'title', },
  // { Header: 'Responsável', accessor: 'people_id', Cell: NamePersonCell },
  { Header: 'Trilha', accessor: 'trails_id', Cell: NameTrailCell },
  { Header: 'Status', accessor: 'status', Cell: ({ value }) => (value === 1 ? 'Ativo' : 'Inativo') },
]


//TRAIL COLUMNS

async function fetchNameCourse(id) {
  const token = localStorage.getItem('token');
  const response = await fetch(`${COURSE_API}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const data = await response.json();
  return data.title;
}

const NameCourseCell = ({ value }) => {
  const [NameCourse, setNameCourse] = useState('');

  useEffect(() => {
    fetchNameCourse(value)
      .then(NameCourse => setNameCourse(NameCourse))
      .catch(error => console.error('Error fetching  name:', error));
  }, [value]);

  return <span>{NameCourse}</span>;
};

export const TrailCollumns = [
  { Header: 'Trilha', accessor: 'title' },
  // { Header: 'Responsável', accessor: 'people_id', Cell: NamePersonCell },
  // { Header: 'Curso', accessor: 'curse_id', Cell: NameCourseCell },
  { Header: 'Status', accessor: 'status', Cell: ({ value }) => (value === 1 ? 'Ativo' : 'Inativo') },
]


//CATEGORY COLUMNS

export const CategoryCollumns = [
  { Header: 'Categoria', accessor: 'description' },
  // { Header: 'Responsável', accessor: 'people_id', Cell: NamePersonCell },
  // { Header: 'Curso', accessor: 'curse_id', Cell: NameCourseCell },
]


//MODULE COLUMNS



export const ModuleColumns = [
  { Header: 'Módulo', accessor: 'title' },
  // { Header: 'Responsável', accessor: 'people_id', Cell: NamePersonCell },
  { Header: 'Curso', accessor: 'courses_id', Cell: NameCourseCell },
  // { Header: 'Trilha', accessor: 'trails_id', Cell: NameTrailCell },
  { Header: 'Status', accessor: 'status', Cell: ({ value }) => (value === 1 ? 'Ativo' : 'Inativo') },
]

//LESSON COLUMNS

async function fetchNameModule(id) {
  const token = localStorage.getItem('token');
  const response = await fetch(`${MODULE_API}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const data = await response.json();
  return data.title;
}

const NameModuleCell = ({ value }) => {
  const [ModuleName, setModuleName] = useState('');

  useEffect(() => {
    fetchNameModule(value)
      .then(ModuleName => setModuleName(ModuleName))
      .catch(error => console.error('Error fetching  name:', error));
  }, [value]);

  return <span>{ModuleName}</span>;
};

export const LessonColumns = [
  { Header: 'Aula', accessor: 'title' },
  // { Header: 'Responsável', accessor: 'people_id', Cell: NamePersonCell },
  { Header: 'Módulo', accessor: 'module_id', Cell: NameModuleCell },
  { Header: 'Status', accessor: 'status', Cell: ({ value }) => (value === 1 ? 'Ativo' : 'Inativo') },
]


//ASSESMENT COLUMNS

async function fetchNameLesson(id) {
  const token = localStorage.getItem('token');
  const response = await fetch(`${LESSON_API}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const data = await response.json();
  return data.title;
}

const NameLessonCell = ({ value }) => {
  const [LessonName, setLessonName] = useState('');

  useEffect(() => {
    fetchNameLesson(value)
      .then(LessonName => setLessonName(LessonName))
      .catch(error => console.error('Error fetching  name:', error));
  }, [value]);

  return <span>{LessonName}</span>;
};

export const AssesmentColumns = [
  { Header: 'Avaliação', accessor: 'title' },
  // { Header: 'Responsável', accessor: 'people_id', Cell: NamePersonCell },
  { Header: 'Curso', accessor: 'courses_id', Cell: NameCourseCell },
  // { Header: 'Status', accessor: 'status', Cell: ({ value }) => (value === 1 ? 'Ativo' : 'Inativo') },
]




