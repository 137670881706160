export const CompanyFields = [
  { name: 'razao_social', type: 'text', placeholder: 'Razão Social *', width: 'calc(97% - 7.5px)',required:true },
  { name: 'fantasy_name', type: 'text', placeholder: 'Nome Fantasia', width: 'calc(97% - 7.5px)' },
  { name: 'email', type: 'email', placeholder: 'Email', width: 'calc(58.5% - 7.5px)' },
  { name: 'cnpj', type: 'text', placeholder: 'CNPJ *', width: 'calc(38% - 7.5px)', mask: '99.999.999/9999-99',required:true },
  { name: 'dd_phone', type: 'text', placeholder: 'DDD *', width: 'calc(10% - 7.5px)', mask: '99',required:true },
  { name: 'phone', type: 'text', placeholder: 'Telefone *', width: 'calc(25% - 7.5px)', mask: '9999-9999',required:true },
  { name: 'dd_cell_phone', type: 'text', placeholder: 'DDD *', width: 'calc(10% - 7.5px)', mask: '99' },
  { name: 'cell_phone', type: 'text', placeholder: 'Celular *', width: 'calc(25% - 7.5px)', mask: '99999-9999',required:true },
  { name: 'zip_code', type: 'text', placeholder: 'CEP', width: 'calc(24.5% - 7.5px)', mask: '99999-999' },
  { name: 'address', type: 'text', placeholder: 'Logradouro', width: 'calc(47% - 7.5px)' },
  { name: 'number', type: 'text', placeholder: 'Número *', width: 'calc(10% - 7.5px)',required:true },
  { name: 'district', type: 'text', placeholder: 'Bairro', width: 'calc(39% - 7.5px)' },
  { name: 'city', type: 'text', placeholder: 'Cidade', width: 'calc(29% - 7.5px)', disabled: true  },
  { name: 'state', type: 'text', placeholder: 'UF', width: 'calc(15% - 7.5px)', disabled: true  },
];
