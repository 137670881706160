import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import testImage from '../../img/test.png'
import { COURSE_API, GETMODULEBYTRAIL_API, GETLESSONBYMODULE_API, EVALUATION_API } from '../../apiEndPoints';

function ModulesList() {
  const { id: IDGet } = useParams();
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState(null);
  const [modules, setModules] = useState([]);
  const [evaluation, setEvaluation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${COURSE_API}/${IDGet}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setCourses(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchCourses();
  }, [IDGet]);

  useEffect(() => {
    const fetchModulesAndLessons = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${GETMODULEBYTRAIL_API}/${IDGet}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const filteredModulesStatus = response.data.filter(module => module.status == 1);

        const modulesWithLessons = await Promise.all(filteredModulesStatus.map(async (module) => {
          const lessonsResponse = await axios.get(`${GETLESSONBYMODULE_API}/${module.id}`, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
          const lessons = lessonsResponse.data;
          return { ...module, firstLessonId: lessons.length > 0 ? lessons[0].id : null, hasLessons: lessons.length > 0 };
        }));

        // Filtrar módulos que têm aulas
        const filteredModules = modulesWithLessons.filter(module => module.hasLessons);

        setModules(filteredModules);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchModulesAndLessons();
  }, [IDGet]);

  useEffect(() => {
    const fetchEvaluation = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(EVALUATION_API, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        // Filtrar avaliações do curso com status=1
        const courseEvaluations = response.data.filter(
          (evaluation) => evaluation.courses_id === parseInt(IDGet) && evaluation.status === 1
        );

        if (courseEvaluations.length > 0) {
          setEvaluation(courseEvaluations[0]);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEvaluation();
  }, [IDGet]);

  if (loading) {
    return (
      <div className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" variant="primary" style={{ width: '3rem', height: '3rem' }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {/* Categories Start */}
      <div className="container-xxl py-5 category">
        <div className="text-center">
          <p style={{ fontSize: '22px', fontWeight: 'bold' }}>{courses?.title}</p>
        </div>

        <div className="container">
          <h2>Módulos de {courses?.title}</h2>
          <ul className="list-group">
            {modules.map((module, index) => (
              <li key={index} className="list-group-item">
                <div className="media">
                  <a href={`/modules/${module.id}/lessons/${module.firstLessonId}`}>
                    <img src={module.arquivo} className="mr-3" alt="Imagem de exemplo" 
                      style={{
                        width: '180px',
                        height: '120px',
                        objectFit: 'cover',
                        objectPosition: 'center'
                      }} />
                  </a>
                  <div className="media-body">
                    <h5 className="mt-0">{module.title}</h5>
                    <div dangerouslySetInnerHTML={{ __html: module.summary_modules }} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* Categories End */}

      {/* Course Evaluation */}
      {evaluation && (
       <div className="container mt-5">
       <h2>Avaliação do Curso</h2>
       <div className="list-group-item">
         <div className="media">
           <a href={`/curso/modulos/avaliacao/${evaluation.id}`}>
             <img
               src={testImage}
               className="mr-3"
               alt="Avaliação"
               style={{
                 width: '180px',
                 height: '120px',
                 objectFit: 'cover',
                 objectPosition: 'center',
                 transition: 'transform 0.3s ease',
                 transform: 'scale(1)',
               }}
               onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
               onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
             />
           </a>
           <div className="media-body">
             <h5 className="mt-0">{evaluation.title}</h5>
             <p dangerouslySetInnerHTML={{ __html: evaluation.description }} />
           </div>
         </div>
       </div>
     </div>
     
      )}

      {/* Progress Bar */}
      {/* <div className="container-xxl py-5">
        <div className="progress">
          <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">0%</div>
        </div>
      </div> */}

      {/* Back to Top */}
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="fas fa-caret-up"></i></a>
    </div>
  );
}

export default ModulesList;
