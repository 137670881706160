import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import axios from 'axios';
import swal from 'sweetalert';
import { GROUP_API } from '../../apiEndPoints';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../vendor/fontawesome-free/css/all.min.css';
import '../../css/sb-admin-2.min.css';
import CircularIndeterminate from '../../components/LoadingCircle';

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  max-width: 75%;
  margin: 50px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 13em;
  width: 28em;
`;

const Container1 = styled.div`
  position: relative;
  max-width: 90%;
  padding: 0px;
  width: 22em;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  overflow: hidden;

  .card {
    flex-grow: 1;
    height: 100%;
  }

  .fa-bars {
    cursor: pointer;
    position: relative;
    right: 1px;
  }
`;

const FormContainer1 = styled.div`
display: flex
flex-direction: column
justify-content: center;
`
const FormContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
`;

const Form1 = styled.form`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  @media (max-width: 768px) {
    grid-template-columns: auto;
    margin: 0 auto;
  }
`;

const Input = styled.input`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

const Button4 = styled.button`
  background-color: transparent;
  color: ${theme.primaryColor};
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 27%;

  &:hover {
    border: solid 1px ${theme.primaryColor};
  }
  
`;

const Button3 = styled.button`
  background-color: ${theme.primaryColor};
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background: linear-gradient(90deg, ${theme.primaryColor} 35%, ${theme.secondaryColor} 85%);
  }

  align-self: left;
  margin-left: 0;
`;

const Button2 = styled.button`
  background-color: white;
  color: ${theme.primaryColor};
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
  margin-left: auto;
`;

const Button = styled.button`
  background-color: ${theme.primaryColor};
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(90deg, ${theme.primaryColor} 35%, ${theme.secondaryColor} 85%);
  }

  align-self: left;
  margin-left: 0;
`;

const Title = styled.h2`
  color: #333;
`;
const Title1 = styled.h2`
  font-size: 20px;
  color: #333;
`;

const Menu = styled.div`
  position: relative;
  display: ${(props) => (props.showMenu ? 'flex' : 'none')}; 
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const MenuItem = styled.div`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const AccessGroups = () => {

  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [menuGroup, setMenuGroup] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
  });
  const [existingGroups, setExistingGroups] = useState([]);
  const [token, setToken] = useState('');
  const [nextGroupId, setNextGroupId] = useState(1);
  const [showInactiveGroups, setShowInactiveGroups] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  
    const companyID = parseInt(localStorage.getItem('companyID')); 
  
    const fetchExistingGroups = async () => {
      try {
        const response = await axios.get(GROUP_API, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
  
        const groupsWithMatchingCompanyID = response.data.filter(group => group.company_id === companyID);
        const maxId = Math.max(...groupsWithMatchingCompanyID.map(group => Number(group.id)), 0);
        setNextGroupId(maxId + 1);
        setExistingGroups(groupsWithMatchingCompanyID);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar grupos existentes:', error);
        setLoading(false);
      }
    };
  
    fetchExistingGroups();
  }, []);

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        if (response.status !== 200) {
          return Promise.reject(response);
        }
        return response;
      },
      function (error) {
        const status = error.response ? error.response.status : null;
        if (status === 203) {
          swal({
            title: 'Erro!',
            text: 'Erro ao processar a operação.',
            icon: 'error',
            timer: 2000,
            buttons: false,
          });
        }
        return Promise.reject(error);
      }
    );
  }, []);


  const handleMenuToggle = (group) => {
    setMenuGroup((prevGroup) => (prevGroup === group ? null : group));
  };

  const handleCreateGroupToggle = () => {
    setIsCreatingGroup(!isCreatingGroup);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const companyID = parseInt(localStorage.getItem('companyID')); 

    if (!formData.name.trim()) {
      swal({
        title: 'Ops!',
        text: 'O campo Nome do Grupo é obrigatório.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      return;
    }

    try {
      const adjustedFormData = {
        name: formData.name,
        status: 1,
        company_id: companyID,
        id: nextGroupId.toString(),
      };

      const response = await axios.post(GROUP_API, adjustedFormData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      setExistingGroups([...existingGroups, response.data]);
      setFormData({ name: '' });
      setNextGroupId(nextGroupId + 1);

      swal({
        title: 'OK!',
        text: 'Grupo criado com sucesso!',
        icon: 'success',
        timer: 2000,
        buttons: false,
      });

      setIsCreatingGroup(false);
      // window.location.reload();
    } catch (error) {
      swal({
        title: 'Ops!',
        text: 'Erro ao criar grupo.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
    }
  };

  const handleCancelCreate = () => {
    setFormData({ name: '' });
    setIsCreatingGroup(false);
  };

  const handleEditGroup = async (group) => {
    const newName = await swal({
      text: 'Digite o novo nome do grupo:',
      content: 'input',
      button: {
        text: 'Salvar',
        closeModal: false,
      },
    });
  
    if (!newName) {
      return;
    }
  
    try {
  
      const updatedGroup = {
        ...group,
        name: newName,
      };

      console.log(updatedGroup)
     
      await axios.put(`${GROUP_API}/${group.id}`, updatedGroup, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      setExistingGroups((prevGroups) =>
        prevGroups.map((g) => (g.id === group.id ? updatedGroup : g))
      );
  
      swal({
        title: 'OK!',
        text: 'Grupo editado com sucesso!',
        icon: 'success',
        timer: 2000,
        buttons: false,
      });
    } catch (error) {
      console.error('Erro ao editar grupo:', error);
  
      swal({
        title: 'Ops!',
        text: 'Erro ao editar grupo.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
    }
  };

  const handleToggleGroupStatus = async (group) => {
    const newStatus = group.status === 1 ? 0 : 1;
  
    try {
      await axios.put(
        `${GROUP_API}/${group.id}`,
        { status: newStatus, name: group.name },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
        console.log(newStatus, 'sss')
  
      setExistingGroups((prevGroups) =>
        prevGroups.map((g) => (g.id === group.id ? { ...g, status: newStatus } : g))
      );
  
      swal({
        title: 'OK!',
        text: `Status do grupo "${group.name}" alterado com sucesso!`,
        icon: 'success',
        timer: 2000,
        buttons: false,
      });
    } catch (error) {
      console.error('Erro ao alterar status do grupo:', error);
  
      swal({
        title: 'Ops!',
        text: 'Erro ao alterar status do grupo.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
    }
  };

  if (loading) {
    return <Loading><CircularIndeterminate/></Loading>;
  }

  return (
    <FormContainer>
    <FormContainer1>
      <Container>
        <Title>Grupos de Acesso</Title>
        {isCreatingGroup ? (
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="name"
              placeholder="Nome do Grupo"
              value={formData.name}
              onChange={handleChange}
              fullWidth
            />
            <Button2 type="button" onClick={handleCancelCreate}>
              Cancelar
            </Button2>
            <Button type="submit">Criar</Button>
          </Form>
        ) : (
          <Button3 onClick={handleCreateGroupToggle}>
            Criar Novo Grupo de Usuário
          </Button3>
        )}
      </Container>
      <Button4 onClick={() => setShowInactiveGroups(!showInactiveGroups)}>
        {showInactiveGroups ? 'Ocultar Grupos Inativos' : 'Mostrar Grupos Inativos'}
      </Button4>
     </FormContainer1>
      <Form1>
        {existingGroups.map((group, index) => (
          (showInactiveGroups || group.status === 1) && (
            <Container1
              key={group.id}
              className={`mb-4 ${
                group.status === 0 ? 'inactive-group' : `border-left-${['primary', 'success', 'info', 'warning', 'danger'][index % 5]}`
              }`}
            >
              <div className="card shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        <Title1>{group.name}</Title1>
                      </div>
                    </div>
                    <div className="col-auto">
                      <i
                        className="fas fa-bars fa-2x text-gray-300"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMenuToggle(group);
                        }}
                      ></i>
                      <Menu showMenu={menuGroup === group}>
                        {menuGroup === group && (
                          <div>
                            <MenuItem onClick={() => handleEditGroup(group)}>Editar</MenuItem>
                            <MenuItem onClick={() => handleToggleGroupStatus(group)}>
                              {group.status === 1 ? 'Desativar' : 'Ativar'}
                            </MenuItem>
                          </div>
                        )}
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </Container1>
          )
        ))}
      </Form1>
    </FormContainer>
  );
};

export default AccessGroups;