import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { Link } from 'react-router-dom';
import SearchInput from '../../../components/SearchInput';
import CircularIndeterminate from '../../../components/LoadingCircle';
import { USER_API, GROUP_API } from '../../../apiEndPoints';
import InputMask from 'react-input-mask';
import { AddNewButton, EditButton, FirstButton, LastButton, NextButton, PrevButton } from '../../../components/Buttons';
import { Title } from '../../../components/General';


const TableWrapper = styled.div`
  padding: 10px;
  max-width: 90%;
  margin: auto;
  margin-top: 20px;

  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      border: 1px solid #ddd;
      padding: 8px;
    }

    tbody tr {
      transition: background-color 0.3s; 

      &:hover {
        background-color: #f5f5f5;
        cursor: pointer;
      }
    }

    tbody td:hover {
      background-color: #f5f5f5;
    }
  }
`;

const FilterWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  margin-left: 9rem;
`;

const Input = styled.div`
  margin-left: auto;
  // margin-right: 10rem;
`;

const Select = styled.select`
  margin-left: 5px;
  border-radius: 10px;
  padding: 2px 0 2px 2px;
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerHeader = styled.div`
   display: flex;
  align-items: center;
  justify-content: start;
  margin: 0 6rem;
  gap: 0 20px;
`;

const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [token, setToken] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');



  const fetchData = useCallback(
    async ({ pageIndex }) => {
      try {
        const response = await axios.get(USER_API, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: pageIndex,
            pageSize: 10,
          },
        });
        
        setUsers(response.data);
        setTotalPages(Math.ceil(response.headers['x-total-count'] / 10));
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
      }
    },
    [token, USER_API]
    );

  const getGroupName = useCallback(
    (groupId) => {
      const group = groups.find((group) => group.id === groupId);
      return group ? group.name : 'sem grupo';
    },
    [groups]
  );
 
  const filteredUsers = useMemo(
    () =>
      users.filter((user) => {
        const statusCondition =
          statusFilter === 'all' || user.status === (statusFilter === 'active' ? 1 : 0);
  
        const searchCondition =
          Object.values(user).some((value) =>
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
          );
  
        return statusCondition && searchCondition;
      }),
    [users, statusFilter, searchTerm]
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    setGlobalFilter,
    headerGroups,
    page,
    prepareRow,
    state: { pageSize, globalFilter, sortBy }, 
    gotoPage,
    canPreviousPage,
    previousPage,
    nextPage,
    canNextPage,
    setOrderBy, 
    setSortBy,  


    rows,
    globalFilter: tableGlobalFilter, 
  } = useTable(
    {
      columns: useMemo(
        () => [
          { Header: 'Nome', accessor: 'name' },
          { Header: 'Email', accessor: 'email' },
          {
            Header: 'Telefone',
            accessor: 'fone',
            Cell: ({ value }) => {
              const valueAsString = String(value);
              const isCnpj = valueAsString.length > 13;
              const mask = isCnpj ? '(99) 9 9999-9999' : '(99) 9999-9999';
        
              return (
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <InputMask
                    mask={mask}
                    value={valueAsString}
                    readOnly
                  >
                    {(inputProps) => <input {...inputProps} style={{ border: 'none', outline: 'none' }} />}
                  </InputMask>
                </div>
              );
            },
          },
          { Header: 'Cidade', accessor: 'city' },
          { Header: 'Estado', accessor: 'state' },
          // { Header: 'Grupo', accessor: 'group_id', Cell: ({ value }) => getGroupName(value) },
          {
            Header: 'CPF',
            accessor: 'cpf',
            Cell: ({ value }) => {
              const valueAsString = String(value);
              const isCnpj = valueAsString.length > 11;
              const mask = isCnpj ? '99.999.999/9999-99' : '999.999.999-99';
          
              return (
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <InputMask
                    mask={mask}
                    value={valueAsString}
                    readOnly
                  >
                    {(inputProps) => <input {...inputProps} style={{ border: 'none', outline: 'none' }} />}
                  </InputMask>
                </div>
              );
            },
          },
          { Header: 'Status', accessor: 'status', Cell: ({ value }) => (value === 1 ? 'Ativo' : 'Inativo') },
          {
            Header: 'Ação',
            accessor: 'id',
            Cell: ({ value }) => (
              <Link to={`/edituser/${value}`}>
                <EditButton/>
              </Link>
            ),
          },
        ],
        [getGroupName]
      ),
      data: filteredUsers,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );

  const handleSort = (column) => {
    const isAsc = sortBy[0]?.id === column.id && sortBy[0]?.desc === false;
    const newSortBy = [{ id: column.id, desc: isAsc }];

    setSortBy(newSortBy);
    setOrderBy([{ columnId: column.id, direction: isAsc ? 'asc' : 'desc' }]);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  useEffect(() => {
    fetchData({ pageIndex });
  }, [fetchData, pageIndex]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(GROUP_API, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setGroups(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar grupos:', error);
        setLoading(false);
      }
    };

    fetchGroups();
  }, [token]);


  if (loading) {
    return <Loading><CircularIndeterminate/></Loading>;
  }

  return (
    <div>
      <FilterWrapper>
        <label>
          Filtrar por Status:
          <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <option value="all">Todos</option>
            <option value="active">Ativos</option>
            <option value="inactive">Inativos</option>
          </Select>
        </label>
        
      </FilterWrapper>

    

      <ContainerHeader>
        <Title text={'Usuários'} />
        <AddNewButton 
          as={Link} to={"/cadastro/register"}
        />

        <Input>
          <SearchInput
            customStyle={true}
            searchTerm={globalFilter || ''}
            setSearchTerm={(value) => setGlobalFilter(value)}
          />
        </Input>
      </ContainerHeader>

      <TableWrapper>
        <table {...getTableProps()} className="table table-bordered" id="userDataTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    onClick={() => column.canSort && handleSort(column)}
                    style={{ cursor: column.canSort ? 'pointer' : 'default' }}
                  >
                    {column.render('Header')}
                    {column.canSort && (
                      <span>
                        {sortBy[0]?.id === column.id && (sortBy[0]?.desc ? ' ↓' : ' ↑')}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableWrapper>

      <ContainerPagination>
      <Pagination>
        <span style={{ display: 'none' }}>
          Página <strong>{pageIndex + 1}</strong>{' '}
        </span>
    
        <PrevButton onClick={() => previousPage()} disabled={!canPreviousPage}
        />
        <NextButton onClick={() => nextPage()} disabled={!canNextPage || pageIndex === totalPages - 1}
        />
   
      </Pagination>
      
      </ContainerPagination>
    </div>
  );
};


export default UserList;
