import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import axios from 'axios';
import InputMask from 'react-input-mask';
import swal from 'sweetalert';
import { PEOPLE_API, IBGECITIESSTATES_API, CNAE_API, CNAEPEOPLE_API, CNPJ_API, PEOPLEDOCUMENTS_API } from '../../../apiEndPoints';
import { useNavigate, Link  } from 'react-router-dom';
import { AddNewButton, BackButton, SaveButton, TabButton } from '../../../components/Buttons';
import { PersorRegisterFormData } from '../../../components/Formdatas';
import { camposPessoaFisica, camposPessoaJuridica } from '../../../components/Fields/PeopleFields';
import { Container, Container1, FormContainer, Form, ResponsiveDiv, Label, Buttons, TabContainer, List, ListItem } from '../../../components/FormContainers';
import { Title } from '../../../components/General';
import Checkbox from '../../../components/Checkboxes';
import PersonDocumentsRegister from '../PeopleDocumentsRegister/PersonDocumentsRegister';

const Input = styled(InputMask)`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const PersonRegistration = () => {
  const [formData, setFormData] = useState(PersorRegisterFormData);
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState('');
  const [selectedStateId, setSelectedStateId] = useState('');
  const [activeTab, setActiveTab] = useState('people');
  const [selectedType, setSelectedType] = useState(''); 


  const [token, setToken] = useState('');
  const [status, setStatus] = useState(1);
  const navigate = useNavigate();
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [numPersonDocumentsRegister, setPersonDocumentsRegister] = useState(1);

  const [showDocumentsTab, setShowDocumentsTab] = useState(false);

  const storedCompanyId = parseInt(localStorage.getItem('companyID'));

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
})
  
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(IBGECITIESSTATES_API);
        setCities(response.data);
      } catch (error) {
        console.error('Erro ao buscar cidades:', error);
      }
    };

    fetchCities();
  }, []);

  const handleCNPJBlur = async (value) => {
    const cleanCNPJ = value.replace(/[^\d]+/g, '');
    if (cleanCNPJ.length >= 11 && cleanCNPJ.length <= 14) {
      try {
        const response = await axios.get(`${CNPJ_API}${cleanCNPJ}`);
        console.log(response)

        if (response.data.erro) {
          swal({
            title: 'Ops!',
            text: 'CPF/CNPJ inválido. Verifique o CPF/CNPJ informado.',
            icon: 'error',
            timer: 2000,
            buttons: false,
          });
          resetFormData();
        } else {

          const { email, razao_social, nome_fantasia, ddd_telefone_1, ddd_telefone_2 } = response.data;
          const ddd1 = ddd_telefone_1.slice(0, 2);
          const phone1 = ddd_telefone_1.slice(2);
          const ddd2 = ddd_telefone_2.slice(0, 2);
          const phone2 = ddd_telefone_2.slice(2);

          setFormData((prevData) => ({
            ...prevData,
            corporate_name: razao_social || prevData.corporate_name,
            email: email || prevData.email,
            fantasy_name: nome_fantasia || prevData.fantasy_name,
            dd_1: ddd1 || prevData.dd_1,
            phone_1: phone1 || prevData.phone_1,
            dd_2: ddd2 || prevData.dd_2,
            phone_2: phone2 || prevData.phone_2,
          }));
        }
      } catch (error) {
        console.error('Erro ao buscar CNPJ:', error);
      }
    } else {
      swal({
        title: 'Ops!',
        text: 'CPF/CNPJ inválido. Verifique o CPF/CNPJ informado.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      resetFormData();
    }
  };

  const resetFormData = () => {
    setFormData((prevData) => ({
      ...prevData,
      corporate_name: '',
      email: '',
      fantasy_name: '',
      dd_1: '',
      phone_1: '',
      dd_2: '',
      phone_2: '',
    }));
  };

  useEffect(() => {
    const cnpjInput = document.querySelector('input[name="document"]');
    const handleBlur = () => handleCNPJBlur(cnpjInput.value);
    cnpjInput.addEventListener('blur', handleBlur);
    return () => {
      cnpjInput.removeEventListener('blur', handleBlur);
    };
  }, []);

  const handleCepBlur = async (value) => {
    if (value.length === 9) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${value}/json/`);

        if (response.data.erro) {
          swal({
            title: 'Ops!',
            text: 'CEP inválido. Verifique o CEP informado.',
            icon: 'error',
            timer: 2000,
            buttons: false,
          });
          setFormData((prevData) => ({
            ...prevData,
            zip_code: '',
            address: '',
            district: '',
            city: '',
            state: '',
          }));
        } else {
          const { logradouro, bairro, cep, localidade, uf } = response.data;
          const ibgeToken = localStorage.getItem('IBGEtoken');
          const ibgeResponse = await axios.get(`${IBGECITIESSTATES_API}`, {
            headers: {
              Authorization: `Bearer ${ibgeToken}`,
            },
          });

          const cities = ibgeResponse.data;
          const selectedCity = cities.find((city) => city.name.toLowerCase() === localidade.toLowerCase());

          if (selectedCity) {
            setFormData((prevData) => ({
              ...prevData,
              zip_code: cep,
              address: logradouro,
              district: bairro,
              city: localidade,
              state: uf,
            }));

            setSelectedCityId(selectedCity.code);
            const stateId = selectedCity.state[0].code;
            setSelectedStateId(stateId);
          } else {
            setFormData((prevData) => ({
              ...prevData,
              zip_code: cep,
              address: logradouro,
              district: bairro,
              city: '',
              state: uf,
            }));
          }
        }
      } catch (error) {
        console.error('Erro ao buscar CEP:', error);
      }
    } else {
      swal({
        title: 'Ops!',
        text: 'CEP inválido. Verifique o CEP informado.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      setFormData((prevData) => ({
        ...prevData,
        zip_code: '',
        address: '',
        district: '',
        city: '',
        state: '',
      }));
    }
  };

  useEffect(() => {
    const cepInput = document.querySelector('input[name="zip_code"]');
    const handleBlur = () => handleCepBlur(cepInput.value);
    cepInput.addEventListener('blur', handleBlur);
    return () => {
      cepInput.removeEventListener('blur', handleBlur);
    };
  }, []);

  

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'status') {
      setStatus(parseInt(value, 10));
      setFormData({ ...formData, status: parseInt(value, 10) });
    } else if (name === 'zip_code') {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (loading) {
      return;
    }
  
    setLoading(true);
  
    const userId = localStorage.getItem('userId');
    const camposComMascara = ['document', 'rg', 'zip_code', 'state_registration', 'phone_1', 'phone_2'];
  
    const cleanFormData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => {
        if (typeof value === 'string' && camposComMascara.includes(key)) {
          const cleanedValue = value.replace(/\D/g, '');
          return [key, cleanedValue];
        }
        return [key, value];
      })
    );
  
    const updatedFormData = {
      ...cleanFormData,
      city: selectedCityId,
      state: selectedStateId,
      user_id: userId,
      status: status,
      type_people:selectedType,
    };
  
    try {
      const response = await axios.post(
        PEOPLE_API,
        { ...updatedFormData, company_id: storedCompanyId },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        const PeopleID = response.data.id;
        localStorage.setItem('peopleID', PeopleID);
        swal({
          title: 'OK!',
          text: 'Usuário cadastrado com sucesso!',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        setFieldsDisabled(true);
        // navigate('/cadastro/pessoa');
      } else {
        swal({
          title: 'Ops!',
          text: 'Erro ao associar pessoa à uma ou mais CNAE',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Erro ao processar o formulário:', error);
  
      if (error.response && error.response.status === 409) {
        swal({
          title: 'Ops!',
          text: 'Pessoa já cadastrada no sistema',
          icon: 'warning',
          timer: 2000,
          buttons: false,
        });
      } else {
        swal({
          title: 'Ops!',
          text: 'Erro ao cadastrar pessoa.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    }
  
    const companyID = localStorage.getItem('companyID');
    const peopleID = localStorage.getItem('peopleID');
  
    const updatedFormdata = {
      ...formData,
      user_id: userId,
      company_id: companyID,
      people_id: peopleID,
      observation: `documentos ${peopleID}`,
    };
  
    try {
      const response = await axios.post(
        PEOPLEDOCUMENTS_API,
        updatedFormdata,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 201 || response.status === 200) {
        swal({
          title: 'OK!',
          text: 'Cadastrado com sucesso',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        localStorage.setItem('PDId', response.data.id);
        setShowDocumentsTab(true);
        setFieldsDisabled(true);
        setFormData(updatedFormdata);
        setActiveTab('Documentos');
        localStorage.removeItem('peopleID');
      } else {
        swal({
          title: 'Ops!',
          text: 'Algo deu errado, tente novamente.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Erro ao processar o formulário:', error);
    }
  
    setLoading(false);
  };
  
      // const getPhoneMask = (value) => {
      //   return value.length === 9 ? '9 9999-9999' : '9999-9999';
      // };

      const camposAtuais = [
        ...camposPessoaFisica,
        {
        name: 'type_people',
        type: 'select',
        placeholder: 'Tipo',
        fullWidth: true,
        width: 'calc(20% - 7.5px)',
        value: selectedType,
        onChange: (e) => setSelectedType(e.target.value),
        options: [
          { value: 1, label: 'Professor' },
          { value: 0, label: 'Aluno' }
        ]
      },]


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  }

  return (
    <div>
      <FormContainer>
        <TabContainer>
          <TabButton
            active={activeTab === 'people'}
            onClick={() => handleTabChange('people')}
          >
            Dados da Pessoa
          </TabButton>
          {showDocumentsTab && (
            <TabButton
              active={activeTab === 'Documentos'}
              onClick={() => handleTabChange('Documentos')}
            >
              Documentos
            </TabButton>
          )}
        </TabContainer>
        <Container>
          <Title text={'Cadastro Pessoa'} backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'} />
          <Container1>
            <Form onSubmit={handleSubmit}>
              {activeTab === 'people' && (
                <>
                  {camposAtuais.map((campo) => (
                    <ResponsiveDiv key={campo.name} width={campo.width}>
                    {campo.type === 'select' ? (
                      <Select
                        name={campo.name}
                        value={campo.value}
                        onChange={campo.onChange}
                        style={{ width: '100%' }}
                      >
                        <option value="" style={{ color: '#999' }} disabled>
                          {campo.placeholder}
                        </option>
                        {campo.options.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        id={campo.name}
                        type={campo.type}
                        name={campo.name}
                        value={formData[campo.name]}
                        onChange={campo.onChange || handleChange}
                        mask={campo.mask}
                        maskChar={null}
                        required
                        disabled={campo.disabled}
                        fullWidth={campo.fullWidth}
                        style={{ width: '100%' }}
                      />
                    )}
                    <Label htmlFor={campo.name}>
                      {campo.placeholder}
                    </Label>
                    </ResponsiveDiv>
                ))}
                   <Select
                    name="status"
                    value={status.toString()}
                    onChange={handleChange}
                    style={{ width: 'calc(16% - 7.5px)' }}
                    disabled={fieldsDisabled}
                  >
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                  </Select>
                </>
              )}
              {activeTab !== 'Documentos' && (
                <Buttons>
                  <BackButton as={Link} to="/cadastros/pessoa" />
                  <SaveButton onClick={handleSubmit} disabled={loading} />
                </Buttons>
              )}
            </Form>
            {showDocumentsTab && activeTab === 'Documentos' && (
              <>
              <Title text={'Documentos'}/>
                {Array.from({ length: numPersonDocumentsRegister }).map((_, index) => (
                  <PersonDocumentsRegister key={index} />
                ))}
                <FormContainer paddingWrap={'0px'}>
                  <AddNewButton onClick={() => setPersonDocumentsRegister(numPersonDocumentsRegister + 1)} />
                </FormContainer>
              </>
            )}
          </Container1>
        </Container>
      </FormContainer>
    </div>
  );
};

export default PersonRegistration;