import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { COURSE_API, ME_API } from '../../../apiEndPoints'; // Substitua pelo seu endpoint da API de cursos

const CompletionContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  padding: 20px;
`;

const DiplomaWrapper = styled.div`
  width: 800px;
  padding: 40px;
  border: 5px solid #d4af37; /* Ouro */
  background-color: #fff;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const DiplomaTitle = styled.h1`
  font-family: 'Georgia', serif;
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
`;

const DecorativeLine = styled.div`
  height: 4px;
  width: 200px;
  background-color: #d4af37;
  margin: 0 auto 20px auto;
`;

const CourseTitle = styled.h2`
  font-family: 'Georgia', serif;
  font-size: 28px;
  color: #555;
  margin-bottom: 20px;
`;

const DiplomaText = styled.p`
  font-family: 'Times New Roman', serif;
  font-size: 18px;
  color: #333;
  margin-bottom: 30px;
`;

const DiplomaSignature = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

const SignatureText = styled.p`
  font-family: 'Cursive', sans-serif;
  font-size: 16px;
  color: #333;
  margin-top: 20px;
`;

const SignatureLine = styled.div`
  width: 200px;
  height: 1px;
  background-color: #333;
  margin: 10px auto 5px auto;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    background-color: #0056b3;
  }
`;

const CourseCompletionPage = () => {
  const { id } = useParams(); 
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [courseTitle, setCourseTitle] = useState(''); 
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const fetchCourseDetails = async () => {
      try {
        const response = await axios.get(`${COURSE_API}/${id}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setCourseTitle(response.data.title); 
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar detalhes do curso:', error);
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [id]);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const fetchUserData = async () => {
      try {
        const response = await fetch(ME_API, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserName(data.name);

        } else {
          console.error('Erro ao buscar dados do usuário');
        }
      } catch (error) {
        console.error('Erro na chamada da API:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleBackToModules = () => {
    navigate(`/curso/modulos/${id}`); 
  };

  if (loading) {
    return <CompletionContainer>Carregando...</CompletionContainer>; 
  }

  return (
    <CompletionContainer>
      <DiplomaWrapper>
        <DiplomaTitle>Certificado de Conclusão</DiplomaTitle>
        <DecorativeLine />
        <CourseTitle>{courseTitle}</CourseTitle> 
        
        <DiplomaText>
          Este certificado é concedido a <strong>{userName}</strong> pela conclusão bem-sucedida do curso 
          <strong> {courseTitle}</strong>. Parabenizamos o esforço e dedicação demonstrados ao longo de 
          suas atividades e esperamos que os conhecimentos adquiridos aqui o ajudem em seus futuros desafios.
        </DiplomaText>
        
        <DiplomaText>Data de conclusão: {new Date().toLocaleDateString()}</DiplomaText>

        {/* <DiplomaSignature>
          <SignatureLine />
        </DiplomaSignature> */}
        
        <Button onClick={handleBackToModules}>Voltar aos Módulos</Button>
      </DiplomaWrapper>
    </CompletionContainer>
  );
};

export default CourseCompletionPage;
