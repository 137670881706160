import React from "react";
import styled from "styled-components";


const StyledTitle = styled.h2`
    padding: ${props => props.padding || '5px'};
    font-size: ${props => props.fontSize};
    background-color: ${props => props.backgroundColor || 'transparent'};
    border: none;
    border-radius: 5px;
    color: ${props => props.letteringColor || '#333'};
`;


export const Title = ({ text, fontSize, backgroundColor, color, padding }) => {
    return (
      <StyledTitle
        fontSize={fontSize}
        backgroundColor={backgroundColor} 
        color={color} 
        padding={padding}
      >
        {text}
      </StyledTitle>
    );
  };