import React from "react";
import { COURSELIST_API} from "../../../apiEndPoints";
import theme from "../../../theme";
import Lists from "../../../components/Lists";
import { CourseCollumns } from "../../../components/TableColumns";


const CourseList = () => {
  const token = localStorage.getItem('token');

  return(
    <div>
      <Lists
      title={'Cursos'}
      api={COURSELIST_API}
      buttonColor={theme.primaryColor}
      addURL={`/cadastro/courseregister`}
      editURL={`/editcourse`}
      filterdisplay='block'
      fields={CourseCollumns}
      token={token}
      applyCompanyIdFilter={true}
      />
    </div>
  )
}

export default CourseList