import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import axios from 'axios';
import InputMask from 'react-input-mask';
import swal from 'sweetalert';
import { CNAEPEOPLECONSULT_API, CNAEPEOPLEDELETE_API, CNAEPEOPLE_API, CNAE_API, CNPJ_API, IBGECITIESSTATES_API, IBGESTATES_API, PEOPLE_API } from '../../../apiEndPoints';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { BackButton, SaveButton, TabButton } from '../../../components/Buttons';
import { EditPersonData } from '../../../components/Formdatas';
import { camposEditPessoaFisica, camposEditPessoaJuridica, camposPessoaFisica, fieldsForCompany, fieldsForPerson, maskedFields } from '../../../components/Fields/PeopleFields';
import { Container, Container1, FormContainer, Form, ResponsiveDiv, Label, Buttons, TabContainer , List, ListItem} from '../../../components/FormContainers';
import { Title } from '../../../components/General';
import Checkbox from '../../../components/Checkboxes';
import EditPeopleDocuments from '../EditPeopleDocuments';

const Input = styled(InputMask)`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%;
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  display: none;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

const Active = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  min-width: 100px;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%;
  }
`;

const EditPerson = ({ match }) => {
  const { id: personId } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(EditPersonData);
  const [loading, setLoading] = useState(false);
  const [selectedCityId, setSelectedCityId] = useState('');
  const [selectedStateId, setSelectedStateId] = useState('');
  const [originalCityId, setOriginalCityId] = useState(''); 
  const [originalStateId, setOriginalStateId] = useState(''); 
  const [selectedType, setSelectedType] = useState(''); 
  const [token, setToken] = useState('');
  const [IBGEtoken, setibgeToken] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [activeTab, setActiveTab] = useState('people');
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }

    const ibgeToken = localStorage.getItem('IBGEtoken');
    if (ibgeToken) {
      setibgeToken(ibgeToken);
    }

    const fetchPersonData = async () => {
      try {
        const response = await axios.get(`${PEOPLE_API}/${personId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
  
        setFormData(response.data);
        setSelectedStatus(response.data.status);
        setOriginalCityId(response.data.city); 
        setOriginalStateId(response.data.state);
  
        const cityResponse = await axios.get(`${IBGECITIESSTATES_API}/search/code/${response.data.city}`, {
          headers: {
            Authorization: `Bearer ${ibgeToken}`,
          },
        });
        const stateResponse = await axios.get(`${IBGESTATES_API}/search/code/${response.data.state}`, {
          headers: {
            Authorization: `Bearer ${ibgeToken}`,
          },
        });

        const cityName = cityResponse.data[0] ? cityResponse.data[0].name : null;
        const stateAbbr = stateResponse.data[0] ? stateResponse.data[0].sigla : null;
 
        setFormData(prevData => ({
          ...prevData,
          city: cityName,
          state: stateAbbr
        }
        ))
  } catch (error) {
      console.error('Erro ao buscar informações de pessoa:', error);
    }
}
  
    fetchPersonData();
  }, [personId]);


  const handleCNPJBlur = async (value) => {
    const cleanCNPJ = value.replace(/[^\d]+/g, '');
    if (cleanCNPJ.length >= 11 && cleanCNPJ.length <= 14) {
      try {
        const response = await axios.get(`${CNPJ_API}${cleanCNPJ}`);

  
        if (response.data.erro) {
          swal({
            title: 'Ops!',
            text: 'CPF/CNPJ inválido. Verifique o CPF/CNPJ informado.',
            icon: 'error',
            timer: 2000,
            buttons: false,
          });
          resetFormData();
        } else {
          const { email, razao_social, nome_fantasia, ddd_telefone_1, ddd_telefone_2 } = response.data;
          const ddd1 = ddd_telefone_1.slice(0, 2);
          const phone1 = ddd_telefone_1.slice(2);
          const ddd2 = ddd_telefone_2.slice(0, 2);
          const phone2 = ddd_telefone_2.slice(2);
          setFormData((prevData) => ({
            ...prevData,
            corporate_name: razao_social || prevData.corporate_name,
            email: email || prevData.email,
            fantasy_name: nome_fantasia || prevData.fantasy_name,
            dd_1: ddd1 || prevData.dd_1,
            phone_1: phone1 || prevData.phone_1,
            dd_2: ddd2 || prevData.dd_2,
            phone_2: phone2 || prevData.phone_2,
          }));
        }
      } catch (error) {
        console.error('Erro ao buscar CNPJ:', error);
      }
    } else {
      swal({
        title: 'Ops!',
        text: 'CPF/CNPJ inválido. Verifique o CPF/CNPJ informado.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      resetFormData();
    }
  };
  
  const resetFormData = () => {
    setFormData((prevData) => ({
      ...prevData,
     corporate_name: '',
     email: '',
     fantasy_name: '',
     dd_1: '',
     phone_1: '',
     dd_2: '',
     phone_2: '',
    }));
  };

  useEffect(() => {
    const cnpjInput = document.querySelector('input[name="document"]');
    const handleBlur = () => handleCNPJBlur(cnpjInput.value);
    cnpjInput.addEventListener('blur', handleBlur);
    return () => {
      cnpjInput.removeEventListener('blur', handleBlur);
    };
  }, []);
  

  const handleCepBlur = async (value) => {
    if (value.length === 9) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${value}/json/`);

        if (response.data.erro) {
          swal({
            title: 'Ops!',
            text: 'CEP inválido. Verifique o CEP informado.',
            icon: 'error',
            timer: 2000,
            buttons: false,
          });
          setFormData((prevData) => ({
            ...prevData,
            zip_code: '',
            address: '',
            district: '',
            city: '',
            state: '',
          }));
        } else {
          const { logradouro, bairro, cep, localidade, uf } = response.data;
          const ibgeToken = localStorage.getItem('IBGEtoken');
          const ibgeResponse = await axios.get(`${IBGECITIESSTATES_API}`, {
            headers: {
              Authorization: `Bearer ${ibgeToken}`,
            },
          });

          const cities = ibgeResponse.data;
          const selectedCity = cities.find((city) => city.name.toLowerCase() === localidade.toLowerCase());

          if (selectedCity) {
            setFormData((prevData) => ({
              ...prevData,
              zip_code: cep,
              address: logradouro,
              district: bairro,
              city: localidade,
              state: uf,
            }));

            setSelectedCityId(selectedCity.code);
            const stateId = selectedCity.state[0].code;
            setSelectedStateId(stateId);
          } else {
            setFormData((prevData) => ({
              ...prevData,
              zip_code: cep,
              address: logradouro,
              district: bairro,
              city: '',
              state: uf,
            }));
          }
        }
      } catch (error) {
        console.error('Erro ao buscar CEP:', error);
      }
    } else {
      swal({
        title: 'Ops!',
        text: 'CEP inválido. Verifique o CEP informado.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      setFormData((prevData) => ({
        ...prevData,
        zip_code: '',
        address: '',
        district: '',
        city: '',
        state: '',
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'status') {
      setFormData({ ...formData, status: parseInt(value) });
    } else if (name === 'zip_code') {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    const cepInput = document.querySelector('input[name="zip_code"]');
    const handleBlur = () => handleCepBlur(cepInput.value);
    cepInput.addEventListener('blur', handleBlur);
    return () => {
      cepInput.removeEventListener('blur', handleBlur);
    };
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const cityIdToUpdate = selectedCityId || originalCityId;
    const stateIdToUpdate = selectedStateId || originalStateId;
    const cleanFormData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => {
        if (typeof value === 'string' && maskedFields.includes(key)) {
          const cleanedValue = value.replace(/\D/g, '');
          return [key, cleanedValue];
        }
        return [key, value];
      })
    );

    const updatedFormData = {
      ...cleanFormData,
      status: selectedStatus,
      city: cityIdToUpdate,
      type_people:selectedType,
      state: stateIdToUpdate,
    };

    try {

      setLoadingSubmit(true) 
      const response = await axios.put(`${PEOPLE_API}/${personId}`, updatedFormData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
        console.log(response.data)
        if (response.status === 200) { 
          swal({
            title: 'OK!',
            text: 'Pessoa atualizado com sucesso!',
            icon: 'success',
            timer: 2000,
            buttons: false,
          });
          // navigate('/cadastro/pessoa');
        } else {
          swal({
            title: 'Ops!',
            text: 'Ocorreu um erro ao editar dados',
            icon: 'error',
            timer: 2000,
            buttons: false,
          });
        }
      } catch (error) {
        console.error('Erro ao processar o formulário:', error);
      } finally {
        setLoadingSubmit(false);
      }
    };

  const camposAtuais = [
    ...camposPessoaFisica,
    {
    name: 'type_people',
    type: 'select',
    placeholder: 'Tipo',
    fullWidth: true,
    width: 'calc(20% - 7.5px)',
    value: selectedType,
    onChange: (e) => setSelectedType(e.target.value),
    options: [
      { value: 1, label: 'Professor' },
      { value: 0, label: 'Aluno' }
    ]
  },]

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  }

  return (
    <div>
    <FormContainer>
      <TabContainer>
        <TabButton
          active={activeTab === 'people'}
          onClick={() => handleTabChange('people')}
        >
          Dados da Pessoa
        </TabButton>
        <TabButton
          active={activeTab === 'Documentos'}
          onClick={() => handleTabChange('Documentos')}
        >
          Documentos
        </TabButton>
      </TabContainer>
      <Container>
        <Title text={'Editar Pessoa'} backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'} />
        <Container1>
          <Form onSubmit={handleSubmit}>
            {activeTab === 'people' && (
              <>
                {camposAtuais.map((campo) => (
                   <ResponsiveDiv key={campo.name} width={campo.width}>
                   {campo.type === 'select' ? (
                     <Select
                       name={campo.name}
                       value={campo.value}
                       onChange={campo.onChange}
                       style={{ width: '100%' }}
                     >
                       <option value="" style={{ color: '#999' }} disabled>
                         {campo.placeholder}
                       </option>
                       {campo.options.map((option, index) => (
                         <option key={index} value={option.value}>
                           {option.label}
                         </option>
                       ))}
                     </Select>
                   ) : (
                     <Input
                       id={campo.name}
                       type={campo.type}
                       name={campo.name}
                       value={formData[campo.name]}
                       onChange={campo.onChange || handleChange}
                       mask={campo.mask}
                       maskChar={null}
                       required
                       disabled={campo.disabled}
                       fullWidth={campo.fullWidth}
                       style={{ width: '100%' }}
                     />
                   )}
                   <Label htmlFor={campo.name}>
                     {campo.placeholder}
                   </Label>
                   </ResponsiveDiv>
               ))}
                <Active
                  name="status"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  style={{ width: 'calc(10% - 7.5px)' }}
                >
                  <option value="" disabled style={{ color: '#999' }}>
                    Status
                  </option>
                  <option value={1}>Ativo</option>
                  <option value={0}>Inativo</option>
                </Active>
              </>
            )}
            {activeTab !== 'Documentos' && (
              <Buttons>
                <BackButton as={Link} to="/cadastros/pessoa" />
                <SaveButton onClick={handleSubmit} disabled={loading} />
              </Buttons>
            )}
          </Form>
          {activeTab === 'Documentos' && (
              <EditPeopleDocuments />
            )}
          </Container1>
        </Container>
      </FormContainer>
    </div>
  );
};

export default EditPerson;