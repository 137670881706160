import React from "react";
import styled from "styled-components";
import theme from "../../theme";

const TopbarSearchInput = styled.input`
  background-color: #f8f9fc;
  border: ${(props) => (props.customStyle ? `2px solid ${theme.primaryColor}` : 'none')};
  border-radius: 0.25rem 0 0 0.25rem;
  padding: 0.375rem 0.75rem;
  width: ${(props) => props.inputWidth || ''}; 
`;

const TopbarSearchButton = styled.button`
  background-color:  ${(props) => props.backgroundColor || '#4e73df'};
  border: 2px blue;
  border-radius: 0 0.25rem 0.25rem 0;
  color: #fff;
  padding: 0.375rem 0.75rem;

  ::placeholder {
    font-weight: bold;
    color: #000;
  }
`;


const TopbarSearchForm = styled.form`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const SearchInput = ({ searchTerm, setSearchTerm, backgroundColor, placeholder, inputWidth, Color  }) => {
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <TopbarSearchForm 
    >
      <TopbarSearchInput
        type="text"
        className="form-control bg-light border-0 small"
        placeholder= {placeholder  || "Busca..."}
        aria-label="Search"
        aria-describedby="basic-addon2"
        value={searchTerm}
        onChange={handleInputChange}
        inputWidth={inputWidth}
      />
      <TopbarSearchButton className="btn btn-primary" type="button" backgroundColor={backgroundColor} >
        <i className="fas fa-search fa-sm"
        style={{ color: Color }}></i>
      </TopbarSearchButton>
    </TopbarSearchForm>
  );
};

export default SearchInput;
