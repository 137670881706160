import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import axios from 'axios';
import { COMPANY_API, CONSULTCOMPANYYUSER_API, LOGINIBGE_API, ME_API } from '../../../apiEndPoints';
import { useNavigate } from 'react-router-dom';

const CenteredContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  height: 100vh;
  background-color: ${theme.backgroundColor1};
`;

const Container = styled.div`
  width: 75%;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const CompanyOption = styled.div`
  margin: 10px 0;
  padding: 15px;
  font-size: 21px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.primaryColor};
    color: ${theme.backgroundColor1};
  }
`;

const Title = styled.h2`
  color: #333;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const CheckboxLabel = styled.label`
  margin-left: 10px;
  font-size: 18px;
  color: #333;
  display: flex;
  align-items: center;
  position: relative;
  top: 5px;
`;

const CompanySelection = () => {
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();
  const [userMaster, setUserMaster] = useState(0);
  const [redirectToDashboard, setRedirectToDashboard] = useState(true);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const fetchUserData = async () => {
      try {
        const response = await fetch(ME_API, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserMaster(data.user_master);
        } else {
          console.error('Erro ao buscar dados do usuário');
        }
      } catch (error) {
        console.error('Erro na chamada da API:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId'); 

        const associatedCompaniesResponse = await axios.get(`${CONSULTCOMPANYYUSER_API}/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const associatedCompanyIds = associatedCompaniesResponse.data.map(company => company.company_id);

        const allCompaniesResponse = await axios.get(COMPANY_API, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const filteredCompanies = allCompaniesResponse.data.filter(company => associatedCompanyIds.includes(company.id));

        setCompanies(filteredCompanies);
      } catch (error) {
        console.error('Erro ao buscar empresas:', error);
      }
    };

    fetchCompanies();
  }, []);

  const handleCompanySelect = async (selectedCompany) => {
    localStorage.setItem('companyID', selectedCompany.id);
  
    const IBGEtoken = await loginWithCredentials();
    localStorage.setItem('IBGEtoken', IBGEtoken);

    if (userMaster === 0) {
      navigate('/home');
    } else {
      if (redirectToDashboard) {
        navigate('/dashboard');
      } else {
        navigate('/home');
      }
    }
  };

  const loginWithCredentials = async () => {
    try {
      const loginCredentials = {
        cpf: "00000000000",
        password: "admin1234"
      };

      const response = await axios.post(`${LOGINIBGE_API}`, loginCredentials, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      return response.data.access_token;
    } catch (error) {
      console.error('Erro ao realizar login:', error);
      throw error;
    }
  };

  return (
    <CenteredContainer>
      <Container>
        <Title>Escolha a Empresa</Title>
        {companies.map((company) => (
          <CompanyOption key={company.id} onClick={() => handleCompanySelect(company)}>
            {company.fantasy_name}
          </CompanyOption>
        ))}
        {userMaster !== 0 && (
          <CheckboxContainer>
            <input
              type="checkbox"
              checked={redirectToDashboard}
              onChange={() => setRedirectToDashboard(!redirectToDashboard)}
            />
            <CheckboxLabel>Logar como Administrador</CheckboxLabel>
          </CheckboxContainer>
        )}
      </Container>
    </CenteredContainer>
  );
};

export default CompanySelection;
