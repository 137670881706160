import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../vendor/fontawesome-free/css/all.min.css'
import '../../css/sb-admin-2.min.css'

function InputLogin({ onChangeCPF, onChangePassword }) {
  return (
    <div>
      <div className="form-group">
        <input
          type="text"
          className="form-control form-control-user"
          id="InputEmail"
          placeholder="Insira seu Email"
          onChange={(e) => onChangeCPF(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="password"
          className="form-control form-control-user"
          id="InputPassword"
          placeholder="Senha"
          onChange={(e) => onChangePassword(e.target.value)}
        />
      </div>
    </div>
  );
}

export default InputLogin;
