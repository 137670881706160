import React from "react";
import Login from "../views/LoginRegister/LoginPage";
import Layout from '../layouts/layout';
import DashboardPage from '../views/Dashboard';
import ConfigPage from "../views/ConfigPage";
import CompanySelection from "../views/LoginRegister/CompanySelection";
import UserRegister from "../views/RegisterPages/UserRegister";
import EditUser from "../views/EditPages/EditUser";
import AccessGroups from "../views/AccessGroups";
import CompanyRegister from "../views/RegisterPages/CompanyRegister";
import EditCompany from "../views/EditPages/EditCompany";
import UserList from "../views/TablePages/UserList";
import CompanyList from "../views/TablePages/CompanyList";
import PersonRegistration from "../views/RegisterPages/PersonRegistration";
import EditPerson from "../views/EditPages/EditPerson";
import PeopleList from "../views/TablePages/PeopleList";
import CourseRegister from "../views/RegisterPages/CourseRegister";
import EditCourse from "../views/EditPages/EditCourses";
import CourseList from "../views/TablePages/CourseList";
import TrailRegister from "../views/RegisterPages/TrailRegister";
import TrailList from "../views/TablePages/TrailList";
import EditTrail from "../views/EditPages/EditTrail";
import ModuleRegister from "../views/RegisterPages/ModuleRegister";
import EditModule from "../views/EditPages/EditModule";
import ModuleList from "../views/TablePages/ModuleList";
import LessonRegister from "../views/RegisterPages/LessonRegister";
import EditLesson from "../views/EditPages/EditLesson";
import LessonList from "../views/TablePages/LessonList";
import AssesmentRegister from "../views/RegisterPages/AssesmentRegister";
import EditAssesment from "../views/EditPages/EditAssesment";
import AssesmentList from "../views/TablePages/AssesmentList";
import CustomPage from "../html/inicio";
import PortalLayout from "../layouts/portalLayout";
import CoursesList from "../portal/cursos";
import CursoIntro from "../portal/cursos/curso";
import CourseIntroduction from "../portal/cursos/intro";
import TrailsList from "../portal/trilhas/trilha";
import ModulesList from "../portal/modulos";
import ModuleIntroduction from "../portal/modulos/intro";
import ClassesList from "../portal/aulas";
import ClassIntroduction from "../portal/aulas/intro";
import Lesson from "../portal/aulas/lesson";
import ForumPage from "../portal/forum/questions";
import ForumResponses from "../portal/forum/response";
import PostQuestionPage from "../portal/forum/post";
import PostQuestionLessonForum from "../portal/forum/postLessonForum";
import LessonForum from "../portal/forum/lessonForum";
import TrailsGrid from "../portal/trilhas";
import CategoriesRegister from "../views/RegisterPages/CategoryRegister";
import CategoryList from "../views/TablePages/CategoryList";
import EditCategory from "../views/EditPages/EditCategory";
import TrailsCurses from "../portal/trilhas/trilhaCursos";
import EvaluationRegister from "../views/RegisterPages/EvaluationRegister";
import EditEvaluation from "../views/EditPages/EditEvaluation";
import Evaluation from "../portal/avaliacoes";
import LinkCategoryTrail from "../views/RegisterPages/LinkCategoryTrailRegister";
import AlunoHUmus from "../portal/home";
import CourseCompletionPage from "../portal/aulas/certificado";
import ChangePassword from "../views/EditPages/EditUser/ChangePassword";
import StartPage from "../portal/start";




const VRoutes = [
    
    // { path: '/', element: <Navigate to="/login" /> },
    // { path: '/', element: <Layout><DashboardPage /></Layout> },
    { path: '/', element: <StartPage />},
    { path: '/login', element: <Login /> },
    { path: '/home', element: <PortalLayout><AlunoHUmus /></PortalLayout> },
    { path: '/trilhas', element: <PortalLayout><TrailsGrid /></PortalLayout> },
    { path: '/trilhas/:id', element: <PortalLayout><TrailsList /></PortalLayout> },
    { path: '/cursos', element: <PortalLayout><CoursesList /></PortalLayout> },
    { path: '/cursos/:id', element: <PortalLayout><CursoIntro /></PortalLayout> },
    { path: '/trilhas/cursos/:id', element: <PortalLayout><TrailsCurses /></PortalLayout> },
    { path: '/cursos/intro/:id', element: <PortalLayout><CourseIntroduction /></PortalLayout> },
    { path: '/curso/modulos/:id', element: <PortalLayout><ModulesList /></PortalLayout> },
    { path: '/curso/modulo/:id', element: <PortalLayout><ModuleIntroduction /></PortalLayout> },
    { path: '/curso/modulo/aulas/:id', element: <PortalLayout><ClassesList /></PortalLayout> },
    { path: '/curso/modulo/aula/:id', element: <PortalLayout><ClassIntroduction /></PortalLayout> },
    { path: '/modules/:moduleId/lessons/:lessonId', element:<PortalLayout><Lesson /></PortalLayout>},
    { path: '/forum', element:<PortalLayout><ForumPage /></PortalLayout>},
    { path: '/forum/lesson/:lesson_id', element:<PortalLayout><LessonForum /></PortalLayout>},
    { path: '/forum/question/:questionId', element:<PortalLayout><ForumResponses /></PortalLayout>},
    { path: '/forum/post-question', element:<PortalLayout><PostQuestionPage /></PortalLayout>},
    { path: 'forum/post-question/:lesson_id', element:<PortalLayout><PostQuestionLessonForum /></PortalLayout>},
    { path: '/curso/modulos/avaliacao/:id', element: <PortalLayout><Evaluation/></PortalLayout> },
    { path: '/curso/:id/completion', element: <PortalLayout><CourseCompletionPage/></PortalLayout> },
    { path: '/newpassword', element: <PortalLayout><ChangePassword/></PortalLayout> },

    
    
    
    // _____________________________________________________________________________________________________________
    
    
    { path: '/drag', element:<Layout><LinkCategoryTrail /></Layout>},
    
    { path: '/dashboard', element: <Layout><DashboardPage /></Layout> },
    { path: '/config', element: <Layout><ConfigPage /></Layout> },
    { path: '/companies', element: <CompanySelection /> },
    { path: '/cadastro/register', element: <Layout><UserRegister/></Layout> },
    { path: '/edituser/:id', element: <Layout><EditUser/></Layout> },
    { path: '/cadastro/pessoa', element: <Layout><UserList/></Layout> },
    { path: '/cadastro/usuario', element: <Layout><AccessGroups /></Layout> },
    { path: '/cadastro/companyregister', element: <Layout><CompanyRegister/></Layout> },
    { path: '/editcompany/:id', element: <Layout><EditCompany /></Layout> },
    { path: '/cadastro/empresa', element: <Layout><CompanyList /></Layout>},
    { path: '/cadastro/personregister', element: <Layout><PersonRegistration/></Layout> },
    { path: '/editpeople/:id', element: <Layout><EditPerson /></Layout> },
    { path: '/cadastros/pessoa', element: <Layout><PeopleList/></Layout> },
    { path: '/cadastro/courseregister', element: <Layout><CourseRegister/></Layout> },
    { path: '/editcourse/:id', element: <Layout><EditCourse /></Layout> },
    { path: '/cadastros/cursos', element: <Layout><CourseList/></Layout> },
    { path: '/cadastro/trailregister', element: <Layout><TrailRegister/></Layout> },
    { path: '/edittrail/:id', element: <Layout><EditTrail/></Layout> },
    { path: '/cadastros/trilha', element: <Layout>< TrailList/></Layout> },
    { path: '/cadastro/moduleregister', element: <Layout><ModuleRegister/></Layout> },
    { path: '/editmodule/:id', element: <Layout><EditModule/></Layout> },
    { path: '/cadastros/modulo', element: <Layout>< ModuleList/></Layout> },
    { path: '/cadastro/lessonregister', element: <Layout><LessonRegister/></Layout> },
    { path: '/editlesson/:id', element: <Layout><EditLesson/></Layout> },
    { path: '/cadastros/aulas', element: <Layout><LessonList/></Layout> },
    { path: '/cadastro/assesmentregister', element: <Layout><AssesmentRegister/></Layout> },
    { path: '/editassesment/:id', element: <Layout><EditAssesment/></Layout> },
    { path: '/cadastros/avaliacoes', element: <Layout><AssesmentList/></Layout> },
    { path: '/cadastro/categoryregister', element: <Layout><CategoriesRegister/></Layout> },
    { path: '/cadastros/categorias', element: <Layout><CategoryList/></Layout> },
    { path: '/editcategory/:id', element: <Layout><EditCategory/></Layout> },
    { path: '/cadastro/avaliacoes', element: <Layout><EvaluationRegister/></Layout> },
    { path: '/editevaluation/:id', element: <Layout><EditEvaluation/></Layout> },
    { path: '/editpassword', element: <Layout><ChangePassword/></Layout> },







    
  ];
  
  export default VRoutes;