import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import theme from '../../../theme';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { PHOTOITEM_API, PHOTOCOVER_API, DELETEPHOTOITEM_API, PEOPLE_API, TRAIL_API, MODULE_API, COURSE_API} from '../../../apiEndPoints';
import swal from 'sweetalert';
import { BackButton, SaveButton, TabButton, AddNewButton } from '../../../components/Buttons';
import { ModuleFormdata} from '../../../components/Formdatas';
import { Container, FormContainer, Form, ResponsiveDiv, Label, Buttons, Container1 , TabContainer } from '../../../components/FormContainers';
import { Title } from '../../../components/General';
import PhotoItem from '../../../components/PhotoCover/PhotoItem';
import { CourseFields, CourseModulesFields } from '../../../components/Fields/CourseFields';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Inputmodules } from '../../../components/InputModules';



const Input = styled.input`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;
const Textarea = styled.textarea`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  resize: vertical;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;


function ModuleRegister() {
  const [formData, setFormData] = useState(ModuleFormdata);
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [peopleOptions, setPeopleOptions] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [phID, setPhid] = useState('');

  const navigate = useNavigate();
  const [numPhotosRegister, setPhotosRegister] = useState(1);

  const [activeTab, setActiveTab] = useState('Módulo');
  const [showDocumentsTab, setShowDocumentsTab] = useState(false);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);


  const editor = useRef(null);
  const [content, setContent] = useState("Digite o Resumo do Módulo");

  const config = {
    readonly: false,
    height: 400,
  };

 
  const handleUpdate = (newContent) => {
    setContent(newContent);
    setFormData({ ...formData, summary_modules: newContent });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const missingFields = campos.filter(campo => campo.required && !formData[campo.name]);

    if (!formData.file_document) {
      swal({
        title: 'Ops!',
        text: 'Por favor, carregue uma imagem antes de salvar.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      return;
    }

    if (missingFields.length > 0) {

      swal({
        title: 'Ops!',
        text: 'Por favor, preencha todos os campos obrigatórios.',
        icon: 'error',
        timer: 2000,
        buttons: false,
      });
      return;
    }
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyID');

    const updatedFormdata = new FormData();

    updatedFormdata.append('user_id', userId);
    updatedFormdata.append('company_id', companyId);
    // updatedFormdata.append('people_id', selectedPeople);
    updatedFormdata.append('status', 1);
    updatedFormdata.append('summary_modules', content);
    updatedFormdata.append('courses_id', selectedCourse);
    updatedFormdata.append('file_document', formData.file_document);
    updatedFormdata.append('title', formData.title);
    updatedFormdata.append('description', '0');

    try {

      const response = await axios.post(`${MODULE_API}`,
        updatedFormdata

        , {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });

      if (response.status === 200 || response.status === 201) {
        swal({
          title: 'OK!',
          text: 'Módulo cadastrada com sucesso',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        handleGoBack()
        // setFieldsDisabled(true);
        // const IDPost = response.data.id;

        // const photoCoverData = {
        //     user_id: userId,
        //     company_id: companyId,
        //     status: 1,
        //     observation: `fotos ${IDPost}`,
        //     people_id: selectedPeople,
        //     modules_id: IDPost
        // };

        // try {
        //     const photoCoverResponse = await axios.post(`${PHOTOCOVER_API}`, photoCoverData, {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             Authorization: `Bearer ${token}`,
        //         },
        //     });

        //     console.log(photoCoverResponse, 'photoCoverResponse');

        //     if (photoCoverResponse.status === 200 || photoCoverResponse.status === 201) {
        //         swal({
        //             title: 'OK!',
        //             text: 'Cadastrado com sucesso',
        //             icon: 'success',
        //             timer: 2000,
        //             buttons: false,
        //         });
        //         setPhid(photoCoverResponse.data.id)
        //         setShowDocumentsTab(true);
        //         setActiveTab('Fotos');
        //     } else {
        //         swal({
        //             title: 'Ops!',
        //             text: 'Algo deu errado , tente denovo.',
        //             icon: 'error',
        //             timer: 2000,
        //             buttons: false,
        //         });
        //     }
        // } catch (error) {
        //     console.error('Error processing the photo cover form:', error);
        //     swal({
        //         title: 'Ops!',
        //         text: 'Erro ao processar o cadastro da capa de foto.',
        //         icon: 'error',
        //         timer: 2000,
        //         buttons: false,
        //     });
        // }

    } else {
        swal({
            title: 'Ops!',
            text: 'Algo deu errado, tente denovo.',
            icon: 'error',
            timer: 2000,
            buttons: false,
        });
    }
} catch (error) {
    console.error('Error processing the form:', error);
}
};


// useEffect(() => {
//     const fetchPeople = async () => {
//       try {
//         const response = await axios.get(`${PEOPLE_API}`, {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         });

//         const companyId = parseInt(localStorage.getItem('companyID'), 10);
//         const filteredProducts = response.data.filter(
//           (people) => people.company_id === companyId && people.type_people === 1
//         );

//         const options = filteredProducts.map((people) => ({
//           label: people.name,
//           value: people.id
//         }));

//         setPeopleOptions(options);
//       } catch (error) {
//         console.error('Erro ao buscar opções de produtos:', error);
//       }
//     };

//     fetchPeople();
//   }, []);

useEffect(() => {
  const fetchCourse = async () => {
    try {
      const response = await axios.get(`${COURSE_API}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const companyId = parseInt(localStorage.getItem('companyID'), 10);
      const filteredProducts = response.data.filter(
        (course) => course.company_id === companyId
      );

      const options = filteredProducts.map((course) => ({
        label: course.title,
        value: course.id
      }));

      setCourseOptions(options);
    } catch (error) {
      console.error('Erro ao buscar opções de produtos:', error);
    }
  };

  fetchCourse();
}, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const reader = new FileReader();
      const file = files[0];
      
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
  
      reader.readAsDataURL(file);
  
      setFormData({ ...formData, [name]: file });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  
  const handleGoBack = () => {
    window.history.back();
  };


  const campos = [
    // {
    //   name: 'people_id',
    //   type: 'select',
    //   placeholder: 'Responsável',
    //   width: 'calc(50% - 7.5px)',
    //   value: selectedPeople,
    //   onChange: (e) => {
    //     setSelectedPeople(e.target.value);
    //   },
    //   options: peopleOptions,
    // },
    {
      name: 'curse_id',
      type: 'select',
      placeholder: 'Curso',
      width: 'calc(99% - 7.5px)',
      value: selectedCourse,
      onChange: (e) => {
        setSelectedCourse(e.target.value);
      },
      options: courseOptions,
    },
    ...CourseModulesFields,
    // {
    //   name: 'status',
    //   type: 'select',
    //   placeholder: 'Status',
    //   width: 'calc(13.5% - 7.5px)',
    //   value: selectedStatus,
    //   onChange: (e) => setSelectedStatus(e.target.value),
    //   options: AnnounceStatusOptions
    // },
  ]


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  }



  return (
    <div>
      <FormContainer>
      <TabContainer>
        <TabButton
          active={activeTab === 'Módulo'}
          onClick={() => handleTabChange('Módulo')}
        >
          Módulo
        </TabButton>
        {showDocumentsTab  && (
        <TabButton
          active={activeTab === 'Fotos'}
          onClick={() => handleTabChange('Fotos')}
        >
          Fotos
        </TabButton>
        )}
      </TabContainer>
        <Container>
          <Title text={'Registrar Módulo'} backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'} />
          <Container1>
            <Form onSubmit={handleSubmit}>
            {activeTab === 'Módulo' && (
              <>
              {campos.map((campo) => (
                <ResponsiveDiv key={campo.name} width={campo.width}>
                  {campo.type === 'select' ? (
                      <Select
                      name={campo.name}
                      value={campo.value}
                      onChange={campo.onChange}
                      style={{ width: '100%' }}
                      disabled={campo.disabled || fieldsDisabled}
                    >
                      <option value="" style={{ color: '#999' }} disabled>
                        {campo.placeholder}
                      </option>
                      {campo.options.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  ) : campo.type === 'textarea' ? (
                    <Textarea
                      id={campo.name}
                      name={campo.name}
                      value={formData[campo.name]}
                      onChange={campo.onChange || handleChange}
                      required={campo.required}
                      disabled={campo.disabled || fieldsDisabled}
                      fullWidth={campo.fullWidth}
                      style={{ width: '100%' }}
                    />
                  ) : campo.type === 'file' ? (
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                      <div>
                        {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />}
                      </div>
                      <div>
                        <Input
                          id={campo.name}
                          type="file"
                          name={campo.name}
                          onChange={handleChange}
                          required
                          accept={campo.accept}
                          disabled={campo.disabled}
                          fullWidth={campo.fullWidth}
                          style={{ width: '100%' }}
                        />
                      </div>
                    </div>
                  ) : (
                    <Input
                      id={campo.name}
                      type={campo.type}
                      name={campo.name}
                      value={formData[campo.name]}
                      onChange={campo.onChange || handleChange}
                      mask={campo.mask}
                      maskChar={null}
                      required
                      disabled={campo.disabled || fieldsDisabled}
                      fullWidth={campo.fullWidth}
                      style={{ width: '100%' }}
                    />
                  )}
                  <Label htmlFor={campo.name}>{campo.placeholder}</Label>
                </ResponsiveDiv>
              ))}
                             <ResponsiveDiv width={'99%'}>
                      Resumo do Módulo
                      <ReactQuill
                      ref={editor}
                      value={content}
                      onChange={handleUpdate}
                      style={{ height: '100px', marginBottom:'2em', resize: 'vertical', overflow: 'auto' }}
                      modules={Inputmodules}
                    />
                  </ResponsiveDiv>

                </>
                 
            )}
              {activeTab !== 'Fotos' && (
              <Buttons>
                <BackButton onClick={handleGoBack} />
                <SaveButton onClick={handleSubmit} disabled={loading} />
              </Buttons>
            )}
          </Form>
          {showDocumentsTab && activeTab === 'Fotos' && (
          <>
          <Title text={'Fotos'}/>
            {Array.from({ length: numPhotosRegister }).map((_, index) => (
              <PhotoItem key={index} apiItem={PHOTOITEM_API} deleteApi={DELETEPHOTOITEM_API} phId={phID}/>
            ))}
            <FormContainer paddingWrap={'0px'}>
              <AddNewButton onClick={() => setPhotosRegister(numPhotosRegister + 1)} />
            </FormContainer>
          </>
        )}
          </Container1>
        </Container>
      </FormContainer>
    </div>
  );
};

export default ModuleRegister;