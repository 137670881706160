import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { BackButton, PagesButton, PostButton } from '../../../components/Buttons';
import { Form, ResponsiveDiv, Label, Buttons, Container1 } from '../../../components/FormContainers';
import { Title } from '../../../components/General';
import { FORUM_API, FORUMLIKE_API, FORUMRESPONSES_API, RESPONSE_API, USER_API } from '../../../apiEndPoints';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { Inputmodules } from '../../../components/InputModules';
import ResponseLikesButton from '../../../components/ResponseLikesButton';

const Input = styled.input`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${(props) => props.theme.primaryColor};
  }
`;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QuestionContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
`;

const ResponseContainer = styled.div`
  width: 100%;
  min-width: 800px;
  min-height: 200px;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  word-break: break-word;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: solid 1px #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const Pagination = styled.div`
  margin: 2px 5px 2rem 0;
  display: flex;
`;

const Flex = styled.div`
  display: flex;
`;

const TimeAgo = ({ timestamp }) => {
  const timeDifference = (date1, date2) => {
    const diffInMs = date2 - date1;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    if (diffInMinutes < 60) return `${diffInMinutes} minutos atrás`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} horas atrás`;
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 365) return `${diffInDays} dias atrás`;
    const diffInYears = Math.floor(diffInDays / 365);
    return `${diffInYears} anos atrás`;
  };

  const now = new Date();
  const postDate = new Date(timestamp);
  return <span>{timeDifference(postDate, now)}</span>;
};

const VoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 15px; 
`;

const VoteButton = styled.button`
  // background-color: ${(props) => (props.upvote ? 'green' : 'red')};
  color: #06BBCC;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: ${(props) => (props.upvote ? '5px' : '0')};
`;
function ForumResponses() {
  const { questionId } = useParams();
  const [question, setQuestion] = useState(null);
  const [responses, setResponses] = useState([]);
  const [userNames, setUserNames] = useState({});
  const [newResponse, setNewResponse] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [likes, setLikes] = useState({});
  const [likeCounts, setLikeCounts] = useState({});
  const responsesPerPage = 10;
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchQuestionAndResponses = async () => {
      try {
        const questionResponse = await axios.get(`${FORUM_API}/${questionId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setQuestion(questionResponse.data);

        const responseResponse = await axios.get(`${RESPONSE_API}/${questionId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setResponses(responseResponse.data);

        const likesResponse = await axios.get(FORUMLIKE_API, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const userLikes = likesResponse.data.filter(like => like.user_id === Number(userId));
        console.log(likesResponse, 'resposta')
        const likesData = {};
        const likeCountsData = {};
        likesResponse.data.forEach((like) => {
          if (like.like === 1) { 

            likeCountsData[like.response_id] = (likeCountsData[like.response_id] || 0) + 1;
          }
          if (like.user_id === Number(userId)) {
            likesData[like.response_id] = { ...like, like: like.like === 1 }; 
          }
        });

        setLikes(likesData);
        setLikeCounts(likeCountsData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchQuestionAndResponses();
  }, [questionId, token]);

  const handleLike = async (responseID, event) => {
    event.preventDefault();
    event.stopPropagation();

    const like = likes[responseID];
    const storedToken = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const companyID = localStorage.getItem('companyID');

    if (like && like.id) {
      try {
        const response = await axios.put(
          `${FORUMLIKE_API}/${like.id}`,
          {
            like: !like.like,
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        setLikes((prevLikes) => ({
          ...prevLikes,
          [responseID]: { ...like, like: !like.like },
        }));
        setLikeCounts((prevCounts) => ({
          ...prevCounts,
          [responseID]: like.like ? prevCounts[responseID] - 1 : prevCounts[responseID] + 1,
        }));
      } catch (error) {
        console.error('Error updating like:', error);
      }
    } else {
      try {
        const response = await axios.post(
          FORUMLIKE_API,
          {
            user_id: userId,
            response_id: responseID,
            like: true,
            company_id: companyID,
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        setLikes((prevLikes) => ({
          ...prevLikes,
          [responseID]: { id: response.data.id, like: true },
        }));
        setLikeCounts((prevCounts) => ({
          ...prevCounts,
          [responseID]: (prevCounts[responseID] || 0) + 1,
        }));
      } catch (error) {
        console.error('Error liking the response:', error);
      }
    }
  };

  useEffect(() => {
    const fetchUserNames = async () => {
      const userIds = new Set();
      if (question) userIds.add(question.user_id);
      responses.forEach((response) => userIds.add(response.user_id));
      
      const userNamePromises = Array.from(userIds).map(userId =>
        axios.get(`${USER_API}/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      );

      try {
        const userResponses = await Promise.all(userNamePromises);
        const userNamesMap = userResponses.reduce((acc, response) => {
          acc[response.data.id] = response.data.name;
          return acc;
        }, {});
        setUserNames(userNamesMap);
      } catch (error) {
        console.error('Erro ao buscar os nomes dos usuários:', error);
      }
    };

    if (question || responses.length > 0) {
      fetchUserNames();
    }
  }, [question, responses, token]);

  const handleResponseChange = (content) => {
    setNewResponse(content);
  };

  const handleResponseSubmit = async (e) => {
    e.preventDefault();
    if (!newResponse.trim()) return;

    try {
      const response = await axios.post(
        FORUMRESPONSES_API,
        {
          user_id: userId,
          question_id: questionId,
          response: newResponse,
          like: 0
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        setResponses((prevResponses) => [...prevResponses, response.data]);
        setNewResponse('');
      }
    } catch (error) {
      console.error('Erro ao enviar a resposta:', error);
    }
  };

  const handleGoBack = () => {
    window.history.back();
  };


  // const handleVote = async (responseId, delta) => {
  //   try {
  //     const response = responses.find((res) => res.id === responseId);
  //     const updatedLike = response.like + delta;

  //     await axios.put(
  //       `${FORUMRESPONSES_API}/${responseId}`,
  //       { like: updatedLike },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     setResponses((prevResponses) =>
  //       prevResponses.map((res) =>
  //         res.id === responseId ? { ...res, like: updatedLike } : res
  //       )
  //     );
  //   } catch (error) {
  //     console.error('Erro ao atualizar o voto:', error);
  //   }
  // };

  const indexOfLastResponse = currentPage * responsesPerPage;
  const indexOfFirstResponse = indexOfLastResponse - responsesPerPage;
  const currentResponses = responses
    .slice()
    .sort((a, b) => b.like - a.like || new Date(a.created_at) - new Date(b.created_at))
    .slice(indexOfFirstResponse, indexOfLastResponse);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container>
      <Title text={`Fórum`} />
      {question && (
        <QuestionContainer>
          <Header>
            <p>Postado por: {userNames[question.user_id] || 'Carregando...'}</p>
            <TimeAgo timestamp={question.created_at} />
          </Header>
          <Title text={question.title} />
          <div dangerouslySetInnerHTML={{ __html: question.question }} />
          <Label>Tags: {Array.isArray(question.tags) ? question.tags.join(', ') : question.tags}</Label>
        </QuestionContainer>
      )}
      <div>
        <Title text={'Respostas'} />
        {currentResponses.map((response) => (
          <ResponseContainer key={response.id}>
            <Header>
              <p>Postado por: {userNames[response.user_id] || 'Carregando...'}</p>
              <TimeAgo timestamp={response.created_at} />
            </Header>
            <div style={{marginRight: '1em'}} dangerouslySetInnerHTML={{ __html: response.response }} />
            <VoteContainer>
            <ResponseLikesButton
                  like={likes[response.id] && likes[response.id].like}
                  likeCount={likeCounts[response.id] || 0}
                  onLike={(event) => handleLike(response.id, event)}
                />
            </VoteContainer>
          </ResponseContainer>
        ))}
      </div>
      <Flex>
        {Array.from({ length: Math.ceil(responses.length / responsesPerPage) }, (_, index) => (
          <Pagination key={index}>
            <PagesButton 
              backgroundColor={'#06BBCC'} 
              onClick={() => paginate(index + 1)}
              text={index + 1}
            />
          </Pagination>
        ))}
      </Flex>
      <Form onSubmit={handleResponseSubmit}>
        <ResponsiveDiv width={'100%'}>
          <ReactQuill
            value={newResponse}
            onChange={handleResponseChange}
            placeholder="Digite sua resposta"
            required
            modules={Inputmodules}

          />
        </ResponsiveDiv>
        <Buttons>
          <BackButton onClick={handleGoBack} />
          <button className="btn btn-primary" type={"submit"}>Postar</button>
        </Buttons>
      </Form>
    </Container>
  );
}

export default ForumResponses;
