import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import { Link } from 'react-router-dom';
import { PiCaretDoubleLeftFill } from "react-icons/pi";
import { PiCaretDoubleRightFill } from "react-icons/pi";


const StyledButton = styled.button`
  background-color: ${props => props.backgroundColor || theme.primaryColor};
  color: ${props => props.color || 'white'};
  padding: ${props => props.padding || '5px'};
  font-size: ${props => props.fontSize};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;

  &:hover {
    opacity: 0.8;
    text-decoration: none;
    color: #ccc;}
  `;

const OutlinedButton = styled.button`
padding: ${props => props.padding || '5px'};
font-size: ${props => props.fontSize};
background-color: ${props => props.backgroundColor || 'transparent'};
border: none;
border-radius: 5px;
cursor: pointer;
color: ${props => props.letteringColor};
border: solid 1px ${props => props.letteringColor};

&:hover {
    background-color: ${props => props.backgroundColorHover || 'transparent'};
    text-decoration: none;
    color: ${props => props.letteringColor};
`;

const StyledTabButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  margin-right: 10px;
  border-bottom: ${(props) =>
    props.active ? `2px solid ${theme.primaryColor}` : 'none'};
`;

export const DefaultButton = ({ text, onClick, backgroundColor, color, padding }) => {
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor || theme.primaryColor}
      color={color || 'white'}
      padding={padding}
    >
      {text}
    </StyledButton>
  );
};

export const SidebarToggleButton = ({ onClick, backgroundColor, color, padding, isSidebarOpen, iconSize }) => {
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor || 'transparent'}
      color={color || 'blue'}
      padding={padding}
      style={{ marginLeft: isSidebarOpen ? '0%' : '0%' }}>

      <i className={`fa fas ${isSidebarOpen ? 'fa fa-bars' : 'fa fa-bars'}`}
        style={{ fontSize: iconSize || '1.5em', display: 'inline', float: 'left', margin: '10px 0 0 36px ' }} />
    </StyledButton>
  );
};
export const SaveButton = ({ onClick, backgroundColor, color, padding, fontSize, to, type, disabled }) => {
  return (
    <StyledButton
    onClick={!disabled ? onClick : null} 
    backgroundColor={backgroundColor}
    color={color}
    padding={padding || '8px 20px'}
    fontSize={fontSize || '16px'}
    as={to ? Link : 'button'}
    to={to}
    type={type || 'button'}
    disabled={disabled}
  >
    <i className="fas far fa-check"></i> 
  </StyledButton>
  );
};

export const BackButton = ({ onClick, backgroundColor, color, padding, letteringColor, fontSize, to, backgroundColorHover }) => {
  return (
    <OutlinedButton
      onClick={onClick}
      backgroundColor={backgroundColor}
      backgroundColorHover={backgroundColorHover || theme.backgroundColor2}
      color={color}
      padding={padding || '7px 20px'}
      fontSize={fontSize || '16px'}
      as={Link}
      to={to}
      letteringColor={letteringColor || theme.dangerColor}
    >
      <i class="fa fal fa-undo"></i>
    </OutlinedButton>
  );
};

export const DeleteButton = ({ onClick, backgroundColor, color, padding, letteringColor, fontSize, to, backgroundColorHover }) => {
  return (
    <OutlinedButton
      onClick={onClick}
      backgroundColor={backgroundColor}
      backgroundColorHover={backgroundColorHover || theme.backgroundColor2}
      color={color}
      padding={padding || '7px 22px'}
      fontSize={fontSize || '16px'}
      as={Link}
      to={to}
      letteringColor={letteringColor || theme.dangerColor}
    >
      <b>X</b>
    </OutlinedButton>
  );
};

export const TabButton = ({ active, onClick, children }) => {
  return (
    <StyledTabButton active={active} onClick={onClick}>
      {children}
    </StyledTabButton>
  );
};

export const AddNewButton = ({ onClick, backgroundColor, color, padding, to }) => {
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor || theme.primaryColor}
      color={color || 'white'}
      padding={padding || '5px 10px'}
      as={Link}
      to={to}
    >
      <i class="fa fas fa-plus"></i>
    </StyledButton>
  );
};

export const BacktoButton = ({ onClick, backgroundColor, color, padding, to }) => {
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor || theme.primaryColor}
      color={color || 'white'}
      padding={padding || '5px 10px'}

      as={Link}
      to={to}
    >
      <i class="fa fal fa-undo-alt"></i>
    </StyledButton>
  );
};

export const InfoButton = ({ onClick, backgroundColor, color, padding }) => {
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor || theme.primaryColor}
      color={color || 'white'}
      padding={padding || '5px 10px'}
    >
      <i class="fa fa-solid fa-info"></i>
    </StyledButton>
  );
};

export const EditButton = ({ onClick,fontSize, backgroundColor, color, padding }) => {
  return (
    <StyledButton
      onClick={onClick}
      fontSize={fontSize || '20px'}
       backgroundColor={backgroundColor || 'transparent'}
      color={color || 'blue'}
      padding={padding || '10px 10px'}
    >
      <i class="fa-solid fa-pencil"></i> 
    </StyledButton>
  );
};

export const BindButton = ({ onClick, backgroundColor, color, padding }) => {
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor || theme.primaryColor}
      color={color || 'white'}
      padding={padding || '5px 10px'}
    >
      <i class="fa fa-solid fa-link"></i>
    </StyledButton>
  );
};

export const NextButton = ({ onClick, backgroundColor, color, padding }) => {
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor || theme.primaryColor}
      color={color || 'white'}
      padding={padding || '5px 10px'}
    >
      <PiCaretDoubleRightFill />
    </StyledButton>
  );
};

export const LastButton = ({ onClick, backgroundColor, color, padding }) => {
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor || theme.primaryColor}
      color={color || 'white'}
      padding={padding || '5px 10px'}
    >
      <i class="fa fas fa-chevron-right"></i>
    </StyledButton>
  );
};

export const PrevButton = ({ onClick, backgroundColor, color, padding }) => {
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor || theme.primaryColor}
      color={color || 'white'}
      padding={padding || '5px 10px'}
    >
      <PiCaretDoubleLeftFill />
    </StyledButton>
  );
};

export const FirstButton = ({ onClick, backgroundColor, color, padding }) => {
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor || theme.primaryColor}
      color={color || 'white'}
      padding={padding || '5px 10px'}

    >
      <i class="fa fas fa-chevron-left"></i><i class="fa fas fa-chevron-left"></i>
    </StyledButton>
  );
};

export const PostButton = ({ onClick, backgroundColor, color, padding, fontSize, to, type, disabled }) => {
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor}
      color={color}
      padding={padding || '8px 20px'}
      fontSize={fontSize || '16px'}
      as={Link}
      to={to}
      type={type || "button"}
      disabled={disabled}
    >
      Postar
      
    </StyledButton>
  );
};

export const PagesButton = ({ onClick, backgroundColor, color, padding, text, key }) => {
  return (
    <StyledButton
      onClick={onClick}
      backgroundColor={backgroundColor || theme.primaryColor}
      color={color || 'white'}
      padding={padding || '5px 10px'}
      key={key}

    >
      {text}
    </StyledButton>
  );
};
