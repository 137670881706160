import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Spinner, Button } from 'react-bootstrap';
import axios from 'axios';
import { CATEGORYTRAIL_API } from '../../apiEndPoints';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../theme';
import SearchInput from '../../components/SearchInput';

const FrameContainer = styled.div`
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 20px;
`;

const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  background-color: ${({ isOdd }) => (isOdd ? '#f8f9fa' : '#fff')};
  padding: 20px;
  border-radius: 10px;
  flex-direction: column;
`;

const CategoryTitle = styled.h3`
  flex: 0 0 200px;
  margin-top: 25px;
  text-align: left;
  font-size: 1.5rem;
 color: ${theme.portalTitleColor};
  transition: color 0.3s ease;
  
  &:hover {
    color: ${theme.portalColor};
  }
`;

const TrailsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  flex: 1;
  width: 100%;
`;

const CardContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
  text-align: center;
  padding: 10px;
`;

const CardImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease; 
  
  &:hover {
    transform: scale(1.1); 
  }
`;

const CardTitle = styled.h5`
  margin: 10px 0;
  color: ${theme.portalTitleColor};
`;

const ShowMoreButton = styled(Button)`
  margin-top: 20px;
  background-color: ${theme.portalColor};
  border: none;
  border-radius: 10px;
  &:hover {
    background-color: darken(${theme.portalColor}, 10%);
  }
`;

function TrailsGrid() {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAllTrails, setShowAllTrails] = useState({});

  useEffect(() => {
    const fetchCategoriesAndTrails = async () => {
      const storedToken = localStorage.getItem('token');

      try {
        const response = await axios.get(CATEGORYTRAIL_API, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        setCategories(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchCategoriesAndTrails();
  }, []);

  const toggleShowAllTrails = (categoryId) => {
    setShowAllTrails((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  const filteredCategories = categories
    .map((category) => ({
      ...category,
      trilhas: category.trilhas
        .filter((trail) => trail.status === 1)
        .filter((trail) => trail.tituloTrilha.toLowerCase().includes(searchTerm.toLowerCase())),
    }))
    .filter((category) => category.trilhas.length > 0);

  useEffect(() => {
    if (!searchTerm) {
      setShowAllTrails({});
    }
  }, [searchTerm]);

  console.log(filteredCategories, 'all')

  if (loading) {
    return (
      <div className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" variant="primary" style={{ width: '3rem', height: '3rem' }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        {/* <h6 className="section-title bg-white text-center text-primary px-3" style={{ margin: '30px' }}>
          Trilhas
        </h6> */}
        <p> </p>
        {/* <h1 className="mb-5">Todas as Trilhas</h1> */}
      </div>

      {/* Campo de busca */}
      <div className="container">
        <SearchInput
          type="text"
          placeholder="O que você quer aprender?"
          className="form-control mb-4"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          backgroundColor={theme.portalColor}
          inputWidth={'100%'}
          Color={'black'}
        />
      </div>

      <FrameContainer>
        {filteredCategories.map((category, index) => (
          <div key={category.categoria.id}>
            <CategoryTitle>{category.categoria.descricaoCategoria}</CategoryTitle>
            <CategoryContainer isOdd={index % 2 !== 0}>
              <TrailsGridContainer>
              {category.trilhas.slice(
                    0, searchTerm
                      ? category.trilhas.length 
                      : showAllTrails[category.categoria.id]
                      ? category.trilhas.length
                      : 6
                  )
                  .map((trail) => (
                    <CardContainer key={trail.id}>
                      <Link to={`/trilhas/cursos/${trail.id}`}>
                        <CardImage src={trail.arquivo} alt={trail.tituloTrilha} />
                        <CardTitle>{trail.tituloTrilha}</CardTitle>
                      </Link>
                    </CardContainer>
                  ))}
              </TrailsGridContainer>
                        {!searchTerm && category.trilhas.length > 6 && (
            <ShowMoreButton onClick={() => toggleShowAllTrails(category.categoria.id)}>
              {showAllTrails[category.categoria.id] ? 'Mostrar Menos' : 'Mostrar Mais'}
            </ShowMoreButton>
          )}

            </CategoryContainer>
          </div>
        ))}
      </FrameContainer>

      <a href="#" className="btn btn-lg btn-lg-square back-to-top"
      style={{ backgroundColor: theme.portalColor }}>
        <i className="fas fa-caret-up"></i>
      </a>
    </div>
  );
}

export default TrailsGrid;
