import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../../theme';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { CHANGEPASSWORD_API, ME_API, USER_API } from '../../../../apiEndPoints';
import { Buttons, Container, Container1, Form, FormContainer, Label, ResponsiveDiv } from '../../../../components/FormContainers';
import { Title } from '../../../../components/General';
import { BackButton, SaveButton } from '../../../../components/Buttons';
import swal from 'sweetalert';


const Input = styled(InputMask)`
  width: ${(props) => (props.fullWidth ? '100%' : '100%')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const Checkbox = styled.input`
  margin: 0 10px;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
`;

const ChangePassword = () => {
  const [passwords, setPasswords] = useState({ newPassword: '', confirmPassword: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  // const [userData, setUserData] = useState({});
  const userID = localStorage.getItem('userId');
  const token = localStorage.getItem('token');

  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const meApiResponse = await axios.post(`${ME_API}`, {}, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       setUserData(meApiResponse.data);
  //     } catch (error) {
  //       console.error('Erro ao buscar dados do usuário:', error);
  //     }
  //   };

  //   fetchUserData();
  // }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (passwords.newPassword !== passwords.confirmPassword) {
      setError('As senhas não coincidem');
      swal("Erro", "As senhas não coincidem", "error");
      return;
    }
  
    setLoading(true);
  
    try {
      const response = await axios.put(`${CHANGEPASSWORD_API}/${userID}`, {
        password: passwords.newPassword, 
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      console.log('Senha alterada com sucesso:', response.data);
      swal("Sucesso", "Senha alterada com sucesso!", "success");
      setPasswords({ newPassword: '', confirmPassword: '' });
      setError('');
      handleGoBack();
    } catch (error) {
      console.error('Erro ao alterar senha:', error);
      swal("Erro", "Houve um erro ao alterar a senha. Tente novamente.", "error");
    } finally {
      setLoading(false);
    }
  };
  

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <Container minWidth="auto" maxWidth="25rem">
      <Title text={'Alterar Senha'} backgroundColor={theme.backgroundColor2} padding={'5px 0 5px 20px'} />
      <Container1>
        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <ResponsiveDiv width="100%">
              <Label htmlFor="newPassword">Nova Senha</Label>
              <Input
                type={showPassword ? 'text' : 'password'}
                name="newPassword"
                value={passwords.newPassword}
                onChange={handleChange}
                // placeholder="Digite a nova senha"
                required
              />
            </ResponsiveDiv>
            <ResponsiveDiv width="100%">
              <Label htmlFor="confirmPassword">Confirmar Senha</Label>
              <Input
                type={showPassword ? 'text' : 'password'}
                name="confirmPassword"
                value={passwords.confirmPassword}
                onChange={handleChange}
                // placeholder="Confirme a nova senha"
                required
              />
            </ResponsiveDiv>
            <CheckboxLabel>
              <Checkbox type="checkbox" onChange={handleToggleShowPassword} />
              Mostrar
            </CheckboxLabel>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Buttons>
              <BackButton onClick={handleGoBack} />
              <SaveButton onClick={handleSubmit} disabled={loading}>
                {loading ? 'Enviando...' : 'Salvar'}
              </SaveButton>
            </Buttons>
          </Form>
        </FormContainer>
      </Container1>
    </Container>
  );
};

export default ChangePassword;
