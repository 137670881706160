import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../vendor/fontawesome-free/css/all.min.css';
import '../../css/sb-admin-2.min.css';
import { Title } from '../../components/General';
import styled from 'styled-components';
import { Links } from '../../components/Fields/ConfigPageLinks';

const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 0 6rem;
  gap: 0 20px;
`;

const Card = styled.div`
  width: 30%;
  min-width: 300px; 
  margin-left: calc((100% - 30%) / 2);
  transition: filter 0.3s ease; 

  @media (max-width: 768px) {
    margin-left: 2rem;
  }

  &:hover {
    filter: brightness(90%); 
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;

const links = Links;

const ConfigPage = () => {
  return (
    <div>
      <ContainerHeader>
        <Title text={'Configurações'} />
      </ContainerHeader>
      <Container>
        {links.map((link) => (
          <div key={link.id}>
            <Card className={`card shadow h-100 py-2 border-left-${link.color}`}>
              <Link to={link.path} style={{ textDecoration: 'none' }}>
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-secundary mb-1">
                        <Title text={link.name} fontSize={'25px'} />
                        <p>{link.sub}</p>
                      </div>
                    </div>
                    <div className="col-auto"></div>
                  </div>
                </div>
              </Link>
            </Card>
          </div>
        ))}
      </Container>
    </div>
  );
};

export default ConfigPage;