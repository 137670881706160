import React from "react";
import { MODULELIST_API} from "../../../apiEndPoints";
import theme from "../../../theme";
import Lists from "../../../components/Lists";
import { ModuleColumns} from "../../../components/TableColumns";


const ModuleList = () => {
  const token = localStorage.getItem('token');

  return(
    <div>
      <Lists
      title={'Módulos'}
      api={MODULELIST_API}
      buttonColor={theme.primaryColor}
      addURL={`/cadastro/moduleregister`}
      editURL={`/editmodule`}
      filterdisplay='block'
      fields={ModuleColumns}
      token={token}
      applyCompanyIdFilter={true}
      />
    </div>
  )
}

export default ModuleList