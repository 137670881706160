import React, { useState } from 'react';
import styled from 'styled-components';
import Navbar from '../components/HeaderPortal';
import FooterPortal from '../components/FooterPortal';

const StyledFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

const ContentWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const PortalLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <LayoutContainer>
      <ContentWrapper>
        <ContentWrapper2>
          <Navbar toggleSidebar={toggleSidebar} />
          <MainContent>
            <div className="container-fluid">{children}</div>
          </MainContent>
          <FooterPortal/>
        </ContentWrapper2>
      </ContentWrapper>
      <StyledFooter>
      </StyledFooter>
    </LayoutContainer>
  );
};

export default PortalLayout;