import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../vendor/fontawesome-free/css/all.min.css';
import '../../css/sb-admin-2.min.css';

const LogoutModal = ({ showModal, handleClose, handleLogout }) => {
  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Quer mesmo sair?</Modal.Title>
      </Modal.Header>
      <Modal.Body>Selecione "Sair" abaixo se você deseja encerrar sua sessão.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary"
        style={{ color: 'black' }}
        onClick={handleLogout}>
          Sair
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutModal;
