import React from 'react';

const ProjectStatus = () => {
  const projects = [
    { title: 'Server Migration', progress: 20, color: 'bg-danger' },
    { title: 'Sales Tracking', progress: 40, color: 'bg-warning' },
    { title: 'Customer Database', progress: 60, color: 'bg-primary' },
    { title: 'Payout Details', progress: 80, color: 'bg-info' },
    { title: 'Account Setup', progress: 100, color: 'bg-success' },
  ];

  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Projects</h6>
      </div>
      <div className="card-body">
        {projects.map((project, index) => (
          <React.Fragment key={index}>
            <h4 className="small font-weight-bold">
              {project.title}
              <span className="float-right">{project.progress}%</span>
            </h4>
            <div className="progress mb-4">
              <div
                className={`progress-bar ${project.color}`}
                role="progressbar"
                style={{ width: `${project.progress}%` }}
                aria-valuenow={project.progress}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ProjectStatus;