import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../../theme';
import axios from 'axios';
import InputMask from 'react-input-mask';
import {GETPERSONDOCUMENTSBYID_API, GETPERSONDOCUMENTS_API, PERSONDOCUMENTS_API, PUTPERSONDOCUMENTS_API, } from '../../../../apiEndPoints';
import swal from 'sweetalert';
import {DeleteButton, SaveButton } from '../../../../components/Buttons';
import { Form, ResponsiveDiv, Label, Buttons } from '../../../../components/FormContainers';
import { PersonDocumentFormdata } from '../../../../components/Formdatas';
import { PersonDocFields } from '../../../../components/Fields/PeopleDocumentsFields';

const Input = styled(InputMask)`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }

  @media (max-width: 812px) {
    min-width: 100%; 
  }
`;

const Select = styled.select`
  width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  padding: 10px;
  margin-top: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: ${theme.primaryColor};
  }
`;

const EditPersonDocuments = ({ record, peopleDocId }) => {
  const [formData, setFormData] = useState(PersonDocumentFormdata);
  const token = localStorage.getItem('token');
  const [loading] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [show, setShow] = useState(true);
  const [showExtras, setShowExtras] = useState(true);
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);



  useEffect(() => {
    const fetchPersonDocs = async () => {
      try {
        const response = await axios.get(`${GETPERSONDOCUMENTSBYID_API}/${peopleDocId}`, {
          ...formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFormData(response.data[0]);
        setSelectedDoc(response.data[0].document_id);
  
        if (response.data[0].arquivo) {
          setImagePreviewUrl(`${response.data[0].arquivo}`);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchPersonDocs();
  }, [peopleDocId, token, record]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem('userId');
  
    const updatedFormData = new FormData();
    updatedFormData.append('user_id', userId);
    updatedFormData.append('document_id', selectedDoc);
    updatedFormData.append('observation', formData.observation);
    if (file) {
      updatedFormData.append('file_document', file);
    }
  
    try { 
      const response = await axios.put(
        `${PUTPERSONDOCUMENTS_API}/${peopleDocId}`,
        updatedFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, 'response');
  
      if (response.status === 200 || response.status === 201 ) {
        swal({
          title: 'OK!',
          text: 'Documento atualizado com sucesso',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
      } else {
        swal({
          title: 'Ops!',
          text: 'Algo deu errado, tente novamente.',
          icon: 'error',
          timer: 2000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Error processing the form:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${PERSONDOCUMENTS_API}?peopleID=${peopleDocId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal({
        title: 'OK!',
        text: 'Documento excluído com sucesso',
        icon: 'success',
        timer: 2000,
        buttons: false,
      });
      setShow(false)
    } catch (error) {
      console.error('Erro ao excluir o post:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const reader = new FileReader();
      const file = files[0];
      
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
  
      reader.readAsDataURL(file);
  
      setFile(file);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const fileName = `documento_${peopleDocId}.jpg`

  const campos = [
    ...PersonDocFields,
  ];

  return (
    <div>
      {show && (
        <>
          <div style={{ border: 'solid 1px #D3D3D3', margin: '1.5em 0 0 0', padding: '1em', backgroundColor: '#fcfcfc' }}>
            <Form onSubmit={handleSubmit}>
              {campos.map((campo) => (
                <ResponsiveDiv key={campo.name} width={campo.width}>
                  {campo.type === 'select' ? (
                    <Select
                      name={campo.name}
                      value={campo.value}
                      onChange={(e) => {
                        campo.onChange(e);
                      }}
                      style={{ width: '100%' }}
                      disabled={campo.disabled}
                    >
                      <option value="" style={{ color: '#999' }} disabled>
                        {campo.placeholder}
                      </option>
                      {campo.options.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    campo.type === 'file' ? (
                      <div style={{ display: 'flex', alignItems: 'end' }}>
                       <div style={{ padding: '0 1rem 0 0'}}>
                          {imagePreviewUrl && (
                            <a
                              href={imagePreviewUrl}
                              download={fileName}
                              style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                            >
                              <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px', cursor: 'pointer', borderRadius:'15px' }} />
                            </a>
                          )}
                        </div>
                        <div>
                          <Input
                            id={campo.name}
                            type="file"
                            name={campo.name}
                            onChange={handleChange}
                            required
                            disabled={campo.disabled}
                            fullWidth={campo.fullWidth}
                            style={{ width: '100%' }}
                          />
                        </div>
                      </div>
                    ) : (
                      <Input
                        id={campo.name}
                        type={campo.type}
                        name={campo.name}
                        value={formData[campo.name]}
                        onChange={campo.onChange || handleChange}
                        required
                        disabled={campo.disabled}
                        fullWidth={campo.fullWidth}
                        style={{ width: '100%' }}
                      />
                    )
                  )}
                  <Label htmlFor={campo.name}>{campo.placeholder}</Label>
                </ResponsiveDiv>
              ))}
              <Buttons>
                {showExtras && (
                  <DeleteButton onClick={() => { handleDelete() }} />
                )}
                <SaveButton onClick={handleSubmit} disabled={loading} />
              </Buttons>
            </Form>
          </div>
        </>
      )}
    </div>
  );
  
};

export default EditPersonDocuments;
