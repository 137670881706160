import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

// Styled checkbox input
const StyledCheckboxInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 20px;
  height: 20px;
 
  & + label {
    position: relative;
    padding-left: 30px; 
    cursor: pointer;
    font-size: ${props => props.fontSize || '20px'}; 
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      width: 20px; 
      height: 20px;
      border: 2px solid #ccc; 
      background-color: #fff; 
      border-radius: 3px;
    }
    &:after {
      content: '';
      position: absolute;
      left: 7px; 
      top: 7px;
      width: 6px; 
      height: 12px;
      border: solid ${theme.primaryColor}; 
      border-width: 0 2px 2px 0;
      transform: rotate(45deg); 
      opacity: ${props => props.checked ? '1' : '0'}; 
    }
  }
`;

// Checkbox component
const Checkbox = ({ id, checked, onChange, label, fontSize, info }) => {
  return (
    <div>
      <StyledCheckboxInput
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} style={{ fontSize, fontWeight: 'bold', marginBottom: '5px', display: 'inline-block' }}>
        {label}
      </label>
      <div style={{ marginLeft: '25px', display: 'inline-block' }}>
        {info}
      </div>
    </div>
  );
};

export default Checkbox;